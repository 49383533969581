const about = {
	'about.title': 'เกี่ยวกับเรา',
	'about.content':
		'สกุลเงินดิจิทัลเปิดโอกาสใหม่ๆ สำหรับการบรรลุอิสรภาพทางการเงิน ยิ่งฐานผู้ใช้มีขนาดใหญ่ขึ้นเท่าใด เทคโนโลยีบัญชีแยกประเภทแบบกระจายอำนาจที่เกิดขึ้นใหม่ก็จะยิ่งมีข้อได้เปรียบในการแข่งขันมากขึ้นเท่านั้น FixedFloat มอบเครื่องมือให้คุณใช้สินทรัพย์ดิจิทัลของคุณได้อย่างเต็มประสิทธิภาพผ่านแพลตฟอร์มแลกเปลี่ยนที่ใช้งานง่ายและเข้าถึงได้',
	'about.title2': 'ข้อดี',
	'about.title2.tips1': 'ความง่ายสูงสุดในการแลกเปลี่ยนและความสามารถในการเลือกกลยุทธ์จะช่วยให้คุณทำการแลกเปลี่ยนได้อย่างมีกำไร',
	'about.title2.tips2': 'เราจัดให้มีการแลกเปลี่ยนที่ปลอดภัย คุณไม่เสี่ยงอะไรเลยและคุณจะเห็นขนาดของคอมมิชชันได้ทันที',
	'about.title2.tips3': 'คุณสามารถทำการแลกเปลี่ยนอัตโนมัติด้วยความเร็วสูงสุดบนอุปกรณ์ใดก็ได้',
	'about.title3': 'ภารกิจ',
	'about.title3.tips1':
		'เราเป็นผู้เล่นระยะยาวที่มีวิสัยทัศน์และต้องการเป็นพันธมิตรที่เชื่อถือได้และไว้วางใจได้ของคุณในโลกของสินทรัพย์ดิจิทัล ภารกิจของเราคือการทำให้กระบวนการแลกเปลี่ยนง่ายขึ้นผ่านโซลูชันที่ใช้งานได้จริงและปรับขนาดได้ซึ่งทำให้เศรษฐกิจคริปโตทำงานเพื่อคุณ',
	'about.title4': 'สกุลเงินที่รองรับ',
}

export default about
