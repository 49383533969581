import menu from './id/menu'
import home from './id/home'
import chat from './id/chat'
import other from './id/other'
import about from './id/about'
import swap from './id/swap'

const lang = {
	'app.switch.language.tips': 'Berhasil mengganti {{msg}}',
	'app.language': 'Bahasa',
	'app.loading': 'Memuat...',
	'connect.sign': 'Tanda tangan...',
	'connect.btn': 'Hubungkan',
	'connect.logout': 'Keluar',
	'connect.sign.error': 'Verifikasi tanda tangan gagal',

	'layout.menu.stake': 'Setoran',
	'layout.menu.withdraw': 'Ekstrak',
	'layout.menu.team': 'Ekologi',
	'layout.menu.share': 'Bagikan',

	'app.fee.title1': 'Akumulasi biaya penanganan',
	'app.fee.title2': 'Jumlah akumulasi alamat layanan',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
