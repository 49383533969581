import { Accordion, AccordionItem } from '@nextui-org/react'
import { useState } from 'react'
import type { Selection } from '@nextui-org/react'
import IconClose from '@/components/Icon/close'
import IconOpen from '@/components/Icon/open'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function HomeHelp() {
	const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(['1']))

	const { t } = useTranslation()

	return (
		<div className="px-2">
			<div className="tail-text-menu ml-1 text-2xl font-bold">{t('swap.help.title')}</div>
			<Accordion selectedKeys={selectedKeys} onSelectionChange={setSelectedKeys}>
				<AccordionItem
					indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
					startContent={<div className="text-3xl">1</div>}
					key="1"
					classNames={{
						title: 'text-2xl',
					}}
					aria-label="Accordion 1"
					title={t('swap.help.title1')}
				>
					<div className="pl-6 text-sm">{t('swap.help.1')}</div>
				</AccordionItem>
				<AccordionItem
					startContent={<div className="text-3xl">2</div>}
					classNames={{
						title: 'text-2xl',
					}}
					key="2"
					indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
					aria-label="Accordion 2"
					title={t('swap.help.title2')}
				>
					<div className="pl-6 text-sm">{t('swap.help.2')}</div>
				</AccordionItem>
				<AccordionItem
					startContent={<div className="text-3xl">3</div>}
					key="3"
					classNames={{
						title: 'text-2xl',
					}}
					indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
					aria-label="Accordion 3"
					title={t('swap.help.title3')}
				>
					<div className="pl-6 text-sm">
						{t('swap.help.3')} <br />
						{t('swap.help.4')} <br />
						{t('swap.help.5')}
					</div>
				</AccordionItem>
			</Accordion>
			<div className="h-divider w-full shrink-0 border-none bg-divider"></div>
			<div className="flex flex-row-reverse items-center gap-2 py-5">
				<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="40" height="40" rx="20" fill="#0A273F" />
					<path d="M9.82275 19.5551H23.1713V20.791H9.82275V19.5551Z" fill="#008FDF" />
					<path d="M23.0959 15.8565L30.2836 20.0778L23.0959 24.4132V15.8565Z" fill="#008FDF" />
				</svg>
				<Link to="/help" className="text-lg text-[#008FDF]">
					{t('swap.help.6')}
				</Link>
			</div>
			<div className="h-divider w-full shrink-0 border-none bg-divider"></div>
		</div>
	)
}
