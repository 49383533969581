const swap = {
	'swap.tips.no.wallet': 'Silakan hubungkan dompet Anda terlebih dahulu',
	'swap.tips.no.number': 'Silakan masukkan jumlahnya',
	'swap.tips.no.address': 'Masukkan alamat penerima Anda',
	'swap.tips.address.error': 'Kesalahan alamat',
	'swap.tips.error': 'Penukaran gagal',
	'swap.tips.max': 'Jumlah maksimum: {{msg}}',
	'swap.tips.min': 'Jumlah minimum: {{msg}}',

	'swap.card.send': 'Kirim',
	'swap.card.receive': 'Terima',
	'swap.card.tips': 'Masukkan mata uang atau tiket',
	'swap.card.min': 'min:',
	'swap.card.max': 'maks:',

	'swap.btn.title': 'Tujuan',
	'swap.input.tips': 'Alamat pembayaran',
	'swap.title.order': 'Jenis Pesanan',

	'swap.tab.1': 'Nilai tukar tetap (1.0%)',
	'swap.tab.2': 'Nilai tukar mengambang (0.5%)',

	'swap.btn.tipss': 'Apa bedanya? ',
	'swap.btn.swap': 'Mulai swap',

	'swap.tips.1': 'Dengan menggunakan situs web ini dan membuat pertukaran, Anda menyetujui Fast Coin Mixer',
	'swap.tips.2': 'Persyaratan Layanan',
	'swap.tips.3': 'dan',
	'swap.tips.4': 'Kebijakan Privasi',
	'swap.tips.5': '. ',

	'swap.diff.title': 'Apa perbedaan antara nilai tukar tetap dan mengambang? ',

	'swap.paste.title': 'Tempel',
	'swap.help.title': 'Pertanyaan Umum',
	'swap.help.1':
		'Dengan tiga cara: melalui email (jika Anda meninggalkan alamat email Anda), di situs web kami (jika browser Anda menerima caching) atau melalui tautan pesanan Anda untuk melihat bursa di blockchain. ',
	'swap.help.2': `Tidak perlu registrasi, tidak perlu membagikan informasi pribadi Anda. Kami tidak menahan dana Anda, semua pertukaran dilakukan secara instan dalam mode otomatis penuh. `,
	'swap.help.3':
		'Integritas adalah prioritas utama kami, jadi kami berjanji untuk sepenuhnya transparan dan membuat semua biaya jelas:',
	'swap.help.4': '• Jika Anda memilih nilai tukar tetap, maka nilainya adalah 1%',
	'swap.help.5': '• Jika Anda memilih suku bunga mengambang, maka suku bunganya akan menjadi 0,5%',
	'swap.help.6': 'Buka halaman FAQ',
	'swap.help.title1': 'Bagaimana cara melacak pesanan saya? ',
	'swap.help.title2': 'Mengapa saya bisa mempercayai Anda? ',
	'swap.help.title3': 'Apakah Anda memiliki biaya tersembunyi? ',

	'swap.tips.title': 'Tepercaya sejak 2018',
	'swap.tips.card.title1': 'Hemat waktu',
	'swap.tips.card.title2': 'Lakukan pertukaran',
	'swap.tips.card.title3': 'Hemat uang',
	'swap.tips.card.content1': 'Karena seluruh proses otomatis, kecepatan pertukaran adalah yang tercepat',
	'swap.tips.card.content2': 'Pilih strategi yang tepat dan lakukan pertukaran yang menguntungkan',
	'swap.tips.card.content3': 'Nilai tukar terbaik, komisi terendah',

	'swap.trade.title': 'Transaksi Terkini',
}

export default swap
