import OrderDetailsBottomInfo from './components/BottomInfo'
import OrderDetailsInfo from './components/Info'
import OrderDetailsQrCode from './components/QrCode'
import OrderDetailsTopBar from './components/TopBar'
import ProgressRate from './components/ProgressRate'
import { useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { serverOrderDetail } from '@/api/order'
import { useEffect } from 'react'
import { Spinner } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'

export type IOrderDetail = {
	id: number
	order_sn: string
	ff_expiration: string
	from_code: string
	to_code: string
	type: 'fixed' | 'float'
	send_amount: string
	send_address: string
	to_amount: string
	to_address: string
	status: number
	created_at: string
	ccies_from_code: {
		code: string
		coin: string
		name: string
		network: string
		color: string
		logo: string
	}
	ccies_to_code: {
		code: string
		coin: string
		name: string
		network: string
		color: string
		logo: string
	}
}

export default function OrderDetails() {
	const { t } = useTranslation()
	const [params] = useSearchParams()
	const idParams = params.getAll('id')[0]

	const { data: orderDetail, ...OrderDetailArgs } = useSWR(
		idParams ? ['orderDetail', idParams] : null,
		async ([, id]) => {
			return await serverOrderDetail({ id }).then(res => {
				return res.data as IOrderDetail
			})
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	const loading = OrderDetailArgs.isValidating || OrderDetailArgs.isLoading

	useEffect(() => {
		console.log('orderDetail', orderDetail)
	}, [orderDetail])

	useEffect(() => {
		OrderDetailArgs.mutate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!idParams) return <div>{t('orders.no.data')}</div>

	if (!orderDetail || loading)
		return (
			<div className="flex h-full w-full items-center justify-center">
				<Spinner size="sm" color="white" />
			</div>
		)

	return (
		<div className="flex flex-col gap-4">
			<OrderDetailsTopBar info={orderDetail} />
			<div className="flex h-[14.375rem] gap-4">
				<OrderDetailsInfo info={orderDetail} />
				<OrderDetailsQrCode id={idParams} />
			</div>
			<OrderDetailsBottomInfo info={orderDetail} />
			<ProgressRate info={orderDetail} />
		</div>
	)
}
