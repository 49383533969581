const menu = {
	'menu.home': '首頁',
	'menu.contract': '合約',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': '選擇權合約',
	'menu.option.ai': 'AI高頻套利',
	'menu.option.qr': '量化機器人',
	'menu.option.ai.mall': 'AI智慧商城',

	'menu.my.orders': '我的訂單',
	'menu.stake': 'Stake',
	'menu.about': '關於',
	'menu.about.faq': '常見問題',
	'menu.serve': '服務條款',
	'menu.privacy': '隱私權條款',
}

export default menu
