const swap = {
	'swap.tips.no.wallet': 'Подключите кошелек',
	'swap.tips.no.number': 'Введите сумму',
	'swap.tips.no.address': 'Введите адрес получения',
	'swap.tips.address.error': 'Ошибка в адресе',
	'swap.tips.error': 'Обмен не удался',
	'swap.tips.max': 'Максимальная сумма: {{msg}}',
	'swap.tips.min': 'Минимальная сумма: {{msg}}',
	'swap.card.send': 'Отправить',
	'swap.card.receive': 'Получить',
	'swap.card.tips': 'Выберите валюту или токен',
	'swap.card.min': 'Мин:',
	'swap.card.max': 'Макс:',
	'swap.btn.title': 'Адрес получения',
	'swap.input.tips': 'Адрес кошелька',
	'swap.title.order': 'Тип обмена',
	'swap.tab.1': 'Фиксированный курс (1%)',
	'swap.tab.2': 'Плавающий курс (0,5%)',
	'swap.btn.tipss': 'Какая разница?',
	'swap.btn.swap': 'Начать обмен',
	'swap.tips.1': 'Используя этот сайт и совершая обмен, вы соглашаетесь с',
	'swap.tips.2': 'Условиями обслуживания',
	'swap.tips.3': 'и',
	'swap.tips.4': 'Политикой конфиденциальности.',
	'swap.tips.5': '.',
	'swap.diff.title': 'Разница между фиксированным и плавающим курсом',
	'swap.paste.title': 'Вставить',
	'swap.help.title': 'Часто задаваемые вопросы',
	'swap.help.1':
		'Вы можете отслеживать обмен тремя способами: по электронной почте (если указали её), через наш сайт (если ваш браузер сохраняет кэш), либо через блокчейн, используя ссылку на ваш заказ.',
	'swap.help.2': 'Регистрация не требуется, личные данные не запрашиваются. Все обмены проходят автоматически и мгновенно.',
	'swap.help.3': 'Мы гарантируем прозрачность и четко указываем все комиссии:',
	'swap.help.4': '• Если вы выбираете фиксированный обменный курс, он составляет 1%',
	'swap.help.5': '• Если вы выберете плавающую ставку, она составит 0,5%',
	'swap.help.6': 'Перейти на страницу часто задаваемых вопросов',
	'swap.help.title1': 'Как отслеживать обмен?',
	'swap.help.title2': 'Почему вам можно доверять?',
	'swap.help.title3': 'Есть ли скрытые комиссии?',
	'swap.tips.title': 'Нам доверяют с 2018 года',
	'swap.tips.card.title1': 'Экономия времени',
	'swap.tips.card.title2': 'Быстрый обмен',
	'swap.tips.card.title3': 'Лучший курс',
	'swap.tips.card.content1': 'Полностью автоматизированный процесс обеспечивает максимальную скорость.',
	'swap.tips.card.content2': 'Выбирайте стратегию для максимальной выгоды.',
	'swap.tips.card.content3': 'Лучший обменный курс с минимальной комиссией.',
	'swap.trade.title': 'Последние транзакции',
}

export default swap
