import { ReactComponent as Token1 } from '@/assets/about/token/1.svg'
import { ReactComponent as Token2 } from '@/assets/about/token/2.svg'
import { ReactComponent as Token3 } from '@/assets/about/token/3.svg'
import { ReactComponent as Token4 } from '@/assets/about/token/4.svg'
import { ReactComponent as Token5 } from '@/assets/about/token/5.svg'
import { ReactComponent as Token6 } from '@/assets/about/token/6.svg'
import { ReactComponent as Token7 } from '@/assets/about/token/7.svg'
import { ReactComponent as Token8 } from '@/assets/about/token/8.svg'
import { ReactComponent as Token9 } from '@/assets/about/token/9.svg'
import { ReactComponent as Token10 } from '@/assets/about/token/10.svg'
import { ReactComponent as Token11 } from '@/assets/about/token/11.svg'
import { ReactComponent as Token12 } from '@/assets/about/token/12.svg'
import { ReactComponent as Token13 } from '@/assets/about/token/13.svg'
import { ReactComponent as Token14 } from '@/assets/about/token/14.svg'
import { ReactComponent as Token15 } from '@/assets/about/token/15.svg'
import { ReactComponent as Token16 } from '@/assets/about/token/16.svg'
import { ReactComponent as Token17 } from '@/assets/about/token/17.svg'
import { ReactComponent as Token18 } from '@/assets/about/token/18.svg'
import { ReactComponent as Token19 } from '@/assets/about/token/19.svg'
import { ReactComponent as Token20 } from '@/assets/about/token/20.svg'
import { ReactComponent as Token21 } from '@/assets/about/token/21.svg'
import { ReactComponent as Token22 } from '@/assets/about/token/22.svg'
import { ReactComponent as Token23 } from '@/assets/about/token/23.svg'
import { ReactComponent as Token24 } from '@/assets/about/token/24.svg'
import { ReactComponent as Token25 } from '@/assets/about/token/25.svg'
import { ReactComponent as Token26 } from '@/assets/about/token/26.svg'
import { ReactComponent as Token27 } from '@/assets/about/token/27.svg'
import { ReactComponent as Token28 } from '@/assets/about/token/28.svg'
import { ReactComponent as Token29 } from '@/assets/about/token/29.svg'
import { ReactComponent as Token30 } from '@/assets/about/token/30.svg'
import { ReactComponent as Token31 } from '@/assets/about/token/31.svg'
import { ReactComponent as Token32 } from '@/assets/about/token/32.svg'
import { ReactComponent as Token33 } from '@/assets/about/token/33.svg'
import { ReactComponent as Token34 } from '@/assets/about/token/34.svg'
import { ReactComponent as Token35 } from '@/assets/about/token/35.svg'
import { ReactComponent as Token36 } from '@/assets/about/token/36.svg'
import { ReactComponent as Token37 } from '@/assets/about/token/37.svg'
import { ReactComponent as Token38 } from '@/assets/about/token/38.svg'
import { ReactComponent as Token39 } from '@/assets/about/token/39.svg'
import { ReactComponent as Token40 } from '@/assets/about/token/40.svg'
import { ReactComponent as Token41 } from '@/assets/about/token/41.svg'
import { ReactComponent as Token42 } from '@/assets/about/token/42.svg'
import { ReactComponent as Token43 } from '@/assets/about/token/43.svg'
import { ReactComponent as Token44 } from '@/assets/about/token/44.svg'
import { ReactComponent as Token45 } from '@/assets/about/token/45.svg'
import { ReactComponent as Token46 } from '@/assets/about/token/46.svg'
import { ReactComponent as Token47 } from '@/assets/about/token/47.svg'
import { ReactComponent as Token48 } from '@/assets/about/token/48.svg'
import { ReactComponent as Token49 } from '@/assets/about/token/49.svg'
import { ReactComponent as Token50 } from '@/assets/about/token/50.svg'
import { ReactComponent as Token51 } from '@/assets/about/token/51.svg'
import { ReactComponent as Token52 } from '@/assets/about/token/52.svg'
import { ReactComponent as Token53 } from '@/assets/about/token/53.svg'
import { ReactComponent as Token54 } from '@/assets/about/token/54.svg'
import { ReactComponent as Token55 } from '@/assets/about/token/55.svg'
import { ReactComponent as Token56 } from '@/assets/about/token/56.svg'
import { ReactComponent as Token57 } from '@/assets/about/token/57.svg'
import { ReactComponent as Token58 } from '@/assets/about/token/58.svg'
import { ReactComponent as Token59 } from '@/assets/about/token/59.svg'
import { ReactComponent as Token60 } from '@/assets/about/token/60.svg'
import { ReactComponent as Token61 } from '@/assets/about/token/61.svg'
import { ReactComponent as Token62 } from '@/assets/about/token/62.svg'
import { ReactComponent as Token63 } from '@/assets/about/token/63.svg'
import { ReactComponent as Token64 } from '@/assets/about/token/64.svg'
import { ReactComponent as Token65 } from '@/assets/about/token/65.svg'
import { ReactComponent as Token66 } from '@/assets/about/token/66.svg'
import { ReactComponent as Token67 } from '@/assets/about/token/67.svg'
import { ReactComponent as Token68 } from '@/assets/about/token/68.svg'
import { ReactComponent as Token69 } from '@/assets/about/token/69.svg'

export const tokens = [
	{ network: 'Aave (ERC20)', icon: <Token1 /> },
	{ network: 'Cardano', icon: <Token2 /> },
	{ network: 'Cardano (BEP20)', icon: <Token3 /> },
	{ network: 'Aptos', icon: <Token4 /> },
	{ network: 'Cosmos', icon: <Token5 /> },
	{ network: 'Avalanche (C-Chain)', icon: <Token6 /> },
	{ network: 'Basic Attention (ERC20)', icon: <Token7 /> },
	{ network: 'Bitcoin Cash', icon: <Token8 /> },
	{ network: 'BNB (opBNB)', icon: <Token9 /> },
	{ network: 'BNB Smart Chain (BEP20)', icon: <Token10 /> },
	{ network: 'Bitcoin', icon: <Token11 /> },
	{ network: 'Bitcoin (BEP20)', icon: <Token12 /> },
	{ network: 'Bitcoin (Lightning)', icon: <Token13 /> },
	{ network: 'BitTorrent', icon: <Token14 /> },
	{ network: 'PancakeSwap (BEP20)', icon: <Token15 /> },
	{ network: 'DAI (BEP20)', icon: <Token16 /> },
	{ network: 'DAI (ERC20)', icon: <Token17 /> },
	{ network: 'DAI (Polygon)', icon: <Token18 /> },
	{ network: 'Dash', icon: <Token19 /> },
	{ network: 'Dogecoin', icon: <Token20 /> },
	{ network: 'Polkadot', icon: <Token21 /> },
	{ network: 'EOS', icon: <Token22 /> },
	{ network: 'Ethereum Classic', icon: <Token23 /> },
	{ network: 'Ethereum', icon: <Token24 /> },
	{ network: 'Ethereum (Arbitrum)', icon: <Token25 /> },
	{ network: 'Ethereum (Base)', icon: <Token26 /> },
	{ network: 'Ethereum (BEP20)', icon: <Token27 /> },
	{ network: 'Ethereum (Optimism)', icon: <Token28 /> },
	{ network: 'Ethereum (ZkSync)', icon: <Token29 /> },
	{ network: 'Fantom', icon: <Token30 /> },
	{ network: 'KuCoin Token', icon: <Token31 /> },
	{ network: 'Chainlink (ERC20)', icon: <Token32 /> },
	{ network: 'Litecoin', icon: <Token33 /> },
	{ network: 'Decentraland (ERC20)', icon: <Token34 /> },
	{ network: 'Maker (ERC20)', icon: <Token35 /> },
	{ network: 'PAX Gold (ERC20)', icon: <Token36 /> },
	{ network: 'Polygon', icon: <Token37 /> },
	{ network: 'Polygon (ERC20)', icon: <Token38 /> },
	{ network: 'SHIBA INU (ERC20)', icon: <Token39 /> },
	{ network: 'SHIBA INU (BEP20)', icon: <Token40 /> },
	{ network: 'Solana', icon: <Token41 /> },
	{ network: 'Toncoin', icon: <Token42 /> },
	{ network: 'Tron', icon: <Token43 /> },
	{ network: 'TrueUSD (ERC20)', icon: <Token44 /> },
	{ network: 'Trust Wallet Token (BEP20)', icon: <Token45 /> },
	{ network: 'USD Coin (Arbitrum)', icon: <Token46 /> },
	{ network: 'USD Coin (BEP20)', icon: <Token47 /> },
	{ network: 'USD Coin (ERC20)', icon: <Token48 /> },
	{ network: 'USD Coin (Polygon)', icon: <Token49 /> },
	{ network: 'USD Coin (Solana)', icon: <Token50 /> },
	{ network: 'Pax Dollar (ERC20)', icon: <Token51 /> },
	{ network: 'Tether (ERC20)', icon: <Token52 /> },
	{ network: 'Tether (Arbitrum)', icon: <Token53 /> },
	{ network: 'Tether (BEP20)', icon: <Token54 /> },
	{ network: 'Tether (Polygon)', icon: <Token55 /> },
	{ network: 'Tether (Solana)', icon: <Token56 /> },
	{ network: 'Tether (TRC20)', icon: <Token57 /> },
	{ network: 'VeChain', icon: <Token58 /> },
	{ network: 'Wrapped BNB (BEP20)', icon: <Token59 /> },
	{ network: 'Wrapped BNB (opBNB)', icon: <Token60 /> },
	{ network: 'Wrapped ETH (Arbitrum)', icon: <Token61 /> },
	{ network: 'Wrapped ETH (ERC20)', icon: <Token62 /> },
	{ network: 'Wrapped SOL (Solana)', icon: <Token63 /> },
	{ network: 'Stellar Lumens', icon: <Token64 /> },
	{ network: 'Monero', icon: <Token65 /> },
	{ network: 'Ripple', icon: <Token66 /> },
	{ network: 'Tezos', icon: <Token67 /> },
	{ network: 'Zcash', icon: <Token68 /> },
	{ network: '0x (ERC20)', icon: <Token69 /> },
]
