const menu = {
	'menu.home': 'ホーム',
	'menu.contract': '契約',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'オプション契約',
	'menu.option.ai': 'AI高頻度アービトラージ',
	'menu.option.qr': '定量ロボット',
	'menu.option.ai.mall': 'AIスマートモール',

	'menu.my.orders': '私の注文',
	'menu.stake': 'ステーク',
	'menu.about': '概要',
	'menu.about.faq': 'よくある質問',
	'menu.serve': '利用規約',
	'menu.privacy': 'プライバシーポリシー',
}

export default menu
