import { ReactComponent as COPYSVG } from '@/assets/details/copy3.svg'
import { useTranslation } from 'react-i18next'

const Paste = ({ onChange }: { onChange: (text: string) => void }) => {
	const { t } = useTranslation()
	return (
		<div
			onClick={() => {
				navigator.clipboard.readText().then(text => {
					onChange(text)
				})
			}}
			className="flex shrink-0 cursor-pointer items-center gap-1 text-xs text-[#A6A6A6]"
		>
			<COPYSVG />
			{t('swap.paste.title')}
		</div>
	)
}

export default Paste
