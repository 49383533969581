const menu = {
	'menu.home': '홈',
	'menu.contract': '계약',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': '옵션 계약',
	'menu.option.ai': 'AI 고주파 중재',
	'menu.option.qr': '양적 로봇',
	'menu.option.ai.mall': 'AI 스마트 몰',

	'menu.my.orders': '내 주문',
	'menu.stake': '스테이크',
	'menu.about': '정보',
	'menu.about.faq': 'FAQ',
	'menu.serve': '서비스 약관',
	'menu.privacy': '개인정보 보호정책',
}

export default menu
