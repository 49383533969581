const chat = {
	'chat.input.placeholder1': 'Apa yang ingin Anda ketahui? ',
	'chat.input.placeholder2': 'Menunggu jawaban terakhir',
	'chat.submit.btn': 'Kirim',
	'chat.submit.on.address': 'Akun tidak diaktifkan, tidak dapat mengobrol',
	'chat.wallet.btn.title': 'Dompet tidak terhubung, tidak dapat mengobrol',
	'chat.data.params.msg1': 'Kesalahan sistem',
	'chat.data.params.msg2': 'Pengguna belum memulai',
	'chat.data.params.msg3': 'Anda hanya dapat mengakses 10 kali sehari',
	'chat.data.params.msg1001': 'Json tidak valid',
	'chat.data.params.msg.other': 'Permintaan gagal, silakan coba lagi',
}

export default chat
