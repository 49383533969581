const about = {
	'about.title': '당사 소개',
	'about.content':
		'암호화폐는 재정적 자유를 얻을 수 있는 새로운 기회를 열어줍니다. 사용자 기반이 클수록 새로운 분산 원장 기술이 제공하는 경쟁 우위가 커집니다. FixedFloat는 쉽고 접근하기 쉬운 거래소 플랫폼을 통해 디지털 자산을 최대한 활용할 수 있는 도구를 제공합니다.',
	'about.title2': '장점',
	'about.title2.tips1': '교환과 선택을 최대한 용이하게 만드는 전략을 채택했기 때문에 수익성 있는 교환이 가능합니다.',
	'about.title2.tips2': '안전한 교환 서비스를 제공합니다. 위험 부담이 없으며 수수료 금액을 즉시 확인할 수 있습니다.',
	'about.title2.tips3': '각 교환은 모든 장치에서 최대 속도로 자동 수행됩니다.',
	'about.title3': '미션',
	'about.title3.tips1':
		'당사는 비전을 가지고 장기적으로 여러분과 함께하는 협력자로써 디지털 자산 세계에서 신뢰할 수 있는 파트너가 되고자 합니다. 저희의 사명은 실용적이고 확장 가능한 솔루션을 통해 거래 과정을 간소화하는 것으로 이를 통해 암호화폐 경제를 활성화하고자 합니다.',
	'about.title4': '지원되는 암호화폐',
}

export default about
