import IconClose from '@/components/Icon/close'
import IconOpen from '@/components/Icon/open'
import { Accordion, AccordionItem, Selection } from '@nextui-org/react'
import { useState } from 'react'

export default function HelpOrders() {
	const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(['1']))

	return (
		<Accordion selectedKeys={selectedKeys} onSelectionChange={setSelectedKeys}>
			<AccordionItem
				startContent={<div className="text-3xl">1</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="1"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="How can I track my order?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						Order status is updated in real time. We recommend that you do not close the order page until it is completed. You can
						also subscribe to email notifications on the order page. When transactions enter the network, links to explorers
						appear on the order page to track them.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">2</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="2"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="What should I do if my order expired?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						If your transaction has not arrived before the order time has passed, on the order page you will be able to choose:
					</p>
					<p>
						Continue the exchange at a floating rate. The order will be executed after the transaction is received and the
						required number of network confirmations is received.
					</p>
					<p>
						Make a refund of the amount sent for the exchange minus the network fee. To do this, you need to specify your address,
						to which the coins will be returned after receiving your transaction.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">3</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="3"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="I accidentally closed the Fast Coin site, what should I do?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>If you have entered a valid email address in your order, you will receive an email with a link to your order.</p>
					<p>You can return to the page through your browser history.</p>
					<p>You can ask for help in the Live Chat on the site.</p>
				</div>
			</AccordionItem>
		</Accordion>
	)
}
