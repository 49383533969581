const other = {
	'other.help.title': 'FAQ',
	'other.serve.title': '서비스 약관',
	'other.serve.tips': '마지막 업데이트: 2024년 7월 30일',
	'other.privacy.title': '개인정보 보호정책',
	'other.privacy.tips': '마지막 업데이트: 2023년 1월 14일',

	'orders.no.data': '찾을 수 없는 OrderID',
	'orders.details.send': '보내기',
	'orders.details.receive': '받기',
	'orders.details.orderid': '주문ID',
	'orders.details.difftime': '남은 시간',
	'orders.details.type': '주문 유형',
	'orders.details.status1': '고정 환율',
	'orders.details.status2': '변동 환율',
	'orders.details.createTime': '생성 시간',
	'orders.details.address': '주소',
	'orders.details.with.amount': '금액 포함',
	'orders.details.b.send': '보내기',
	'orders.details.b.send1': '받는 주소:',
	'orders.details.b.s': '환율은 수령 시 고정됩니다',
	'orders.details.b.s1': '네트워크 확인. ',
	'orders.details.b.s2': '수신 주소',
	'orders.details.progress.1': '입금 대기 중',
	'orders.details.progress.2': '확인 대기 중',
	'orders.details.progress.3': '교환 실행',
	'orders.details.progress.4': '완료',

	'orders.list.1': '날짜/OROER ID',
	'orders.list.2': '보내기',
	'orders.list.3': '받기',
	'orders.list.4': '수신 주소',
	'orders.title': '완료된 주문',
}

export default other
