const menu = {
	'menu.home': 'Trang chủ',
	'menu.contract': 'Hợp đồng',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'Hợp đồng tùy chọn',
	'menu.option.ai': 'Trọng tài tần suất cao AI',
	'menu.option.qr': 'Robot định lượng',
	'menu.option.ai.mall': 'Trung tâm mua sắm thông minh AI',

	'menu.my.orders': 'Đơn hàng của tôi',
	'menu.stake': 'Cổ phần',
	'menu.about': 'Giới thiệu',
	'menu.about.faq': 'Câu hỏi thường gặp',
	'menu.serve': 'Điều khoản dịch vụ',
	'menu.privacy': 'Chính sách bảo mật',
}

export default menu
