const home = {
	'home.usdt.title': 'Koin Stabil USDT',
	'home.usdt.title2': 'Pendapatan saat ini',
	'home.usdt.max': 'Maksimum',
	'home.usdt.balance': 'Saldo:',
	'home.usdt.total': 'Total setoran:',
	'home.total.balance': 'Kumpulan likuiditas:',
	'home.btn.approve': 'Otorisasi',
	'home.btn.stake': 'Setoran',
	'home.withdraw.title': 'Dapat ditarik:',
	'home.withdraw.2': 'Tebus',
	'home.withdraw.earnings': 'Pendapatan pribadi',
	'home.withdraw.amount': 'Manfaat ekologis',
	'home.withdraw.btn1': 'Tarik',
	'home.withdraw.btn2': 'Deposit lagi',
	'home.team.title': 'Total Simpanan Ekosistem',
	'home.team.1': 'Alamat',
	'home.team.upper': 'Rekomendasi',
	'home.team.address.placeholder': 'Silakan masukkan alamat pengguna',
	'home.team.address.s': 'Sudah terikat',
	'home.team.leavel': 'Level',
	'home.team.bind': 'Ikat',
	'home.invite.title': 'Tautan undangan',
	'home.invite.copy': 'Salin',
	'home.invite.tip': 'Silakan aktifkan akun Anda terlebih dahulu',
	'home.message.tip0': 'Berhasil diterima',
	'home.message.tip1': 'Otorisasi berhasil',
	'home.message.tip2': 'Deposit berhasil',
	'home.message.tip3': 'Jumlah setoran minimum: {{msg}} USDT',
	'home.message.tip4': 'Saldo akun saat ini: {{msg}} USDT',
	'home.message.tip5': 'Penyalinan berhasil',
	'home.message.tip6': 'Tidak dapat mengikat alamat Anda sendiri',
	'home.message.tip7': 'Pengikatan berhasil',
	'home.message.tip8': 'Penukaran berhasil',
	'home.message.tip9': 'Jumlah maksimum yang dapat ditebus di akun saat ini adalah: {{msg}} USDT',

	'home.check.order.title': 'Periksa pesanan',
	'home.2.stake.btn.1': 'Biasa',
	'home.2.stake.btn.1.1': '(15 hari)',
	'home.2.stake.btn.2': 'saat ini',
	'home.2.stake.btn.3': 'Nilai: {{msg}}%',

	'home.orders.title': 'Pesanan Saya',
	'home.orders.tips1': 'Jumlah deposit',
	'home.orders.tips2': 'Pendapatan yang diharapkan',
	'home.orders.tips3': 'Waktu deposit',
	'home.orders.tips4': 'Waktu kedaluwarsa',
	'home.orders.tips5': 'Diterima',
	'home.orders.btn1': 'Dapatkan',
	'home.orders.btn2': 'Diterima',
	'home.orders.no.more': 'Tidak ada lagi',
	'home.orders.claim.success': 'Klaim Sukses',

	'home.2.withdraw.title': 'Waktu hingga penarikan berikutnya:',

	'home.regular.title': 'Pendapatan rutin',
	'home.regular.reward.success': 'Berhasil diterima',
	'home.regular.withdraw.success': 'Penukaran berhasil',
	'home.regular.tips1': 'Jumlah yang dapat ditebus',
	'home.regular.tips2': 'Anda dapat menerima penghasilan',
	'home.regular.btn1': 'Tebus',
	'home.regular.btn2': 'Dapatkan penghasilan',

	'home.team.list.title1': 'Mobilitas Pribadi',
	'home.team.list.title2': 'Mobilitas Tim',
	'home.team.list.title3': 'Undangan yang valid',

	'home.state.order.tips.max': 'Jumlah pesanan yang sedang diproses tidak boleh melebihi 25',
	'home.message.tip10': 'Jumlah setoran tidak mencukupi',
}

export default home
