const other = {
	'other.help.title': 'Часто задаваемые вопросы',
	'other.serve.title': 'Условия обслуживания',
	'other.serve.tips': 'Последнее обновление: 30 июля 2024 г.',
	'other.privacy.title': 'Политика конфиденциальности',
	'other.privacy.tips': 'Последнее обновление: 14 января 2023 г.',
	'orders.no.data': 'Идентификатор заказа не найден',
	'orders.details.send': 'Отправлено',
	'orders.details.receive': 'Получено',
	'orders.details.orderid': 'ID заказа',
	'orders.details.difftime': 'Оставшееся время',
	'orders.details.type': 'Тип заказа',
	'orders.details.status1': 'Фиксированный курс',
	'orders.details.status2': 'Плавающий курс',
	'orders.details.createTime': 'Дата создания',
	'orders.details.address': 'Адрес',
	'orders.details.with.amount': 'Сумма',
	'orders.details.b.send': 'Отправка',
	'orders.details.b.send1': 'Адрес получателя:',
	'orders.details.b.s': 'Курс будет зафиксирован после получения средств',
	'orders.details.b.s1': 'Подтверждение сети...',
	'orders.details.b.s2': 'Адрес получения',
	'orders.details.progress.1': 'Ожидание депозита',
	'orders.details.progress.2': 'Ожидание подтверждения',
	'orders.details.progress.3': 'Обмен выполняется',
	'orders.details.progress.4': 'Обмен завершен',
	'orders.list.1': 'Дата/ID заказа',
	'orders.list.2': 'Отправлено',
	'orders.list.3': 'Получено',
	'orders.list.4': 'Адрес получения',
	'orders.title': 'Завершенные заказы',
}

export default other
