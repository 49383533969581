import { useTranslation } from 'react-i18next'
import { IOrderDetail } from '../page'

export default function OrderDetailsTopBar({ info }: { info: IOrderDetail }) {
	const { t } = useTranslation()
	return (
		<div className="tail-order-topbar-bg flex w-full items-center justify-center gap-2 rounded-md py-5">
			<div className="relative z-10 flex items-center gap-2">
				<div className="flex flex-col items-end gap-1 text-[#1BA27A]">
					<span className="text-[0.625rem] text-[#9C9C9C]">{t('orders.details.send')}</span>
					<div className="flex items-start gap-0.5 text-xs">
						{Number(info.send_amount)} {info.from_code}
						<span className="text-[0.5rem] leading-[0.5rem]">{info.ccies_from_code.network}</span>
					</div>
				</div>
				<img src={info.ccies_from_code.logo} width={22} height={22} className="rounded-full" alt="" />
			</div>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" className="relative z-10" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.52356 6.7168H9.01806V7.50332H0.52356V6.7168Z" fill="white" />
				<path d="M8.97021 4.36328L13.5442 7.04957L8.97021 9.80845V4.36328Z" fill="white" />
			</svg>
			<div className="relative z-10 flex flex-row-reverse items-center gap-2">
				<div className="flex flex-col items-start gap-1 text-[#1BA27A]">
					<span className="text-[0.625rem] text-[#9C9C9C]">{t('orders.details.receive')}</span>
					<div className="flex items-start gap-0.5 text-xs">
						{Number(info.to_amount)} {info.to_code}
						<span className="text-[0.5rem] leading-[0.5rem]">{info.ccies_to_code.network}</span>
					</div>
				</div>
				<img src={info.ccies_to_code.logo} width={22} height={22} className="rounded-full" alt="" />
			</div>
		</div>
	)
}
