import { ReactComponent as USDTSVG } from '@/assets/svg/token/usdt.svg'
import { Button, Input, Spinner } from '@nextui-org/react'

import { ReactComponent as USDTMINSVG } from '@/assets/svg/token/usdt-min.svg'
import useHomeHooks from '@/hooks/useHomeHooks'
import { useMemo, useState } from 'react'
import useRootHooks from '@/hooks/useRootHooks'
import { numFormat } from '@/utils'
import toast from 'react-hot-toast'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import { encodeFunctionData, parseEther } from 'viem'
import { USDT, VAULTPROXY } from '@/contract/abi'
import getChainConfig from '@/lib/web3/getChainConfig'
import { MaxUint256 } from 'ethers'
import { useTranslation } from 'react-i18next'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { Link } from 'react-router-dom'
import useStakeOrdersList from '@/hooks/useStakeOrdersList'

export default function StakePage() {
	const { t } = useTranslation()
	const { isConnected } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()

	const { mutate: RootMutate } = useRootHooks()
	const { usdtAmount, isUsdt } = useRootHooks().data

	const { loading, mutate: HomeMutate } = useHomeHooks()
	const { authorizedAmount, minStakeAmount, currentUserStake, totalAmountUsdt, rewardRate, orderRewardRate } = useHomeHooks().data

	const { onSendTransaction } = useSendTransaction()
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']
	const USDT_ADDRESS = contracts['USDT_ADDRESS']

	// 质押金额、质押loading
	const [stakeNumber, setStakeNum] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)

	// 定期、活期
	const [isRegular, setIsRegular] = useState<boolean>(true)

	const { data: StateOrderData, mutate: StateOrderMutate } = useStakeOrdersList()

	/**
	 * @tips 当前按钮状态
	 * @param isAuthorized 授权
	 * @param isStake 质押
	 * @param none 无
	 */
	const isBtnStatus = useMemo<'isAuthorized' | 'isStake' | 'none'>(() => {
		const number = Number(stakeNumber)
		if (typeof number === 'number' && number > 0) {
			if (authorizedAmount >= number) return 'isStake'
			return 'isAuthorized'
		}
		return 'none'
	}, [authorizedAmount, stakeNumber])

	// 用户点击质押
	const handleStakeClick = async () => {
		console.log('stake', stakeNumber)
		setIsLoading(true)
		const isTrue = stakeJudgeIsTrue()
		if (!isTrue) {
			setIsLoading(false)
			return
		}
		try {
			let abiData = [parseEther(stakeNumber)]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'stake',
				args: abiData,
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.message.tip2'), { duration: 2000, id: 'DepositSuccess' })
			setStakeNum('')
			setIsLoading(false)
			RootMutate()
			HomeMutate()
			StateOrderMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { duration: 2000, id: 'DepositErr' })
			setIsLoading(false)
		}
	}

	// 质押、授权前条件判断
	const stakeJudgeIsTrue = (): boolean => {
		if (!isConnected) return false
		else if (Number(stakeNumber) < minStakeAmount) {
			toast.error(t('home.message.tip3', { msg: minStakeAmount }), { duration: 2000, id: 'stakeJudgeIsTrueError' })
			return false
		} else if (Number(stakeNumber) > usdtAmount) {
			toast.error(t('home.message.tip4', { msg: usdtAmount }), { duration: 2000, id: 'stakeJudgeIsTrueError' })
			return false
		} else return true
	}

	// 用户点击授权
	const handleApproveClick = async () => {
		setIsLoading(true)
		const isTrue = stakeJudgeIsTrue()
		if (!isTrue) {
			setIsLoading(false)
			return
		}
		try {
			//  parseEther(stakeNumber)
			let abiData = [VaultProxy_ADDRESS, MaxUint256]
			const data = encodeFunctionData({
				abi: USDT,
				functionName: 'approve',
				args: abiData,
			})
			let receipt = await onSendTransaction({
				data: data,
				to: USDT_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.message.tip1'), { duration: 2000, id: 'AuthorizationSuccess' })
			setIsLoading(false)
			RootMutate()
			HomeMutate()
		} catch (error: any) {
			toast.error(error, { duration: 2000, id: 'AuthorizationErr' })
			setIsLoading(false)
		}
	}

	// 用户点击质押-定期
	const handleOrderStakeClick = async () => {
		console.log('stake', stakeNumber)
		setIsLoading(true)
		const isTrue = stakeJudgeIsTrue()
		if (!isTrue) {
			setIsLoading(false)
			return
		}
		try {
			let abiData = [parseEther(stakeNumber)]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'orderStake',
				args: abiData,
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.message.tip2'), { duration: 2000, id: 'DepositSuccess' })
			setStakeNum('')
			setIsLoading(false)
			RootMutate()
			HomeMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { duration: 2000, id: 'DepositErr' })
			setIsLoading(false)
		}
	}

	return (
		<div className="flex flex-col items-center">
			{loading && (
				<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-b-2xl">
					<Spinner label={t('app.loading')} labelColor="warning" color="warning"></Spinner>
				</div>
			)}
			<div className="flex items-center gap-2 pb-5">
				<USDTSVG className="h-[1.4375rem] w-[1.4375rem]" />
				<span className="tail-text-menu py-2 text-2xl font-bold">{t('home.usdt.title')}</span>
			</div>
			<Input
				placeholder=""
				classNames={{
					inputWrapper: 'tail-input-wrapper h-10',
					input: 'text-sm text-white',
				}}
				type="number"
				value={stakeNumber}
				onValueChange={setStakeNum}
				isReadOnly={!isConnected || isLoading}
				endContent={
					<span
						onClick={() => {
							if (!isUsdt) return
							else if (isLoading) return
							setStakeNum(numFormat(usdtAmount))
						}}
						className={`${!isUsdt ? 'cus-noactive' : ''} shrink-0 cursor-pointer text-sm font-bold text-[#F7931A]`}
					>
						{t('home.usdt.max')}
					</span>
				}
			/>
			<div className="flex w-full flex-col gap-3 pb-5 pt-4">
				<div className="flex w-full items-center gap-0.5">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.usdt.balance')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(usdtAmount)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
				<div className="flex w-full items-center gap-0.5">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.usdt.total')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(currentUserStake)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
				<div className="flex w-full items-center gap-0.5">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.total.balance')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(totalAmountUsdt)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
			</div>
			{isConnected ? (
				<>
					<div className="flex w-full items-center gap-4">
						<Button
							isDisabled={!(isBtnStatus === 'isStake')}
							isLoading={isLoading && isBtnStatus === 'isStake' && isRegular}
							className={`${isBtnStatus === 'isStake' ? 'tail-btn-active' : 'tail-btn-no-data'} mb-2.5 !h-11 w-full`}
							onClick={() => {
								if (isBtnStatus === 'isStake' && !isLoading) {
									setIsRegular(true)
									console.log('length', StateOrderData?.list?.length)
									if (StateOrderData?.list && StateOrderData?.list.length > 25) {
										toast.error(t('home.state.order.tips.max'), { id: 'max_state_order' })
										return
									}
									handleOrderStakeClick()
								}
							}}
						>
							<div className="flex flex-col gap-0.5">
								<div className="text-sm font-bold">
									{t('home.2.stake.btn.1')} <span className="text-xs">{t('home.2.stake.btn.1.1')}</span>
								</div>
								<div className="!text-[0.625rem] !leading-3">APR: {orderRewardRate} %</div>
							</div>
						</Button>
						<Button
							isDisabled={!(isBtnStatus === 'isStake')}
							isLoading={isLoading && isBtnStatus === 'isStake' && !isRegular}
							className={`${isBtnStatus === 'isStake' ? 'tail-btn-active' : 'tail-btn-no-data'} mb-2.5 !h-11 w-full`}
							onClick={() => {
								if (isBtnStatus === 'isStake' && !isLoading) {
									setIsRegular(false)
									handleStakeClick()
								}
							}}
						>
							<div className="flex flex-col gap-0.5">
								<div className="text-sm font-bold">{t('home.2.stake.btn.2')}</div>
								<div className="!text-[0.625rem] !leading-3">APR: {rewardRate} %</div>
							</div>
						</Button>
					</div>
					<Button
						isDisabled={!(isBtnStatus === 'isAuthorized')}
						isLoading={isLoading && isBtnStatus === 'isAuthorized'}
						className={`${isBtnStatus === 'isAuthorized' ? 'tail-btn-active' : 'tail-btn-no-data'} mb-5 w-full`}
						onClick={() => {
							if (isBtnStatus === 'isAuthorized' && !isLoading) handleApproveClick()
						}}
					>
						<span>{t('home.btn.approve')}</span>
					</Button>
					<Link to="/myOrders">
						<span className="text-sm font-bold text-[#008FDF] underline">{t('home.check.order.title')}</span>
					</Link>
				</>
			) : (
				<Button onClick={() => open()} className="tail-btn-active mb-5 w-full">
					<span>{t('connect.btn')}</span>
				</Button>
			)}
		</div>
	)
}
