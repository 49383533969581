const about = {
	'about.title': 'About us',
	'about.content':
		'Cryptocurrencies open new opportunities for achieving financial freedom. The bigger the user base, the greater the competitive advantages that emerging distributed ledger technologies provide. FixedFloat gives you the tools to make full use of your digital assets through an easy and accessible exchange platform.',
	'about.title2': 'Advantages',
	'about.title2.tips1':
		'The maximum ease of making an exchange and the ability to choose a strategy will allow you to make a profitable exchange',
	'about.title2.tips2':
		'We provide a secure exchange. You do not risk anything and you can immediately see the size of the commission',
	'about.title2.tips3': 'You can make automatic exchange with maximum speed on any devices',
	'about.title3': 'Mission',
	'about.title3.tips1':
		'We are long-term players with a vision and want to become your reliable and trusted partners in the world of digital assets. Our mission is to simplify the process of exchange through practical and scalable solutions that make the crypto economy work for you.',
	'about.title4': 'Supported currencies',
}

export default about
