const menu = {
	'menu.home': 'Beranda',
	'menu.contract': 'Kontrak',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'Kontrak Opsi',
	'menu.option.ai': 'Arbitrase frekuensi tinggi AI',
	'menu.option.qr': 'Robot Kuantitatif',
	'menu.option.ai.mall': 'Pusat Perbelanjaan Cerdas AI',

	'menu.my.orders': 'Pesanan Saya',
	'menu.stake': 'Taruhan',
	'menu.about': 'Tentang',
	'menu.about.faq': 'FAQ',
	'menu.serve': 'Persyaratan Layanan',
	'menu.privacy': 'Kebijakan Privasi',
}

export default menu
