const other = {
	'other.help.title': 'คำถามที่พบบ่อย',
	'other.serve.title': 'ข้อกำหนดในการให้บริการ',
	'other.serve.tips': 'อัปเดตล่าสุดเมื่อวันที่ 30 กรกฎาคม 2024',
	'other.privacy.title': 'นโยบายความเป็นส่วนตัว',
	'other.privacy.tips': 'อัปเดตล่าสุดเมื่อวันที่ 14 มกราคม 2023',

	'orders.no.data': 'ไม่พบรหัสคำสั่งซื้อ',
	'orders.details.send': 'ส่ง',
	'orders.details.receive': 'รับ',
	'orders.details.orderid': 'รหัสคำสั่งซื้อ',
	'orders.details.difftime': 'เวลาที่เหลือ',
	'orders.details.type': 'ประเภทคำสั่งซื้อ',
	'orders.details.status1': 'อัตราคงที่',
	'orders.details.status2': 'อัตราลอยตัว',
	'orders.details.createTime': 'ระยะเวลาในการสร้าง',
	'orders.details.address': 'ที่อยู่',
	'orders.details.with.amount': 'ด้วยจำนวนเงิน',
	'orders.details.b.send': 'ส่ง',
	'orders.details.b.send1': 'ที่อยู่ปลายทาง:',
	'orders.details.b.s': 'อัตราแลกเปลี่ยนจะคงที่เมื่อได้รับ',
	'orders.details.b.s1': 'ยืนยันเครือข่าย ',
	'orders.details.b.s2': 'ที่อยู่ผู้รับ',
	'orders.details.progress.1': 'กำลังรอการฝากเงิน',
	'orders.details.progress.2': 'กำลังรอการยืนยัน',
	'orders.details.progress.3': 'ดำเนินการแลกเปลี่ยน',
	'orders.details.progress.4': 'เสร็จสมบูรณ์',

	'orders.list.1': 'วันที่/รหัส OROER',
	'orders.list.2': 'ส่ง',
	'orders.list.3': 'รับ',
	'orders.list.4': 'ที่อยู่ผู้รับ',
	'orders.title': 'คำสั่งซื้อเสร็จสมบูรณ์',
}

export default other
