import menu from './ru/menu'
import home from './ru/home'
import chat from './ru/chat'
import other from './ru/other'
import about from './ru/about'
import swap from './ru/swap'
const lang = {
	'app.switch.language.tips': 'Язык успешно изменён на {{msg}}!',
	'app.language': 'Язык',
	'app.loading': 'Загрузка...',
	'connect.sign': 'Подпись...',
	'connect.btn': 'Подключить',
	'connect.logout': 'Отключить кошелёк',
	'connect.sign.error': 'Ошибка подписи',

	'layout.menu.stake': 'Стейкинг',
	'layout.menu.withdraw': 'Вывод',
	'layout.menu.team': 'Комьюнити',
	'layout.menu.share': 'Пригласить друзей',

	'app.fee.title1': 'Накопленная комиссия',
	'app.fee.title2': 'Накопленное количество сервисных адресов',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
