import Footer from '@/components/Footer'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Serve() {
	const { t } = useTranslation()
	const scrollToHash = () => {
		const contactUs = document.getElementById(
			(window.location.hash || '').substring(8, (window.location.hash || '').length).replace('#', ''),
		)
		if (contactUs) {
			window.requestAnimationFrame(() => {
				// (contactUs as any).scrollIntoViewIfNeeded()
				window.scrollTo({
					top: contactUs.offsetTop - 20,
					behavior: 'smooth',
				})
			})
		}
	}

	useEffect(() => {
		scrollToHash()
	}, [])

	const haneledScroll = (str: string) => {
		const contactUs = document.getElementById(str)
		if (!contactUs) return
		window.requestAnimationFrame(() => {
			// (contactUs as any).scrollIntoViewIfNeeded()
			window.scrollTo({
				top: contactUs.offsetTop - 20,
				behavior: 'smooth',
			})
		})
	}

	return (
		<div className="h-full overflow-hidden overflow-y-scroll pt-4 md:pt-[3.875rem]">
			<div className="tail-text-menu px-4 text-[2rem] font-bold leading-9">{t('other.serve.title')}</div>
			<p className="px-2 pt-10">{t('other.serve.tips')}</p>
			<div className="tail-no-default flex flex-col gap-3 px-2 pb-10 pt-6 text-sm">
				<div className="flex flex-col gap-2 rounded bg-[#1A1B24] px-6 py-4">
					<h3>Document's contents</h3>
					<ol className="leading-8">
						<li>
							<Link to="/serve#server_section_1" onClick={() => haneledScroll('server_section_1')}>
								General Provisions
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_2" onClick={() => haneledScroll('server_section_2')}>
								Using of Service
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_3" onClick={() => haneledScroll('server_section_3')}>
								Prices and Exchange Rates
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_4" onClick={() => haneledScroll('server_section_4')}>
								Processing Orders in Violation of the Exchange rules
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_5" onClick={() => haneledScroll('server_section_5')}>
								Prohibited Jurisdictions
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_6" onClick={() => haneledScroll('server_section_6')}>
								Prohibited Uses
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_7" onClick={() => haneledScroll('server_section_7')}>
								AML Procedure
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_8" onClick={() => haneledScroll('server_section_8')}>
								Personal Data
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_9" onClick={() => haneledScroll('server_section_9')}>
								Accuracy, Completeness and Timeliness of Information
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_10" onClick={() => haneledScroll('server_section_10')}>
								User Comments, Feedback and Other Submissions
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_11" onClick={() => haneledScroll('server_section_11')}>
								Disclaimer Of Warranties
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_12" onClick={() => haneledScroll('server_section_12')}>
								Limitation Of Liability
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_13" onClick={() => haneledScroll('server_section_13')}>
								Entire Agreement
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_14" onClick={() => haneledScroll('server_section_14')}>
								Tax
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_15" onClick={() => haneledScroll('server_section_15')}>
								Changes to Terms of Service
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_16" onClick={() => haneledScroll('server_section_16')}>
								Risk
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_17" onClick={() => haneledScroll('server_section_17')}>
								Intellectual Property Rights
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_18" onClick={() => haneledScroll('server_section_18')}>
								Restricted Activities
							</Link>
						</li>
						<li>
							<Link to="/serve#server_section_19" onClick={() => haneledScroll('server_section_19')}>
								Disputes Resolution
							</Link>
						</li>
					</ol>
				</div>
				<p>
					Please read these terms of service (the "Terms") carefully before using our Service. By accessing or using any part of
					the site, you agree to be bound by these Terms. If you don’t agree with these Terms or any of its Clauses, you shall
					immediately cease to use any Fast Coin services.
				</p>
				<h3 id="server_section_1">
					<span className="marker">Section 1</span> <label>General Provisions</label>
				</h3>
				<p>
					<span className="counter">1.1.</span>These Terms constitute a legal agreement between us ("<b>Fast Coin</b>", "<b>We</b>
					", "<b>Us</b>", "<b>Ourselves</b>") and users, clients, visitors and others (without limitation), who access the
					Services (as defined below) ("<b>User</b>", "<b>You</b>", "<b>Your</b>").
				</p>
				<p>
					<span className="counter">1.2.</span>The Fast Coin services ("<b>Service</b>") refers to the services provided to you by
					Fast Coin, which are offered through the fixedfloat.com website ("<b>Website</b>"), widgets and mobile applications.
				</p>
				<p>
					<span className="counter">1.3.</span> Fast Coin offers an exchange of cryptocurrencies ("<b>Exchange</b>"), based on an
					order ("<b>Order</b>") that was created by the User in the Service.
				</p>
				<p>
					<span className="counter">1.4.</span> One of the parameters of the Order is the exchange rate type: fixed ("
					<b>Fixed rate</b>") or floating ("<b>Float rate</b>") type of exchange rate. Fixed rate involves fixing the exchange
					rate: the User receives the exact amount that was displayed when creating the Exchange. Float rate implies a change in
					the exchange rate depending on market conditions. The exchange rate is finally set when the User's transaction receives
					the required number of blockchain network confirmations.
				</p>
				<p>
					<span className="counter">1.5.</span> The headings used in this agreement are included for convenience only and will not
					limit or otherwise affect these Terms.
				</p>
				<p>
					<span className="counter">1.6.</span> If the User does not read and accept the Terms of Service, the User should not use
					or continue using the Services.
				</p>
				<div id="server_section_2">
					<span className="marker">Section 2</span> <label>Using of Service</label>
				</div>
				<p>
					<span className="counter">2.1.</span> You hereby confirm that you are an individual, legal person or other organization
					with full capacity for civil rights and civil conduct when you use the Service. By agreeing to these Terms, you
					represent that you are at least the age of majority in your state or province of residence or you have consent from your
					legal representative or guardian to use the Service.
				</p>
				<p>
					<span className="counter">2.2.</span> You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion
					of the Service, use of the Service, or access to the Service or any contact on the Website through which the service is
					provided, without express written permission by us.
				</p>
				<p>
					<span className="counter">2.3.</span> To make an Exchange, the User must create an Order with a choice of direction,
					amount, type of exchange and an indication of the User's address, to which the funds should be received during the
					Exchange, and send funds to a specially generated address (or a combination of an address and a MEMO/Destination tag) on
					the Order page.
				</p>
				<p>
					<span className="counter">2.4.</span> Only one transaction is allowed per address.
				</p>
				<p>
					<span className="counter">2.5.</span> By choosing Fixed rate, you acknowledge and agree that Fast Coin will execute
					Exchange and send the exact amount that was displayed on the order page when it was created, if the following conditions
					are met:
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> You sent funds and the transaction got into the mempool or block of the blockchain
					network within 10 minutes from the moment the order was created.
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> You sent the exact amount that was displayed on the Order page, which does not include
					network fees and withdrawal fees.
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> The market rate from the moment the Order was created to the appearance of the
					transaction in the mempool of the blockchain network was not changed by more than 1.2%
				</p>
				<p>
					Otherwise, the execution of the order with the payment of the exact amount that was displayed on the page of the created
					order before payment will be impossible. You will be able to make a choice on the order page: continue the order at the
					new market fixed rate at the time of the choice (when possible) or make a refund minus the network commission.
				</p>
				<p>
					<span className="counter">2.6.</span> By choosing the Float rate, you acknowledge and agree that the exchange rates for
					the Float rate type are approximate and change over time. The service fee and the network fee for consolidating an
					incoming transaction, as well as for sending a transaction, are already included in the final displayed rate. The
					exchange rate is finally set within 10 minutes after your transaction receives the required number of confirmations in
					the blockchain network.
				</p>
				<p>
					<span className="counter">2.7.</span> If the User's transaction does not arrive at the Service address after 30 minutes
					for an Order with a Float rate and after 10 minutes for an Order with a Fixed rate, the Order automatically switches to
					the "Order expired" status. If funds are received before 24 hours have passed since the creation of the Order, the
					User's transaction is automatically displayed in the Order. The User can choose on the Order page: continue the Order at
					the current market rate and or make a refund minus the network commission.
				</p>
				<p>
					<span className="counter">2.8.</span> Sending a transaction on an Order after 24 hours has passed since the creation of
					the Order is a violation of the Exchange rules.
				</p>
				<p>
					<span className="counter">2.9.</span> The minimum and maximum allowable actual amount for Exchange is always displayed
					on the main page of the site in the "Send" and "Receive" fields. Sending a transaction on an Order, the amount of which
					is less than the minimum limit, is prohibited and is a violation of the Exchange rules.
					<br />
					When sending a transaction for an Order, the amount of which exceeds the maximum limit of the Service, a form will be
					available on the Order page for the User to automatically return a part of the amount that exceeds the original amount
					of the created Order, while the other part of the amount will be exchanged.
				</p>
				<p>
					<span className="counter">2.10.</span> When creating an Order, the User is fully responsible for the correctness of the
					chosen direction of the Exchange, the specified receiving address, the type of exchange rate and amount, as well as for
					being aware of the features of the selected currencies and networks. Fast Coin is not responsible if the transaction was
					sent to incorrectly specified details when creating an order. To make sure in the chosen direction and the details
					entered when creating the Order, the User can specify his email address to receive notifications about the status of the
					Order, containing all information about the Order, before the direct payment of the Order.
				</p>
				<p>
					<span className="counter">2.11.</span> Automatic refund through the form on the Order page to the addresses of the
					Service, including the addresses of other Orders, is prohibited and is a violation of the Exchange rules.
				</p>
				<p>
					<span className="counter">2.12.</span> You acknowledge and agree that you must send funds in a single transaction for
					the Order you have created in the same currency and blockchain network that was selected when creating the Order.
				</p>
				<p>
					<span className="counter">2.13.</span> Sending funds to the address specified in the Order in a currency and/or
					blockchain network other than that specified in the Order is a violation of the Exchange rules.
				</p>
				<p>
					<span className="counter">2.14.</span> You acknowledge and agree that Orders are executed fully automatically and when
					the User contacts technical support with a request to suspend the Order, change the Order data or refund the funds that
					were sent by the User for the Order, Fast Coin has the right to refuse this request without giving reasons.
				</p>
				<p>
					<span className="counter">2.15.</span> Suspension of the User's Order in order to change the receiving address or return
					the funds that were sent by the User under the Order is possible only if, when creating the Order, the User indicated
					his email address to receive notifications about the status of the order or if the User created the exchange from his
					registered account. The user must send an email from their email address with information about the required changes.
					The term for consideration of the request is 24 hours after the Order is suspended and the Fast Coin email is received
					from the user.
				</p>
				<p>
					<span className="counter">2.16.</span> Sending funds after 24 hours after the creation of the Order is a violation of
					the Exchange rules.
				</p>
				<p>
					<span className="counter">2.17.</span> Creating an Order specifying the address of the Service from another Order is a
					violation of the Exchange rules.
				</p>
				<p>
					<span className="counter">2.18.</span> Sending funds on the Order in more than one transaction is a violation of the
					Exchange rules.
				</p>
				<p>
					<span className="counter">2.19.</span> Sending transactions to Fast Coin addresses via third-party smart contracts is
					prohibited and is a violation of the Exchange rules, unless it is expressly allowed on the Order page.
				</p>
				<p>
					<span className="counter">2.20.</span> The possibility of exchanging or refunding funds in case of violation of the
					Exchange rules is described in div&nbsp;4.
				</p>
				<p>
					<span className="counter">2.21.</span> An order is accessed via a URL link containing the Order ID and data about the
					receiving address that was entered when the order was created. If the link to the Order or the Order ID is lost, the
					User can contact technical support to search for the Order. In this case, the User must provide complete data about his
					order, namely: the direction of the exchange being made; the transaction by which the funds were sent to the address of
					the Service; the receiving address that the User entered when creating the Order. If all the necessary data is not
					provided, the search for the Order will be refused.
				</p>
				<p>
					<span className="counter">2.22.</span> If the User has created an Order and sent a transaction in accordance with the
					Terms of Use of the Service, but Fast Coin cannot perform an Exchange or return for its own technical reasons, the User
					is offered a solution based on the technical capabilities at the time of the request, which takes no more than 24 hours
					to complete.
				</p>
				<p>
					<span className="counter">2.23.</span> If the User has created an Order and sent a transaction in accordance with the
					Terms of Use of the Service, but during the process of processing the Order, unforeseen and independent of Fast Coin
					factors occur that contribute to the non-fulfillment of the Order, the Order completion time in this case is not
					regulated and depends solely on the elimination of the relevant factors.
				</p>
				<p>
					<span className="counter">2.24.</span> The Service provides non-custodial services, which means that we do not store
					your funds on deposits and balances.
				</p>
				<p>
					<span className="counter">2.25.</span> We reserve the right to refuse service to anyone for any reason at any time.
				</p>
				<p>
					<span className="counter">2.26.</span> We reserve the right at any time to modify or discontinue the Website (or any
					part or content thereof) without notice at any time.
				</p>
				<h3 id="server_section_3">
					<span className="marker">Section 3</span> <label>Prices and Exchange Rates</label>
				</h3>
				<p>
					<span className="counter">3.1.</span> Prices for our services are subject to change without notice.
				</p>
				<p>
					<span className="counter">3.2.</span> We shall not be liable to you or to any third-party for any modification, price
					change, suspension or discontinuance of the service.
				</p>
				<p>
					<span className="counter">3.3.</span> The Fast Coin commissions, as well as all the fees spent by the Service for
					sending transactions and consolidating the funds received from the User, are already included in the final exchange rate
					displayed when the exchange is created.
				</p>
				<p>
					<span className="counter">3.4.</span> The number of transaction confirmations required for a transaction to be
					considered accepted for an Order is different for each of the cryptocurrencies. The required number of transaction
					confirmations is subject to change at the discretion of Fast Coin without notice.
				</p>
				<h3 id="server_section_4">
					<span className="marker">Section 4</span> <label>Processing Orders in Violation of the Exchange rules</label>
				</h3>
				<p>
					Violation of the Exchange rules makes it impossible to automatically process and fulfill the Order. For each case of
					violation of the Exchange rules, the User must contact the technical support on the site and register a request for
					manual processing by technical specialists.
				</p>
				<p>
					<span className="counter">4.1.</span> Sending funds after 24 hours from the moment the Order was created
				</p>
				<p>
					When sending funds for the Order after 24 hours after the creation of the Order, the transaction will be attached to the
					Order within 24 hours after the registration of the User's request. The User will be able to choose whether to continue
					the order at the market rate, at the time of attaching the transaction, or to make a refund.
				</p>
				<p>
					<span className="counter">4.2.</span> Sending two or more transactions for one Order
				</p>
				<p>
					For one Order, only one transaction is processed and double payment for the Order is prohibited. If two or more
					transactions arrive at the Order address, the Order is still executed only with the first transaction. The exchange of
					funds for other transactions at the current market rate at the time of manual processing of the Order or the return of
					funds minus the network commission is carried out within 24 hours after the registration of the User's request.
				</p>
				<p>
					<span className="counter">4.3.</span> Attempt to make a double exchange
				</p>
				<p>
					If, when creating an Order or when automatically refunding funds, the User indicated the address of the Service from
					another Order, then when contacting technical support, the User must provide his address in order to receive funds for
					exchange or return. Sending funds to the User's address is carried out within 24 hours after registration of the User's
					request. The total amount to be sent will be recalculated taking into account the additional expenses of the Service for
					the miner fee for sending the transaction and consolidating funds.
				</p>
				<p>
					<span className="counter">4.4.</span> Sending or returning funds by the User to the address of our Service
				</p>
				<p>
					If the User sent funds or the service returned funds to the Fast Coin address from which they were sent earlier, he must
					inform technical support about the transaction as a result of which the funds were sent and his address for the return
					of funds. Technical specialists have the right to demand proof that this transaction was sent on behalf of the User, if
					this fact is not obvious. Sending a refund to the User's address is carried out within fourteen (14) days after
					providing all evidence that the transaction was sent or initiated by the User.
				</p>
				<p>
					<span className="counter">4.5.</span> Sending funds below the limit
				</p>
				<p>
					If the User has sent a transaction, the amount of which is less than the amount of the Order and less than the minimum
					limit, a form will be available on the Order page for issuing an automatic refund to the User. Fast Coin reserves the
					right to refuse a refund.
				</p>
				<p>
					<span className="counter">4.6.</span> Sending funds using a third-party smart contract
				</p>
				<p>
					The service does not automatically process transactions sent through third-party smart contracts. When sending such a
					transaction, the User must inform technical support about it. The transaction will be attached to the order within seven
					(7) days after the registration of the User's request.
				</p>
				<p>
					<span className="counter">4.7.</span> Sending funds to a blockchain network other than the one specified in the Order
				</p>
				<p className="with-counter deep">
					<span className="counter">4.7.1.</span> If for the Order the currency was sent to the address of the Service in a
					network different from the one that was selected when creating the Order, and this network is supported by the Service,
					then the request will be processed within seven (7) days after the registration of the request. The user can choose to
					continue the order at the rate at the time of attaching the transaction, or make a refund.
				</p>
				<p className="with-counter deep">
					<span className="counter">4.7.2.</span> If for the Order the currency was sent to the address of the Service in a
					network different from the one that was selected when creating the Order, and this network is NOT supported by the
					Service, then the request will be processed within thirty (30) days after the registration of the request. The User can
					only request a refund if the amount sent is equivalent to more than twenty (20) USD. The commission for the restoration
					of funds and their return depends on the complexity of the work to restore them and is set individually for each case,
					but cannot be less than ten (10) USD. Fast Coin has the right to refuse to refund these funds without giving reasons.
				</p>
				<p>
					<span className="counter">4.8.</span> Sending funds in a currency other than that specified in the Order
				</p>
				<p className="with-counter deep">
					<span className="counter">4.8.1.</span> If a currency other than that specified in the Order is sent to the address of
					the Service in the same network as the selected currency in the Order, and this currency is also supported by the
					Service, then the request will be processed within seven (7) days after the request is registered. The user can choose
					whether to continue the order at the rate at the time of attaching the transaction or to make a refund.
				</p>
				<p className="with-counter deep">
					<span className="counter">4.8.2.</span> If a currency other than that specified in the Order is sent to the Service's
					address in a network other than the one selected when creating the Order, and this currency and network is supported by
					the Service, then the request will be processed within seven (7) days after the request was registered. The user can
					choose whether to continue the order at the rate at the time of attaching the transaction or to make a refund.
				</p>
				<p className="with-counter deep">
					<span className="counter">4.8.3.</span>If a currency other than that specified in the Order is sent to the Service's
					address in the same network that was selected when creating the Order, but this currency is NOT supported by the
					Service, then the request will be processed within thirty (30) days after the request was registered. The user can only
					request a refund if the amount sent is equivalent to more than twenty (20) USD. To return funds, the User is obliged to
					pay a commission for sending a return (miner fee), as well as a service commission in the amount of ten (10) USD for
					making a return of sent funds.
				</p>
				<p className="with-counter deep">
					<span className="counter">4.8.4.</span> If a currency other than that specified in the Order is sent to the address of
					the Service in a network different from the one that was selected when creating the Order, and this currency is NOT
					supported by the Service, but the network is supported by the Service, then the request will be processed within thirty
					(30) days after the registration of the request. The user can only request a refund if the amount sent is equivalent to
					more than twenty (20) USD. To return funds, the User is obliged to pay a commission for sending a return (miner fee), as
					well as a service commission in the amount of ten (10) USD for making a return of sent funds.
				</p>
				<p className="with-counter deep">
					<span className="counter">4.8.5.</span> If a currency other than that specified in the Order is sent to the Service's
					address in a network other than the one selected when creating the Order, and the network is NOT supported by the
					Service, then the request will be processed within thirty (30) days after the request was registered. The user can only
					request a refund if the amount sent is equivalent to more than twenty (20) USD. The commission for the restoration of
					funds and their return depends on the complexity of the work to restore them and is set individually for each case, but
					cannot be less than ten (10) USD. Fast Coin has the right to refuse to refund these funds without giving reasons.
				</p>
				<p>
					<span className="counter">4.9.</span>Sending funds for an Order as a transaction with incorrect parameters
				</p>
				<p>
					Sending funds for an Order as a transaction with incorrect parameters. If the Order specifies the need to specify
					additional parameters when sending a transaction (such as MEMO, Destination tag, Comment and others), then sending a
					transaction on the Order without the specified parameters or with incorrect parameters may lead to an irretrievable loss
					of the User's funds. If the transaction was sent without specifying the required parameter, the User must report this to
					the technical support service, as well as provide evidence that this transaction was sent directly or initiated by the
					User. The evidence is a letter received to our mail from the official mail of the Service from which the transaction was
					sent. The email must contain confirmation that this particular User initiated the transaction. The commission for the
					restoration of funds and their return is 10% of the amount, but cannot be less than ten (10) US dollars. The term for
					consideration of the request is 14 days after the provision of all evidence. If the User and/or the Service from which
					the transaction was sent does not provide proof within 2 weeks, We will refund the funds to the address from which the
					transaction was received, without additional parameters (such as MEMO, Destination tag, Comment and others). Fast Coin
					has the right to refuse to return these funds without giving reasons. The user can only request a refund if the amount
					sent is equivalent to more than ten (10) US dollars.
				</p>
				<p>
					<span className="counter">4.10.</span> The term for processing an Order in violation of the Exchange Rules
				</p>
				<p className="with-counter deep">
					<span className="counter">4.10.1.</span> In case of high workload, We reserve the right to increase the processing time
					for Orders in violation of the Exchange Rules.
				</p>
				<p className="with-counter deep">
					<span className="counter">4.10.2.</span> In case of repeated violation of the Exchange Rules, We reserve the right to
					increase the processing time of the Order up to 2 months, as well as to refuse the User further service.
				</p>
				<h3 id="server_section_5">
					<span className="marker">Section 5</span> <label>Prohibited Jurisdictions</label>
				</h3>
				<p>
					<span className="counter">5.1.</span> Fast Coin reserves the right to restrict or deny its services in certain
					countries.
				</p>
				<p>
					<span className="counter">5.2.</span> The use of Service is prohibited to citizens and residents of the United States of
					America, as well as individuals and entities located in countries on the United Nations Sanctions List. Additionally,
					any individual or entity that is located in these countries, regardless of their nationality or citizenship, is
					prohibited from using this service. Any attempt to use this service from a prohibited location is strictly prohibited.
				</p>
				<p>
					<span className="counter">5.3.</span> Use of the Service is prohibited in countries where usage of cryptocurrency or
					usage of Service is forbidden by applicable law.
				</p>
				<p>
					<span className="counter">5.4.</span> Fast Coin reserves the right to use various methods to prevent the use of the
					Service by Users from prohibited jurisdictions. You are to comply this rule even if the methods to prevent the use of
					the Service from prohibited jurisdictions are ineffective or can be bypassed.
				</p>
				<h3 id="server_section_6">
					<span className="marker">Section 6</span> <label>Prohibited Uses</label>
				</h3>
				<p>
					<span className="counter">6.1.</span> In addition to other prohibitions as set forth in these Terms, you are prohibited
					from using the Website or its content:
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> for any unlawful purpose;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to solicit others to perform or participate in any unlawful acts;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to violate any international, federal, provincial or state regulations, rules, laws,
					or local ordinances;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to infringe upon or violate our intellectual property rights or the intellectual
					property rights of others;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
					discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to submit false or misleading information;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to upload or transmit viruses or any other type of malicious code that will or may be
					used in any way that will affect the functionality or operation of the Service or of any related Website, other
					Websites, or the Internet;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to collect or track the personal information of others;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> for sending spam, phishing, using parsers to collect data from the site;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> for any obscene or immoral purpose;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> to interfere with or circumvent the security features of the Service or any related
					Website, other Websites, or the Internet.
				</p>
				<p>
					<span className="counter">6.2.</span> It is forbidden to use the service for laundering funds obtained by criminal
					means. If criminal funds are sent to our addresses, the funds may be frozen until information about the source and
					legality of the funds is received. The necessary information that must be provided by the User when freezing funds that
					are clearly related to criminal activity is described in div 7.
				</p>
				<p>
					<span className="counter">6.3.</span> It is prohibited to send funds directly related to addresses or contracts that
					have been sanctioned by any country to the addresses of our service. The User is obliged to ensure that any funds sent
					to our addresses are not linked to addresses or contracts under sanctions. In case of discovery of such funds, we
					reserve the right to suspend the provision of services and freeze the funds received. Fast Coin independently determines
					which sanctions regimes to follow by analyzing and monitoring the legislative requirements established by the following
					jurisdictions: the United Nations, the EU, USA.
				</p>
				<p>
					<span className="counter">6.4.</span> We reserve the right to terminate your use of the Service or any related Website
					for violating any of the prohibited uses.
				</p>
				<h3 id="server_section_7">
					<span className="marker">Section 7</span> <label>AML Procedure</label>
				</h3>
				<p>
					<span className="counter">7.1.</span> To make an Exchange in the Service, the User does not have to register or log in
					to the Service system and provide personal data. However, when the User sends funds that are clearly related to criminal
					activity, the User's Order may be suspended in order to request details about the origin of the funds sent until the
					requested information is provided.
				</p>
				<p>
					<span className="counter">7.2.</span> Fast Coin may determine a direct link to criminal activity based on information
					from business partners, public sources, victim complaints against Fast Coin, and law enforcement requests.
				</p>
				<p>
					<span className="counter">7.3.</span> Fast Coin reserves the right not to disclose the source of information about the
					connection of funds sent by the User with criminal activity.
				</p>
				<p>
					<span className="counter">7.4.</span> By accepting these Terms, you acknowledge and agree that the transaction you sent
					can be verified using the security system of the Service.
				</p>
				<p>
					<span className="counter">7.5.</span> By accepting these Terms, you acknowledge and agree to provide full details of the
					origin of the funds sent by you and confirm the honesty and legality of the receipt of these funds upon suspension of
					the Order and the corresponding request by Fast Coin.
				</p>
				<p>
					<span className="counter">7.6.</span> By accepting these Terms, you agree and warrant that true, accurate, current and
					complete information about the origin of funds sent is provided and you take full responsibility for its accuracy,
					completeness and reliability.
				</p>
				<p>
					<span className="counter">7.7.</span> The data must be provided directly by the sender of funds under the Order.
				</p>
				<p>
					<span className="counter">7.8.</span> If you refuse to provide data about the origin of the funds sent or provide false
					data, and if the data you provide confirms your connection to criminal activity, Fast Coin has the right to freeze the
					funds for the subsequent return of funds to the victims with the assistance of law enforcement agencies.
				</p>
				<p>
					<span className="counter">7.9.</span> In some cases, when for objective reasons the User cannot provide sufficient
					evidence of the source of the funds received, as well as in the case of a personal acquaintance of the sender with the
					alleged criminal who sent the funds to the User, as an exception, the User may be asked to undergo identity
					verification.
				</p>
				<h3 id="server_section_8">
					<span className="marker">Section 8</span> <label>Personal Data</label>
				</h3>
				<p>
					<span className="counter">8.1.</span> By accepting these Terms, you expressly allow Fast Coin to process your personal
					data, export your personal data outside of the jurisdiction in which you reside or are located.
				</p>
				<p>
					<span className="counter">8.2.</span> By accepting these Terms, you expressly allow Fast Coin to process and store your
					personal data.
				</p>
				<p>
					<span className="counter">8.3.</span> We are entitled to transfer some User’s data (including IP-addresses) to our
					business partners or governmental bodies at their request to facilitate the prevention and disclosure of prohibited or
					illegal actions. By accepting these Terms, you acknowledge and agree to your personal data may be transferred in this
					way.
				</p>
				<p>
					<span className="counter">8.4.</span> By accepting these Terms, you authorize the transfer of your personal data to our
					business partners and government authorities at their request in order to prevent and disclose prohibited or illegal
					activities.
				</p>
				<p>
					<span className="counter">8.5.</span> Certain personal information provided to us is protected according to the Privacy
					Policy.
				</p>
				<h3 id="server_section_9">
					<span className="marker">Section 9</span> <label>Accuracy, Completeness and Timeliness of Information</label>
				</h3>
				<p>
					<span className="counter">9.1.</span> We are not responsible if information made available on the Website is not
					accurate, complete or current. The material on the Website is provided for general information only and should not be
					relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or
					more timely sources of information. Any reliance on the material on the Website is at your own risk.
				</p>
				<p>
					<span className="counter">9.2.</span> The Website may contain certain historical information. Historical information,
					necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of the
					Website at any time and we have no obligation to update any information on this Website. You agree that it is your
					responsibility to monitor changes to the Website.
				</p>
				<h3 id="server_section_10">
					<span className="marker">Section 10</span> <label>User Comments, Feedback and Other Submissions</label>
				</h3>
				<p>
					<span className="counter">10.1.</span> If, at our request, you send certain specific submissions or without a request
					from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal
					mail, or otherwise (collectively, "comments"), you agree that we may, at any time, without restriction, edit, copy,
					publish, distribute, translate and otherwise use in any media any comments that you forward to us.
				</p>
				<h3 id="server_section_11">
					<span className="marker">Section 11</span> <label>Disclaimer Of Warranties</label>
				</h3>
				<p>
					<span className="counter">11.1.</span> We do not guarantee, represent or warrant that your use of our services will be
					uninterrupted, timely, secure or error-free.
				</p>
				<p>
					<span className="counter">11.2.</span> You agree that from time to time we may remove the service for indefinite periods
					of time or cancel the service at any time, without notice to you.
				</p>
				<p>
					<span className="counter">11.3.</span> In no case shall Fast Coin be liable for lost profits, lost revenue, lost
					savings, loss of data or any similar damages.
				</p>
				<p>
					<span className="counter">11.4.</span> THE WEBSITE AND THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS AND WITHOUT ANY
					WARRANTIES OR INDEMNITIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. YOU ASSUME ALL RESPONSIBILITY AND RISK WITH RESPECT
					TO YOUR USE OF THE WEBSITE AND SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAWS, WE OR OUR AFFILIATES DO
					NOT ACCEPT ANY LIABILITY FOR ANY DAMAGE OR LOSS, INCLUDING LOSS OF BUSINESS, REVENUE, OR PROFITS, OR LOSS OF OR DAMAGE
					TO DATA, EQUIPMENT, OR SOFTWARE (DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY OR
					OTHERWISE), RESULTING FROM ANY USE OF, OR INABILITY TO USE, THIS WEBSITE AND THE SERVICES OR THE MATERIAL, INFORMATION,
					SOFTWARE, FACILITIES, SERVICES OR CONTENT ON THIS REGARDLESS OF THE BASIS, UPON WHICH THE LIABILITY IS CLAIMED.
				</p>
				<p>
					<span className="counter">11.5.</span> WE DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE SERVICES
					AND YOU ACKNOWLEDGE AND AGREE THAT THE WEBSITE MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR CONTROL.
				</p>
				<h3 id="server_section_12">
					<span className="marker">Section 12</span> <label>Limitation Of Liability</label>
				</h3>
				<p>
					<span className="counter">12.1.</span> IF APPLICABLE LAWS DO NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF
					LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAWS. YOU
					UNDERSTAND AND AGREE THAT IT IS YOUR OBLIGATION TO ENSURE COMPLIANCE WITH ANY LEGISLATION RELEVANT TO YOUR COUNTRY OF
					DOMICILE CONCERNING USE OF THE WEBSITE, AND THAT YOU SHOULD NOT ACCEPT ANY LIABILITY FOR ANY ILLEGAL OR UNAUTHORIZED USE
					OF THE WEBSITE. YOU AGREE TO BE SOLELY RESPONSIBLE FOR ANY APPLICABLE TAXES IMPOSED UNDER YOUR TAX RESIDENCY
					REGULATIONS.
				</p>
				<p>
					<span className="counter">12.2.</span> IN NO EVENT SHALL WE, OUR DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR
					ANY LOSS RESULTING FROM HACKING, TAMPERING, VIRUS TRANSMISSION OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICES, YOUR
					ACCOUNT, OR ANY INFORMATION CONTAINED THEREIN; FOR PRODUCTS NOT BEING AVAILABLE FOR USE; FOR IMPROPER FUNCTIONALITY,
					TECHNICAL FAULTS AND DOWNTIME OF THE TECHNICAL INFRASTRUCTURE.
				</p>
				<p>
					<span className="counter">12.3.</span> You hereby agree to indemnify the Fast Coin, any of its officers, directors,
					employees and agents and its affiliated and related entities from and against any claims, costs, losses, liabilities,
					damages, expenses and judgments of any and every kind arising out of, relating to, or incurred in connection with any
					claim, complaint, audit, inquiry, or other proceeding, that arises or relates to:
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> any actual or alleged breach of your representations, warranties, or obligations set
					forth in these Terms;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> your wrongful or improper use of the services;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> any other party’s access or use of the services with your data;
				</p>
				<p className="with-counter with-marker deep nogap">
					<span className="marker">●</span> arising out of a breach of any warranty, representation, or obligation hereunder.
				</p>
				<p>
					<span className="counter">12.4.</span> You shall not have any claim of any nature whatsoever against us for any failure
					to carry out any of our obligations under these Terms as a result of a force majeure - causes beyond our control,
					including but not limited to any strike, lockout, labor shortage or means, in obtaining any permission, consent or
					approval required by us in order to provide the Services, riot, political or civil disturbances, the elements, by an act
					of state or government including regulatory action imposed or any other authority or any other cause whatsoever beyond
					our absolute and direct control.
				</p>
				<p>
					<span className="counter">12.5.</span> Fast Coin is not responsible for phishing sites and chatbots using the name
					and/or design of the Service.
				</p>
				<h3 id="server_section_13">
					<span className="marker">Section 13</span> <label>Entire Agreement</label>
				</h3>
				<p>
					<span className="counter">13.1.</span> The failure of us to exercise or enforce any right or provision of these Terms
					shall not constitute a waiver of such right or provision.
				</p>
				<p>
					<span className="counter">13.2.</span> These Terms and any policies or operating rules posted by us on the Website or in
					respect of the Service constitutes the entire agreement and understanding between you and us and govern your use of the
					Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written,
					between you and us (including, but not limited to, any prior versions of these Terms).
				</p>
				<p>
					<span className="counter">13.3.</span> Any ambiguities in the interpretation of these Terms shall not be construed
					against the drafting party.
				</p>
				<h3 id="server_section_14">
					<span className="marker">Section 14</span> <label>Tax</label>
				</h3>
				<p>
					<span className="counter">14.1.</span> The taxation of digital assets has been limited to the most jurisdictions. You
					may be subject to certain tax implications when buying or selling digital assets.
				</p>
				<p>
					<span className="counter">14.2.</span> You agree that Fast Coin is not responsible for determining whether taxes apply
					to your Exchanges or for collecting, reporting, withholding or remitting any taxes arising from any transactions.
				</p>
				<p>
					<span className="counter">14.3.</span> You agree that Fast Coin is not responsible for notifying Users of the need to
					file tax reports and pay taxes as a result of any transactions, if such are provided in Your jurisdiction.
				</p>
				<p>
					<span className="counter">14.4.</span> The User himself is responsible for compliance with the laws of the country in
					which he is located.
				</p>
				<h3 id="server_section_15">
					<span className="marker">Section 15</span> <label>Changes to Terms of Service</label>
				</h3>
				<p>
					<span className="counter">15.1.</span> You can review the most current version of the Terms at any time at this page.
				</p>
				<p>
					<span className="counter">15.2.</span> We reserve the right, at our sole discretion, to update, change or replace any
					part of these Terms by posting updates and changes to our website. It is your responsibility to check our website
					periodically for changes. Your continued use of or access to our website or the Service following the posting of any
					changes to these Terms constitutes acceptance of those changes.
				</p>
				<h3 id="server_section_16">
					<span className="marker">Section 16</span> <label>Risk</label>
				</h3>
				<p>
					<span className="counter">16.1.</span> You acknowledge and agree that you are aware of the risks associated with
					transactions with digital currencies and their derivatives.
				</p>
				<p>
					<span className="counter">16.2.</span> You acknowledge and agree that your use of the Service is at your own risk.
				</p>
				<p>
					<span className="counter">16.3.</span> You assume all risks associated with transactions with digital currencies and
					their derivatives. Fast Coin is not responsible for any such risks or adverse results.
				</p>
				<h3 id="server_section_17">
					<span className="marker">Section 17</span> <label>Intellectual Property Rights</label>
				</h3>
				<p>
					<span className="counter">17.1.</span> The Fast Coin exclusively owns all rights, title, and interest in the
					"FIXEDFLOAT" name, "FIXEDFLOAT" logo, the names of individual services and their logos, patents, copyrights (including
					rights in derivative works), trademarks or service marks, logos and designs, trade secrets, and other intellectual
					property embodied by, or contained on the Website and relating webpages, documentation etc.
				</p>
				<p>
					<span className="counter">17.2.</span> You shall never use any FIXEDFLOAT logos, marks or other intellectual property
					for commercial and public use without our express permission, unless otherwise explicitly indicated by the Fast Coin.
				</p>
				<p>
					<span className="counter">17.3.</span> You shall not copy, reproduce, distribute, modify, create derivative works of,
					publicly display, publicly perform, republish, download, store, transmit or otherwise use any of the Fast Coin logos,
					marks or intellectual property.
				</p>
				<p>
					<span className="counter">17.4.</span> You also shall not make any public statement or issue any press release related
					to us or our services without our express permission.
				</p>
				<p>
					<span className="counter">17.5.</span> Under these Terms you are entitled to use any logos or marks for your personal,
					non-commercial purposes exclusively.
				</p>
				<h3 id="server_section_18">
					<span className="marker">Section 18</span> <label>Restricted Activities</label>
				</h3>
				<p>
					<span className="counter">18.1.</span> In connection with your use of the Service, or in the course of your interactions
					with the Fast Coin, you shall not:
				</p>
				<p className="with-counter deep nogap">
					<span className="counter">18.1.1.</span> breach these Terms or any policy approved by the Fast Coin and agreed by you;
				</p>
				<p className="with-counter deep nogap">
					<span className="counter">18.1.2.</span> violate any law, statute, ordinance, or regulation;
				</p>
				<p className="with-counter deep nogap">
					<span className="counter">18.1.3.</span> infringe the Fast Coin or any third party's copyright, patent, trademark,
					exchange secret or other intellectual property rights, or rights of publicity or privacy;
				</p>
				<p className="with-counter deep nogap">
					<span className="counter">18.1.4.</span> act in a manner that is defamatory, trade libellous, threatening or harassing
					to our employees, agents or other users;
				</p>
				<p className="with-counter deep nogap">
					<span className="counter">18.1.5.</span> provide false, inaccurate or misleading Information;
				</p>
				<p className="with-counter deep nogap">
					<span className="counter">18.1.6.</span> engage in potentially fraudulent or suspicious activity and/or transactions.
				</p>
				<h3 id="server_section_19">
					<span className="marker">Section 19</span> <label>Disputes Resolution</label>
				</h3>
				<p>
					<span className="counter">19.1.</span> All disputes and disagreements that might arise from these Terms shall be
					resolved by means of negotiations.
				</p>
				<p>
					<span className="counter">19.2.</span> You agree that for the purposes of the settlement of disputes between you and us,
					an e-mail correspondence with the authorized persons of the Fast Coin at: info@fixedfloat.com shall be the effective and
					binding method of communication.
				</p>
				<p>
					<span className="counter">19.3.</span> If the parties cannot agree on the subject of the dispute within thirty (30)
					days, the dispute shall be shall be referred to and finally resolved by the relevant court.
				</p>
				<p>
					<span className="counter">19.4.</span> To the extent allowed by the applicable law, you agree that you will bring any
					claim arising from or connected with these Terms of use within one (1) year from the date of which such claim arose.
					Otherwise such claims will be irrevocably waived.
				</p>{' '}
			</div>
			<Footer />
		</div>
	)
}
