import useTeamList, { ITeamList } from '@/hooks/useTeamList'
import { formatStrAddress2, numFormat } from '@/utils'
// import { Snippet } from '@nextui-org/react'

import { ReactComponent as Copy2Icon } from '@/assets/svg/copy_2.svg'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'

export default function TeamList() {
	const { t } = useTranslation()
	const { data: teamList } = useTeamList()

	const list = teamList?.list || ([] as ITeamList[])

	return (
		<div className="relative z-10 flex w-full flex-col gap-1.5 px-2 pt-6">
			{list.map((team, key) => (
				<div key={key} className="rounded bg-[#151A30] px-1">
					<div className="flex h-10 items-center gap-2 border-b border-[#333A58] px-2">
						<div className="text-sm text-white">{formatStrAddress2(10, 10, team.address)}</div>
						<Copy2Icon
							className="h-3 w-3"
							onClick={() => {
								copy(team.address)
								toast.success(t('home.message.tip5'), { id: 'copy' })
							}}
						/>
						{/* <Snippet
							classNames={{
								base: 'p-0 gap-0 bg-transparent',
								copyButton: '!w-3 !h-3 !p-0 min-w-3',
							}}
							symbol=""
							copyIcon={<Copy2Icon />}
							codeString={team.address}
							color="default"
						></Snippet> */}
					</div>
					<div className="grid grid-cols-2 items-center gap-x-10 gap-y-6 px-2 pb-5 pt-4">
						<div className="flex w-full flex-col items-start gap-4">
							<span className="text-[0.625rem] leading-normal text-[#A6A6A6]">{t('home.team.list.title1')}</span>
							<div className="flex items-center gap-1 text-xs text-white">
								{numFormat(Number(team.stake))} <span className="text-[#45648B]">USDT</span>{' '}
							</div>
						</div>
						<div className="flex w-full flex-col items-start gap-4">
							<span className="text-[0.625rem] leading-normal text-[#A6A6A6]">{t('home.team.list.title2')}</span>
							<div className="flex items-center gap-1 text-xs text-white">
								{numFormat(Number(team.teamStake))} <span className="text-[#45648B]">USDT</span>{' '}
							</div>
						</div>
						<div className="flex w-full flex-col items-start gap-4">
							<span className="text-[0.625rem] leading-normal text-[#A6A6A6]">{t('home.team.list.title3')}</span>
							<div className="flex items-center gap-1 text-xs text-white">{numFormat(Number(team.count))}</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
