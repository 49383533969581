import StakePage from '../Stake'
import Share from '@/pages/Share'
import Team from '@/pages/Team'
import WithDraw from '@/pages/Withdraw'

export const stakeStyles = {
	base: 'rounded-[10px] p-5 backdrop-blur-sm border border-white/10',
}

export default function NewStake() {
	return (
		<div className="flex flex-col gap-4">
			<div className={stakeStyles.base}>
				<StakePage />
			</div>
			<div className={stakeStyles.base}>
				<WithDraw />
			</div>
			<div className={stakeStyles.base}>
				<Share />
			</div>
			<Team />
		</div>
	)
}
