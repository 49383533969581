import { serverOrderRecent } from '@/api/order'
import useWalletStore from '@/lib/store/useWalletStore'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useEffect } from 'react'
import useSWR from 'swr'

export type HomeTradeType = {
	id: number
	from_code: string
	to_code: string
	send_amount: string
	ccies_from_code: {
		code: string
		coin: string
		name: string
		network: string
		color: string
		logo: string
	}
	ccies_to_code: {
		code: string
		coin: string
		name: string
		network: string
		color: string
		logo: string
	}
}

export default function useHomeTrade() {
	const { isConnected, address } = useWeb3ModalAccount()

	const isSign = useWalletStore(state => state.isSign)

	useEffect(() => {
		console.log('isToken', isSign)
	}, [isSign])

	const { data, ...args } = useSWR(
		isConnected && !isSign ? ['homeTrade', address] : ['homeTrade'],
		async () => {
			let list: HomeTradeType[] = []
			const dataParams: any = await serverOrderRecent()
			if (dataParams.code === 0) {
				list = dataParams.data
			}
			return {
				list,
			}
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return {
		data: {
			orderList: data?.list || ([] as HomeTradeType[]),
		},
		...args,
	}
}
