import { numFormat } from '@/utils'
import { useEffect, useMemo, useRef } from 'react'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useWalletStore from '@/lib/store/useWalletStore'
import { serverQlOrdersUsersList } from './graphql'
import { formatEther } from 'viem'
import moment from 'moment'
import { Spinner } from '@nextui-org/react'
import { useInfiniteScroll } from 'ahooks'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'

export type IOrderInfo = {
	account: string
	amount: string
	endAt: string
	id: string
	rate: string
	reward: string
	startAt: string
	isWithdraw: boolean
	lastReceiveAt: string
	sentReward: string
}

interface Result {
	list: IOrderInfo[]
	page: number | undefined
	noData: boolean
}

export default function StakeOrders() {
	const { t } = useTranslation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const { isConnected, address } = useWeb3ModalAccount()

	const isSign = useWalletStore(state => state.isSign)

	const ref = useRef<any>(null)

	const { data, loading, loadingMore, noMore, reload } = useInfiniteScroll(d => getLoadMoreList(d?.page), {
		target: ref,
		isNoMore: d => {
			return d?.noData === true
		},
	})

	const getLoadMoreList = async (page = 1): Promise<Result> => {
		return new Promise(async resolve => {
			if (!isConnected || isSign || !address) {
				resolve({
					list: [],
					noData: true,
					page: undefined,
				})
				return
			}
			try {
				const skip = new BigNumber(page - 1).times(10).toNumber()
				const data = await serverQlOrdersUsersList({ account: address, skip }).then(res => {
					page = page + 1
					return {
						list: res.list,
						page: page,
						noData: res.list.length === 0,
					}
				})
				resolve(data)
			} catch (error) {}
		})
	}

	useEffect(() => {
		if (isConnected && !isSign) reload()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSign, isConnected])

	const list = useMemo(() => {
		return (data?.list || []).map(re => {
			return {
				...re,
				amount: formatEther(re.amount as any as bigint),
				reward: formatEther(re.reward as any as bigint),
				startAt: moment.unix(Number(re.startAt)).format('YYYY/MM/DD HH:mm:ss'),
				endAt: moment.unix(Number(re.endAt)).format('YYYY/MM/DD HH:mm:ss'),
				sentReward: formatEther(re.sentReward as any as bigint),
			}
		})
	}, [data?.list])

	return (
		<div ref={ref} className="h-full overflow-hidden overflow-y-scroll pt-4 md:pt-[3.875rem]">
			<div className="tail-text-menu px-4 text-2xl font-bold leading-9">{t('home.orders.title')}</div>
			<div className="flex w-full flex-col items-center justify-center gap-2.5 py-6">
				{loading ? (
					<div className="flex h-[12.5rem] w-full items-center justify-center">
						<Spinner size="sm" color="white" />
					</div>
				) : (
					<>
						{list.map((item, key) => (
							<div
								key={key}
								className="relative grid w-full grid-cols-2 gap-x-5 gap-y-4 rounded-md bg-[#151A30] p-4 hover:bg-[#1F2849]"
							>
								{moment().isAfter(moment(item.endAt)) && (
									<div className="absolute right-3 top-3 flex h-5 min-w-[3.5rem] items-center justify-center rounded">
										<span className="text-xs text-[#F7931A]">{item.isWithdraw ? '已赎回' : '未赎回'}</span>
									</div>
								)}

								<div>
									<span className="text-[0.625rem] text-[#A6A6A6]">{t('home.orders.tips1')}</span>
									<div className="flex items-center gap-0.5 text-xs text-white">
										{numFormat(item.amount)} <span className="text-[#45648B]">USDT</span>
									</div>
								</div>
								<div>
									<span className="text-[0.625rem] text-[#A6A6A6]">{t('home.orders.tips2')}</span>
									<div className="flex items-center gap-0.5 text-xs text-white">
										{numFormat(item.reward)} <span className="text-[#45648B]">USDT</span>
									</div>
								</div>
								<div>
									<span className="text-[0.625rem] text-[#A6A6A6]">{t('home.orders.tips3')}</span>
									<div className="flex items-center gap-0.5 text-xs text-white">{item.startAt}</div>
								</div>
								<div>
									<span className="text-[0.625rem] text-[#A6A6A6]">{t('home.orders.tips4')}</span>
									<div className="flex items-center gap-0.5 text-xs text-white">{item.endAt}</div>
								</div>
								<div>
									<span className="text-[0.625rem] text-[#A6A6A6]">{t('home.orders.tips5')}</span>
									<div className="flex items-center gap-0.5 text-xs text-white">
										{numFormat(item.sentReward)}
										<span className="text-[#45648B]">USDT</span>
									</div>
								</div>
							</div>
						))}
						{loadingMore && (
							<div className="flex w-full items-center justify-center py-4">
								<Spinner size="sm" color="white" />
							</div>
						)}
						{noMore && (
							<div className="flex w-full items-center justify-center py-4">
								<span className="text-xs text-[#A6A6A6]">{t('home.orders.no.more')}</span>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}
