import { serverOrderFeeCount } from '@/api/order'
import useWalletStore from '@/lib/store/useWalletStore'
import { Spinner } from '@nextui-org/react'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

export default function HomeAdd() {
	const { t } = useTranslation()
	const { isConnected, address } = useWeb3ModalAccount()

	const isSign = useWalletStore(state => state.isSign)

	const { data, ...args } = useSWR(
		isConnected && !isSign ? ['HomeAdd', address] : ['HomeAdd'],
		async () => {
			const dataParams: any = await serverOrderFeeCount()
			if (dataParams.code === 0) {
				return {
					info: {
						totalFee: dataParams.data.totalFee,
						countAddress: dataParams.data.countAddress,
					},
				}
			}
			return {
				info: {
					totalFee: '0',
					countAddress: '0',
				},
			}
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	const loading = args.isLoading || args.isValidating

	return (
		<div className="my-8 px-2">
			<div className="tail-text-menu w-full text-center text-2xl font-bold">{t('app.fee.title1')}</div>
			{loading ? (
				<div className="flex min-h-[200px] items-center justify-center">
					<Spinner color="white" size="sm" />
				</div>
			) : (
				<div className="flex flex-col gap-4 py-5">
					<div className="rounded-[0.625rem] bg-[#21284B]">
						<div className="flex items-center justify-center gap-2 py-4 text-xs">{data?.info.totalFee} USDT</div>
					</div>
				</div>
			)}

			<div className="tail-text-menu w-full text-center text-2xl font-bold">{t('app.fee.title2')}</div>

			{loading ? (
				<div className="flex min-h-[200px] items-center justify-center">
					<Spinner color="white" size="sm" />
				</div>
			) : (
				<div className="flex flex-col gap-4 py-5">
					<div className="rounded-[0.625rem] bg-[#21284B]">
						<div className="flex items-center justify-center gap-2 py-4 text-xs">{data?.info.countAddress}</div>
					</div>
				</div>
			)}
		</div>
	)
}
