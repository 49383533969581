import { serverTeamList } from '@/api/order'
import { serverQlOrdersUsersList } from '@/pages/StakeOrders/graphql'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'

export interface ITeamList {
	address: string
	stake: number
	teamStake: number
	count: number
}

export default function useStakeOrdersList() {
	const { isConnected, address } = useWeb3ModalAccount()

	const { data, ...args } = useSWR(
		isConnected ? ['useStakeOrdersList', address] : null,
		async ([, add]) => {
			try {
				return await serverQlOrdersUsersList({ account: add || '', skip: 0 }).then(res => {
					return {
						list: res.list,
					}
				})
			} catch (error) {
				return {
					list: [],
				}
			}
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return {
		data,
		...args,
	}
}
