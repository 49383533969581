const chat = {
	'chat.input.placeholder1': 'Bạn muốn biết điều gì?',
	'chat.input.placeholder2': 'Đang chờ câu trả lời cuối cùng',
	'chat.submit.btn': 'Gửi',
	'chat.submit.on.address': 'Tài khoản chưa được kích hoạt, không thể trò chuyện',
	'chat.wallet.btn.title': 'Ví chưa được liên kết, không thể trò chuyện',
	'chat.data.params.msg1': 'Lỗi hệ thống',
	'chat.data.params.msg2': 'Người dùng chưa được kích hoạt',
	'chat.data.params.msg3': 'Chỉ có 10 lần truy cập mỗi ngày',
	'chat.data.params.msg1001': 'Json không hợp lệ',
	'chat.data.params.msg.other': 'Yêu cầu không thành công, vui lòng thử lại',
}

export default chat
