const chat = {
	'chat.input.placeholder1': 'คุณต้องการทราบอะไร',
	'chat.input.placeholder2': 'กำลังรอคำตอบสุดท้าย',
	'chat.submit.btn': 'ส่ง',
	'chat.submit.on.address': 'บัญชีที่ไม่ได้เปิดใช้งาน ไม่สามารถแชทได้',
	'chat.wallet.btn.title': 'กระเป๋าสตางค์ที่ไม่ได้เชื่อมโยง ไม่สามารถแชทได้',
	'chat.data.params.msg1': 'ข้อผิดพลาดของระบบ',
	'chat.data.params.msg2': 'ผู้ใช้ไม่ได้เปิดใช้งาน',
	'chat.data.params.msg3': 'เข้าถึงได้เพียง 10 ครั้งต่อวัน',
	'chat.data.params.msg1001': 'json ไม่ถูกต้อง',
	'chat.data.params.msg.other': 'การร้องขอล้มเหลว โปรดลองอีกครั้ง',
}

export default chat
