import IconClose from '@/components/Icon/close'
import IconOpen from '@/components/Icon/open'
import { Accordion, AccordionItem, Selection } from '@nextui-org/react'
import { useState } from 'react'

export default function HelpAbout() {
	const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(['1']))

	return (
		<Accordion selectedKeys={selectedKeys} onSelectionChange={setSelectedKeys}>
			<AccordionItem
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				startContent={<div className="text-3xl">1</div>}
				key="1"
				classNames={{
					title: 'text-2xl',
				}}
				aria-label="Accordion 1"
				title="What is Fast Coin?"
			>
				<div className="flex flex-col gap-3 pl-6 text-sm">
					<p>
						<strong className="mr-1">Fast Coin</strong>
						is a fully automated service for exchanging cryptocurrencies and tokens on favorable terms. Fast Coin is not
						custodial. The exchange takes place immediately after receiving the coins and the required number of network
						confirmations.
					</p>
					<p>
						Fast Coin was launched in 2018 by a team of blockchain specialists with extensive experience in developing rich web
						applications. In an effort to provide a convenient platform for cryptocurrency exchanges, Fast Coin is constantly
						actively developing and improving for its customers.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">2</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="2"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="Why Fast Coin is the smartest way to exchange cryptocurrency?"
			>
				<div className="flex flex-col gap-3 pl-6 text-sm">
					<p>
						Our top priority is to provide a high quality cryptocurrency exchange service through our unique solutions that do not
						compromise speed and security.
					</p>
					<div className="grid grid-cols-2 gap-3">
						<div className="flex flex-col gap-2 text-sm">
							<span className="font-bold text-[#008FDF]">1. Save your money.</span>
							<p>Make an exchange at the most favorable rate and with transparent commission. We always have the better offer.</p>
						</div>
						<div className="flex flex-col gap-2 text-sm">
							<span className="font-bold text-[#008FDF]">2. Save your time.</span>
							<p>
								Use our fully automated processing and get an answer to any of your questions instantly with our supreme support.
							</p>
						</div>
						<div className="flex flex-col gap-2 text-sm">
							<span className="font-bold text-[#008FDF]">3. Hassle-free exchange.</span>
							<p>No registration and unnecessary details. It is simple as 1-2-3.</p>
						</div>
						<div className="flex flex-col gap-2 text-sm">
							<span className="font-bold text-[#008FDF]">4. Cross-platform.</span>
							<p>Fast Coin is designed with performance in mind and works perfectly on any device.</p>
						</div>
					</div>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">3</div>}
				key="3"
				classNames={{
					title: 'text-2xl',
				}}
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 3"
				title="Why should I trust Fast Coin?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						For 4 years, Fast Coin has gained the trust of more than a million users from all over the world. We respect the
						anonymity and security of our customers — no data is required to make exchanges. Fast Coin works completely
						automatically and support is available on Live Chat 24/7.
					</p>
					<p>The loyalty of our customers is fully reflected on popular sites in Trustpilot and BestChange reviews.</p>
					<p>Fast Coin on social networks:</p>
					<ul className="text-[#F7931A]">
						<li>Facebook</li>
						<li>Twitter</li>
						<li>Instagram</li>
					</ul>
					<p>Fast Coin on forums:</p>
					<ul className="text-[#F7931A]">
						<li>Reddit</li>
						<li>Bitcointalk</li>
						<li>Medium</li>
						<li>Steemit</li>
					</ul>
				</div>
			</AccordionItem>
			<AccordionItem
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				startContent={<div className="text-3xl">4</div>}
				key="4"
				classNames={{
					title: 'text-2xl',
				}}
				aria-label="Accordion 1"
				title="How does it work?"
			>
				<div className="flex flex-col gap-3 pl-6 text-sm">
					<p>
						For example, imagine that we change Bitcoin to Ethereum <br />
						<strong>Step 1.</strong>
						In the "Send" field, select Bitcoin and enter the amount you want to exchange. <br />
						<strong>Step 2.</strong> Choose a fixed or float rate, then enter or scan the address to which you will receive
						Ethereum and click the button "Exchange Now". <br />
						<strong>Step 3.</strong> Send Bitcoin to the wallet specified on the order page, and after several confirmations,
						Ethereum will be credited to the wallet specified in <strong>Step 2.</strong>
					</p>
				</div>
			</AccordionItem>
		</Accordion>
	)
}
