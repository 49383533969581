const about = {
	'about.title': 'Tentang kami',
	'tentang.konten':
		'Mata uang kripto membuka peluang baru untuk kebebasan finansial. Semakin besar basis pengguna, semakin besar pula keunggulan kompetitif yang ditawarkan oleh teknologi buku besar terdesentralisasi yang sedang berkembang. Fast Coin memberi Anda alat untuk memaksimalkan aset digital Anda melalui platform pertukaran yang mudah digunakan. ',
	'about.title2': 'Keuntungan',
	'about.title2.tips1':
		'Kenyamanan pertukaran maksimum dan kemampuan untuk memilih strategi akan memungkinkan Anda melakukan pertukaran yang menguntungkan',
	'about.title2.tips2':
		'Kami menyediakan pertukaran yang aman. Anda tidak mengambil risiko apa pun dan Anda dapat melihat komisi Anda secara instan',
	'about.title2.tips3': 'Anda dapat menebus secara otomatis dengan kecepatan tercepat di perangkat apa pun',
	'about.title3': 'Misi',
	'about.title3.tips1': `Kami adalah pemain visioner jangka panjang yang berharap menjadi mitra andal dan tepercaya Anda di bidang aset digital. Misi kami adalah membuat ekonomi kripto bekerja untuk Anda dengan menyederhanakan proses pertukaran melalui solusi praktis dan terukur. `,
	'about.title4': 'Mata uang yang didukung',
}

export default about
