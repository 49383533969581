const menu = {
	'menu.home': 'Home',
	'menu.contract': 'Contract',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'Option Contract',
	'menu.option.ai': 'AI High Frequency Arbitrage',
	'menu.option.qr': 'Quantitative Robot',
	'menu.option.ai.mall': 'AI Smart Mall',

	'menu.my.orders': 'My Orders',
	'menu.stake': 'Stake',
	'menu.about': 'About',
	'menu.about.faq': 'FAQ',
	'menu.serve': 'Terms of Service',
	'menu.privacy': 'Privacy Policy',
}

export default menu
