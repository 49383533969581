import { DEFAULT_CHAINID } from '~/config/*'
import { multicall } from '@/lib/web3/multicall'
import useSWR from 'swr'
import getChainConfig from '@/lib/web3/getChainConfig'
import { USDT, VAULTPROXY } from '@/contract/abi'
import { formatEther, zeroAddress } from 'viem'
import { LeavelValueInit } from '@/utils/leavel'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import BigNumber from 'bignumber.js'

/**
 * @type IState 首页参数说明
 * @param rewards.team 团队收益
 * @param rewards.other 其他收益
 * @param activated 是否绑定
 * @param isBindUser 绑定的用户地址
 * @param teamBalance 团队总质押
 * @param teamVolume 团队人数
 * @param leavel 团队等级
 * @param minStakeAmount 最小质押金额
 * @param authorizedAmount 当前授权金额
 * @param currentUserStake 当前用户的质押金额
 * @param totalAmountUsdt 总质押金额-usdt-固定地址
 *
 * @param rewardRate 活期的费率
 * @param orderRewardRate 定期的费率
 * @param lastReceiveAt 上次领取时间
 *
 * @param orderWithdrawAmount 定期可提取金额
 * @param orderRewardAmount  定期收益
 */
type IState = {
	rewards: {
		team: number
		other: number
	}
	activated: boolean
	isBindUser: string
	teamBalance: number
	teamVolume: number
	leavel: string
	minStakeAmount: number
	authorizedAmount: number
	currentUserStake: number
	totalAmountUsdt: number

	rewardRate: number
	orderRewardRate: number

	lastReceiveAt: number
	currentWithdrawAmount: number

	orderWithdrawAmount: number
	orderRewardAmount: number
}

const StateInit: IState = {
	rewards: {
		team: 0,
		other: 0,
	},
	activated: false,
	isBindUser: zeroAddress,
	teamBalance: 0,
	teamVolume: 0,
	leavel: '0',
	minStakeAmount: 0,
	authorizedAmount: 0,
	currentUserStake: 0,
	totalAmountUsdt: 0,
	rewardRate: 0,
	orderRewardRate: 0,
	lastReceiveAt: 0,
	currentWithdrawAmount: 0,
	orderWithdrawAmount: 0,
	orderRewardAmount: 0,
}

export default function useHomeHooks() {
	const currentChainId = DEFAULT_CHAINID
	const { isConnected, address } = useWeb3ModalAccount()
	// 合约信息
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']
	const USDT_ADDRESS = contracts['USDT_ADDRESS']

	const fetcherUsdtTotal = async () => {
		return await multicall(currentChainId, {
			contracts: [
				{
					address: USDT_ADDRESS,
					abi: USDT,
					functionName: 'balanceOf',
					args: ['0xa9251ca9DE909CB71783723713B21E4233fbf1B1'],
				},
			],
		})
			.then(res => {
				console.log('ssss', res)
				return {
					amount: res[0].status === 'success' ? res[0].result : 0,
				}
			})
			.then(res => {
				return {
					amount: Number(formatEther(res.amount as bigint)),
				}
			})
	}

	const fetcher = async () => {
		const { amount } = await fetcherUsdtTotal()
		if (!isConnected) {
			return {
				...StateInit,
				totalAmountUsdt: amount,
			}
		}
		return await multicall(currentChainId, {
			contracts: [
				{
					address: USDT_ADDRESS,
					abi: USDT,
					functionName: 'allowance',
					args: [address, VaultProxy_ADDRESS],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'MIN_STAKE_AMOUNT',
					args: [],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'users',
					args: [address],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'earned',
					args: [address],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'rebate',
					args: [address],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'rewardRate',
					args: [],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'orderRewardRate',
					args: [],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'getOrderWithdrawAmount',
					args: [address],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'getOrderRewardAmount',
					args: [address],
				},
			],
		}).then(res => {
			console.log('home0-res', res)
			const users: any = (res[2].result || []) as number[],
				rebate = Number(res[4].result),
				earned = Number(formatEther((res[3].result || 0) as bigint))
			console.log('user', users, 'rebate', rebate, 'earned', earned)
			const reward = new BigNumber(formatEther((res[5].result || 0) as bigint)).times(86400).toNumber(),
				order = new BigNumber(formatEther((res[6].result || 0) as bigint)).times(86400).toNumber()
			console.log('reward', reward, 'order', order)

			const rewardRate = new BigNumber(1).times(new BigNumber(1).plus(reward).pow(365)).times(100).toNumber(),
				orderRewardRate = new BigNumber(1).times(new BigNumber(1).plus(order).pow(365)).times(100).toNumber()
			return {
				rewards: {
					team: Number(formatEther((users[4] || 0) as bigint)),
					other: earned,
				},
				activated: users[8],
				isBindUser: users[0],
				teamBalance: Number(formatEther((users[3] || 0) as bigint)),
				teamVolume: Number(users[2] || 0),
				leavel: LeavelValueInit[rebate] ?? 0,
				minStakeAmount: Number(formatEther((res[1].status === 'success' ? res[1].result : 0) as bigint)),
				authorizedAmount: Number(formatEther((res[0].status === 'success' ? res[0].result : 0) as bigint)),
				currentUserStake: Number(
					BigNumber(formatEther((users[5] ?? 0) as bigint))
						.plus(formatEther((users[6] ?? 0) as bigint))
						.toNumber(),
				),
				currentWithdrawAmount: Number(formatEther((users[5] ?? 0) as bigint)),
				totalAmountUsdt: amount,
				rewardRate: Number(rewardRate.toFixed(2)),
				orderRewardRate: Number(orderRewardRate.toFixed(2)),
				lastReceiveAt: Number(users[9] || 0),
				orderWithdrawAmount: Number(formatEther((res[7].status === 'success' ? res[7].result : 0) as bigint)),
				orderRewardAmount: Number(formatEther((res[8].status === 'success' ? res[8].result : 0) as bigint)),
			}
		})
	}

	const { data, ...args } = useSWR(isConnected ? `useHomeHooks-${address}` : 'useHomeHooks', fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	return {
		data: {
			...StateInit,
			...data,
		},
		loading: args.isLoading || args.isValidating,
		...args,
	}
}
