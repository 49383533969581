const home = {
	'home.usdt.title': 'Tether USD',
	'home.usdt.title2': 'Current Income',
	'home.usdt.max': 'MAX',
	'home.usdt.balance': 'Your Balance:',
	'home.usdt.total': 'Total Staking:',
	'home.total.balance': 'Flow Cell:',
	'home.btn.approve': 'Approve',
	'home.btn.stake': 'Stake',

	'home.withdraw.title': 'Withdrawable:',
	'home.withdraw.2': 'Withdraw',
	'home.withdraw.earnings': 'Passive income',
	'home.withdraw.amount': 'Community benefits',
	'home.withdraw.btn1': 'Get Reward',
	'home.withdraw.btn2': 'Restake',

	'home.team.title': 'Total Community Staking',
	'home.team.1': 'Address',
	'home.team.upper': 'Referrer',
	'home.team.address.placeholder': 'Please enter the user address',
	'home.team.address.s': 'Bound',
	'home.team.leavel': 'Level',
	'home.team.bind': 'Bind Relation',

	'home.invite.title': 'Invite Link',
	'home.invite.copy': 'Copy Link',
	'home.invite.tip': 'Please activate your account first',

	'home.message.tip0': 'Received successfully',
	'home.message.tip1': 'Authorization successful',
	'home.message.tip2': 'Stake successful',
	'home.message.tip3': 'Minimum pledge amount: {{msg}} USDT',
	'home.message.tip4': 'Current account balance: {{msg}} USDT',
	'home.message.tip5': 'Copy successful',
	'home.message.tip6': 'Cannot bind your own address',
	'home.message.tip7': 'Binding successful',
	'home.message.tip8': 'Redemption successful',
	'home.message.tip9': 'The maximum amount that can be redeemed in the current account: {{msg}} USDT',

	// 2.0
	'home.check.order.title': 'Check Order',
	'home.2.stake.btn.1': 'Regular',
	'home.2.stake.btn.1.1': '(15 d)',
	'home.2.stake.btn.2': 'Current',
	'home.2.stake.btn.3': 'Rate: {{msg}}%',

	'home.orders.title': 'My Orders',
	'home.orders.tips1': 'Deposit Amount',
	'home.orders.tips2': 'Expected',
	'home.orders.tips3': 'Deposit Time',
	'home.orders.tips4': 'Expiration Time',
	'home.orders.tips5': 'Received',
	'home.orders.btn1': 'Collect',
	'home.orders.btn2': 'Received',
	'home.orders.no.more': 'No more data',
	'home.orders.claim.success': 'Received successfully',

	'home.2.withdraw.title': 'Time until next collection:',

	'home.regular.title': 'Regular Income',
	'home.regular.reward.success': 'Receipt successful',
	'home.regular.withdraw.success': 'Redemption successful',
	'home.regular.tips1': 'Amount redeemable',
	'home.regular.tips2': 'Available income',
	'home.regular.btn1': 'Withdraw',
	'home.regular.btn2': 'Get Reward',

	'home.team.list.title1': 'Personal Mobility',
	'home.team.list.title2': 'Team Mobility',
	'home.team.list.title3': 'Valid invitation',

	'home.state.order.tips.max': 'The number of orders in progress cannot exceed 25',
	'home.message.tip10': 'Insufficient stake amount',
}

export default home
