const other = {
	'other.help.title': 'よくある質問',
	'other.serve.title': '利用規約',
	'other.serve.tips': '最終更新日: 2024 年 7 月 30 日',
	'other.privacy.title': 'プライバシー ポリシー',
	'other.privacy.tips': '最終更新日: 2023 年 1 月 14 日',

	'orders.no.data': '注文IDが見つかりません',
	'orders.details.send': '送信',
	'orders.details.receive': '受信',
	'orders.details.orderid': '注文ID',
	'orders.details.difftime': '残り時間',
	'orders.details.type': '注文タイプ',
	'orders.details.status1': '固定為替レート',
	'orders.details.status2': '変動為替レート',
	'orders.details.createTime': '作成時間',
	'orders.details.address': '住所',
	'orders.details.with.amount': '金額あり',
	'orders.details.b.send': '送信',
	'orders.details.b.send1': '宛先アドレス:',
	'orders.details.b.s': '為替レートは受領時に確定します',
	'orders.details.b.s1': 'ネットワーク確認。 ',
	'orders.details.b.s2': '受取先住所',
	'orders.details.progress.1': '入金待ち',
	'orders.details.progress.2': '確認待ち',
	'orders.details.progress.3': '交換実行',
	'orders.details.progress.4': '完了',

	'orders.list.1': '日付/OROER ID',
	'orders.list.2': '送信',
	'orders.list.3': '受信',
	'orders.list.4': '受取先住所',
	'orders.title': '完了した注文',
}

export default other
