import BG from '@/assets/fcm-bottom.png'
import { cnTw } from '@/utils'
import { useLocation } from 'react-router-dom'

export default function BottomBar() {
	const location = useLocation()

	return (
		<div
			className={cnTw(
				'absolute left-0 w-full',
				location.pathname.startsWith('/home') ||
					location.pathname.startsWith('/myOrders') ||
					location.pathname.startsWith('/stake')
					? 'top-[calc(770px_-_402px)]'
					: 'bottom-0',
			)}
		>
			<img src={BG} className="h-auto w-full sm:rounded-b-2xl" alt="" />
		</div>
	)
}
