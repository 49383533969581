import moment from 'moment'
import { formatStrAddress2 } from '@/utils'
import { Link } from 'react-router-dom'
import { Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, getKeyValue } from '@nextui-org/react'

import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll'
import { useAsyncList } from '@react-stately/data'
import { useState } from 'react'
import { serverOrderList } from '@/api/order'
import { useTranslation } from 'react-i18next'

const columns = [
	{
		key: 'createTime',
		label: 'orders.list.1',
	},
	{
		key: 'send',
		label: 'orders.list.2',
	},
	{
		key: 'end',
		label: 'orders.list.3',
	},
	{
		key: 'receiveAddress',
		label: 'orders.list.4',
	},
]

export default function Order() {
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState(true)
	const [hasMore, setHasMore] = useState(false)

	let list = useAsyncList({
		async load({ signal, cursor }: any) {
			let page = cursor ? cursor : 1
			console.log('page', page, 'cursor', cursor)
			const res = await serverOrderList({ page, limit: 20 }).then((res: any) => {
				if (res.code === 0)
					return {
						count: res.data.count,
						list: res.data.list.map((item: any) => {
							return {
								id: item.id,
								orderid: item.order_sn,
								createTime: item.created_at,
								send: {
									image: item.ccies_from_code.logo,
									amount: Number(item.send_amount),
									symbol: item.from_code,
								},
								end: {
									image: item.ccies_to_code.logo,
									amount: Number(item.to_amount),
									symbol: item.to_code,
								},
								receiveAddress: item.to_address,
							}
						}),
					}
				return {
					count: 0,
					list: [],
				}
			})
			setHasMore(res.list.length > 0)
			setIsLoading(false)

			return {
				items: res.list,
				cursor: ++page,
			}
		},
	})

	const [loaderRef, scrollerRef] = useInfiniteScroll({ hasMore, onLoadMore: list.loadMore })

	return (
		<div className="pt-[1.625rem]">
			<div className="tail-text-menu pl-2 text-2xl font-bold">{t('orders.title')}</div>
			<Table
				isHeaderSticky
				selectionMode="single"
				color="default"
				classNames={{
					base: 'max-h-[520px] overflow-scroll',
					// table: 'min-h-[400px]',
					wrapper: 'shadow-none bg-transparent border-transparent px-0',
					th: 'bg-[#151A30] text-[#A6A6A6] font-bold text-[10px] h-[28px]',
					// td: 'before:bg-[#151A30] before:!opacity-100 group-aria-[selected=false]:group-data-[hover=true]:before:bg-[#1F2849]',
				}}
				baseRef={scrollerRef}
				bottomContent={
					hasMore ? (
						<div className="flex w-full justify-center">
							<Spinner size="sm" ref={loaderRef} color="white" />
						</div>
					) : null
				}
				aria-label="big node"
			>
				<TableHeader columns={columns}>{column => <TableColumn key={column.key}>{t(column.label)}</TableColumn>}</TableHeader>
				<TableBody isLoading={isLoading} items={list.items} loadingContent={<Spinner size="sm" color="white" />}>
					{(item: any) => (
						<TableRow key={item.id}>
							{columnKey => {
								if (columnKey === 'createTime')
									return (
										<TableCell>
											<div className="pr-2">
												{moment(item.createTime).format('MM/DD/YYYY')} <br />
												<Link to={`/ordersDetails?id=${item.id}`} className="text-[#45648B] hover:text-primary-300">
													{item.orderid}
												</Link>
											</div>
										</TableCell>
									)
								if (columnKey === 'send')
									return (
										<TableCell>
											<div className="flex items-center gap-1 whitespace-nowrap px-2">
												<img src={item.send.image} className="h-3 w-3 rounded-full" alt="" />
												<span>{item.send.amount}</span>
												<span>{item.send.symbol}</span>
											</div>
										</TableCell>
									)
								if (columnKey === 'end')
									return (
										<TableCell>
											<div className="flex items-center gap-1 whitespace-nowrap px-2">
												<img src={item.end.image} className="h-3 w-3 rounded-full" alt="" />
												<span>{item.end.amount}</span>
												<span>{item.end.symbol}</span>
											</div>
										</TableCell>
									)
								if (columnKey === 'receiveAddress')
									return (
										<TableCell>
											<div className="whitespace-nowrap pl-2">{formatStrAddress2(10, 10, item.receiveAddress)}</div>
										</TableCell>
									)
								return <TableCell>{getKeyValue(item, columnKey)}</TableCell>
							}}
						</TableRow>
					)}
				</TableBody>
			</Table>
		</div>
	)
}
