export default function IconOpen() {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#0A273F" stroke="#008FDF" />
			<g clipPath="url(#clip0_2481_527)">
				<path
					d="M28.0775 19.2406H20.5997V11.7627C20.6006 11.6734 20.5836 11.5847 20.5499 11.5019C20.5161 11.4191 20.4662 11.3439 20.403 11.2807C20.3399 11.2174 20.2648 11.1674 20.182 11.1335C20.0993 11.0996 20.0107 11.0825 19.9213 11.0832C19.8318 11.0823 19.743 11.0993 19.6602 11.1331C19.5773 11.167 19.5021 11.217 19.4388 11.2803C19.3755 11.3435 19.3255 11.4188 19.2917 11.5017C19.2578 11.5845 19.2409 11.6733 19.2418 11.7627V19.2406H11.7628C11.6733 19.2397 11.5845 19.2567 11.5017 19.2905C11.4188 19.3243 11.3436 19.3744 11.2803 19.4376C11.217 19.5009 11.167 19.5762 11.1332 19.659C11.0993 19.7419 11.0824 19.8306 11.0833 19.9201C11.0833 20.3004 11.3825 20.5996 11.7628 20.5996H19.2407V28.0775C19.2407 28.4578 19.5399 28.757 19.9202 28.757C20.0096 28.7579 20.0984 28.7409 20.1813 28.7071C20.2641 28.6733 20.3394 28.6233 20.4027 28.56C20.4659 28.4967 20.5159 28.4214 20.5498 28.3386C20.5836 28.2557 20.6006 28.167 20.5997 28.0775V20.5996H28.0775C28.167 20.6005 28.2558 20.5836 28.3386 20.5497C28.4215 20.5159 28.4967 20.4659 28.56 20.4026C28.6233 20.3393 28.6733 20.2641 28.7071 20.1812C28.741 20.0984 28.7579 20.0096 28.757 19.9201C28.7579 19.8306 28.741 19.7419 28.7071 19.659C28.6733 19.5762 28.6233 19.5009 28.56 19.4376C28.4967 19.3744 28.4215 19.3243 28.3386 19.2905C28.2558 19.2567 28.167 19.2397 28.0775 19.2406Z"
					fill="#008FDF"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2481_527">
					<rect width="18" height="18" fill="white" transform="translate(11 11)" />
				</clipPath>
			</defs>
		</svg>
	)
}
