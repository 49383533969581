import { ReactComponent as MINLOGO } from '@/assets/logo-min.svg'

import { ReactComponent as ICON1 } from '@/assets/about/icon1.svg'
import { ReactComponent as ICON2 } from '@/assets/about/icon2.svg'
import { ReactComponent as ICON3 } from '@/assets/about/icon3.svg'

import TASK_BG from '@/assets/about/task-bg.png'
import { ReactComponent as TASK } from '@/assets/about/task.svg'

import Footer from '@/components/Footer'
import { tokens } from './init'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const superior = [
	{ icon: <ICON1 />, title: 'about.title2.tips1' },
	{ icon: <ICON2 />, title: 'about.title2.tips2' },
	{ icon: <ICON3 />, title: 'about.title2.tips3' },
]

export default function About() {
	const { t } = useTranslation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="h-full overflow-hidden overflow-y-scroll pt-4 md:pt-[3.875rem]">
			<div className="tail-text-menu px-4 text-[2rem] font-bold leading-9">{t('about.title')}</div>
			<div className="flex items-start gap-4 px-4 pb-6 pt-4">
				<MINLOGO className="h-9 w-9 shrink-0" />
				<p className="text-[1.25rem] leading-normal text-[#D9D9D9]">{t('about.content')}</p>
			</div>
			<div className="tail-text-menu px-4 py-4 text-2xl font-bold">{t('about.title2')}</div>
			<div className="flex flex-col gap-4 px-4">
				{superior.map((item, index) => (
					<div className="tail-about-superior min-h-[7.625rem] rounded-md p-6" key={index}>
						{item.icon}
						<div className="mt-4 text-xs text-[#D9D9D9]">{t(item.title)}</div>
					</div>
				))}
			</div>
			<div
				className="my-20 h-[33rem]"
				style={{
					background: `url(${TASK_BG})`,
				}}
			>
				<div className="flex h-full w-full flex-col items-center justify-center px-4">
					<TASK />
					<div className="tail-text-menu mt-6 pb-4 text-2xl font-bold">{t('about.title3')}</div>
					<p className="text-lg text-[#D9D9D9]">{t('about.title3.tips1')}</p>
				</div>
			</div>
			<div className="tail-text-menu px-4 text-2xl font-bold">{t('about.title4')}</div>
			<div className="grid grid-cols-2 gap-4 px-4 pt-4">
				{tokens.map((item, key) => (
					<div
						className="flex min-h-[8.125rem] flex-col items-center justify-center gap-3 rounded-[0.625rem] bg-[#161A30]"
						key={key}
					>
						{item.icon}
						<span className="px-5 text-center text-xs text-white">{item.network}</span>
					</div>
				))}
			</div>
			<Footer />
		</div>
	)
}
