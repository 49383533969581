export const removeToken = () => sessionStorage.removeItem('token')

export const setToken = (key: string) => sessionStorage.setItem('token', key)

export const getToken = () => sessionStorage.getItem('token')

export const removeUserAddress = () => sessionStorage.removeItem('userAddress')

export const setUserAddress = (key: string) => sessionStorage.setItem('userAddress', key)

export const getUserAddress = () => sessionStorage.getItem('userAddress')
