import { getSubgraphsRequest } from '@/graphql'
import { IOrderInfo } from '..'

// 获取当前用户的定期订单列表
export const serverQlOrdersUsersList = async ({
	account,
	skip,
}: {
	account: string
	skip: number
}): Promise<{ list: IOrderInfo[] }> =>
	new Promise(async (resolve, reject) => {
		let query = `
    query MyQuery($account: String, $skip: Int) {
  orders(
    orderBy: startAt
    orderDirection: desc
    where: {account: $account,isWithdraw: false}
    skip: $skip
  ) {
    account
    amount
    endAt
    id
    rate
    reward
    startAt
    isWithdraw
    lastReceiveAt
    sentReward
  }
}
  `

		try {
			const { data } = await getSubgraphsRequest(query, {
				account: account.toLowerCase(),
				skip,
			})
			resolve({
				list: data.orders || [],
			})
		} catch (error) {
			resolve({
				list: [],
			})
		}
	})
