const other = {
	'other.help.title': 'Pertanyaan Umum',
	'other.serve.title': 'Persyaratan Layanan',
	'other.serve.tips': 'Terakhir diperbarui 30 Juli 2024',
	'other.privacy.title': 'Kebijakan Privasi',
	'other.privacy.tips': 'Terakhir diperbarui 14 Januari 2023',

	'orders.no.data': 'ID pesanan tidak ditemukan',
	'orders.details.send': 'Kirim',
	'orders.details.receive': 'Terima',
	'orders.details.orderid': 'ID Pesanan',
	'orders.details.difftime': 'Waktu yang tersisa',
	'orders.details.type': 'Jenis pesanan',
	'orders.details.status1': 'Nilai tukar tetap',
	'orders.details.status2': 'Nilai tukar mengambang',
	'orders.details.createTime': 'Waktu pembuatan',
	'orders.details.address': 'Alamat',
	'orders.details.with.amount': 'Termasuk jumlah',
	'orders.details.b.send': 'Kirim',
	'orders.details.b.send1': 'Alamat penerima:',
	'orders.details.b.s': 'Nilai tukar akan ditentukan setelah diterima',
	'orders.details.b.s1': 'Konfirmasi jaringan. ',
	'orders.details.b.s2': 'Alamat pengiriman',
	'orders.details.progress.1': 'Menunggu setoran',
	'orders.details.progress.2': 'Menunggu konfirmasi',
	'orders.details.progress.3': 'Jalankan pertukaran',
	'orders.details.progress.4': 'Selesai',

	'orders.list.1': 'Tanggal/ID OROER',
	'orders.list.2': 'Kirim',
	'orders.list.3': 'Terima',
	'orders.list.4': 'Alamat pengiriman',
	'orders.title': 'Pesanan Selesai',
}

export default other
