const other = {
	'other.help.title': '常問問題',
	'other.serve.title': '服務條款',
	'other.serve.tips': '上次更新日期 July 30, 2024',
	'other.privacy.title': '隱私權條款',
	'other.privacy.tips': '上次更新日期 January 14, 2023',

	'orders.no.data': '未找到訂單ID',
	'orders.details.send': '發送',
	'orders.details.receive': '接收',
	'orders.details.orderid': '訂單ID',
	'orders.details.difftime': '剩餘時間',
	'orders.details.type': '訂單類型',
	'orders.details.status1': '固定匯率',
	'orders.details.status2': '浮動匯率',
	'orders.details.createTime': '建立時間',
	'orders.details.address': '地址',
	'orders.details.with.amount': '含金額',
	'orders.details.b.send': '發送',
	'orders.details.b.send1': '收件者地址：',
	'orders.details.b.s': '匯率將在收到後確定',
	'orders.details.b.s1': '網路確認。 ',
	'orders.details.b.s2': '收貨地址',
	'orders.details.progress.1': '等待存款',
	'orders.details.progress.2': '等待確認',
	'orders.details.progress.3': '執行兌換',
	'orders.details.progress.4': '已完成',

	'orders.list.1': '日期/OROER ID',
	'orders.list.2': '發送',
	'orders.list.3': '接收',
	'orders.list.4': '收貨地址',
	'orders.title': '已完成的訂單',
}

export default other
