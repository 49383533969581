import { useTranslation } from 'react-i18next'
import { IOrderDetail } from '../page'
import { FourComponentIcon, ThreeExchangeIcon, TwoWaitQueryIcon, WaitIcon } from '../utils/iconSvg'

const ArrowIcon = () => (
	<svg className="mt-[0.875rem]" width="29" height="8" viewBox="0 0 29 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M28.3536 4.35355C28.5488 4.15829 28.5488 3.84171 28.3536 3.64645L25.1716 0.464466C24.9763 0.269204 24.6597 0.269204 24.4645 0.464466C24.2692 0.659728 24.2692 0.976311 24.4645 1.17157L27.2929 4L24.4645 6.82843C24.2692 7.02369 24.2692 7.34027 24.4645 7.53553C24.6597 7.7308 24.9763 7.7308 25.1716 7.53553L28.3536 4.35355ZM0 4.5H28V3.5H0V4.5Z"
			fill="#A6A6A6"
		/>
	</svg>
)

export default function ProgressRate({ info }: { info: IOrderDetail }) {
	const { t } = useTranslation()
	return (
		<div className="mt-5 flex w-full items-center justify-center pb-10">
			<div className="flex items-start gap-2">
				<div>
					<WaitIcon isActive={[1, 2, 3, 5].includes(info.status)} />
					<span className="text-[0.625rem] font-bold text-white">{t('orders.details.progress.1')}</span>
				</div>
				<ArrowIcon />
				<div>
					<TwoWaitQueryIcon isActive={[2, 3, 5].includes(info.status)} />
					<span className="text-[0.625rem] font-bold text-white">{t('orders.details.progress.2')}</span>
				</div>
				<ArrowIcon />
				<div>
					<ThreeExchangeIcon isActive={[3, 5].includes(info.status)} />
					<span className="text-[0.625rem] font-bold text-white">{t('orders.details.progress.3')}</span>
				</div>
				<ArrowIcon />
				<div>
					<FourComponentIcon isActive={[5].includes(info.status)} />
					<span className="text-[0.625rem] font-bold text-white">{t('orders.details.progress.4')}</span>
				</div>
			</div>
		</div>
	)
}
