const other = {
	'other.help.title': 'FAQ',
	'other.serve.title': 'Terms of Service',
	'other.serve.tips': 'Last updated on July 30, 2024',
	'other.privacy.title': 'Privacy policy',
	'other.privacy.tips': 'Last updated on January 14, 2023',

	'orders.no.data': 'Not Found OrderID',
	'orders.details.send': 'Send',
	'orders.details.receive': 'Receive',
	'orders.details.orderid': 'OrderID',
	'orders.details.difftime': 'Remaining time',
	'orders.details.type': 'Order type',
	'orders.details.status1': 'Fixed rate',
	'orders.details.status2': 'Float rate',
	'orders.details.createTime': 'Creation time',
	'orders.details.address': 'Address',
	'orders.details.with.amount': 'With amount',
	'orders.details.b.send': 'Send',
	'orders.details.b.send1': 'To address:',
	'orders.details.b.s': 'Exchange rate will be fixed upon receipt',
	'orders.details.b.s1': 'Network confirmation. ',
	'orders.details.b.s2': 'Receiving address',
	'orders.details.progress.1': 'Waiting for deposit',
	'orders.details.progress.2': 'Waiting for confirmation',
	'orders.details.progress.3': 'Execute exchange',
	'orders.details.progress.4': 'Completed',

	'orders.list.1': 'Date/OROER ID',
	'orders.list.2': 'Send',
	'orders.list.3': 'Receive',
	'orders.list.4': 'Receiving address',
	'orders.title': 'Completed order',
}

export default other
