const swap = {
	'swap.tips.no.wallet': '請先連接錢包',
	'swap.tips.no.number': '請輸入數量',
	'swap.tips.no.address': '輸入您的接收地址',
	'swap.tips.address.error': '地址錯誤',
	'swap.tips.error': '兌換失敗',
	'swap.tips.max': '最大金額：{{msg}}',
	'swap.tips.min': '最小金額：{{msg}}',

	'swap.card.send': '發送',
	'swap.card.receive': '接收',
	'swap.card.tips': '輸入貨幣或票券',
	'swap.card.min': 'min:',
	'swap.card.max': 'max:',

	'swap.btn.title': '目的地',
	'swap.input.tips': '收款地址',
	'swap.title.order': '訂單類型',

	'swap.tab.1': '固定匯率(1.0%)',
	'swap.tab.2': '浮動匯率(0.5%)',

	'swap.btn.tipss': '有什麼不同？ ',
	'swap.btn.swap': '開始交換',

	'swap.tips.1': '透過使用本網站並建立兌換，您同意Fast Coin Mixer的',
	'swap.tips.2': '服務條款',
	'swap.tips.3': '和',
	'swap.tips.4': '隱私權政策',
	'swap.tips.5': '。 ',

	'swap.diff.title': '固定匯率和浮動匯率有什麼差別？ ',

	'swap.paste.title': '貼上',
	'swap.help.title': '常問問題',
	'swap.help.1':
		'透過三種方式：透過電子郵件（如果您留下您的電子郵件地址)，在我們的網站（如果您的瀏覽器接受快取）或透過您的訂單連結查看區塊鏈中的兌換。 ',
	'swap.help.2': `無需註冊，無需分享您的個人資訊。我們不持有您的資金，所有的兌換都在全自動模式下立即進行。 `,
	'swap.help.3': '誠信是我們的首要任務，因此我們承諾完全透明，並明確所有費用：',
	'swap.help.4': '•如果您選擇固定匯率，則為 1%',
	'swap.help.5': '•如果您選擇浮動利率，則為 0.5%',
	'swap.help.6': '進入常見問題解答頁面 ',
	'swap.help.title1': '我如何追蹤我的訂單？ ',
	'swap.help.title2': '我為什麼能相信你？ ',
	'swap.help.title3': '您有隱藏費用嗎？ ',

	'swap.tips.title': '自2018年以來備受信賴',
	'swap.tips.card.title1': '節省時間',
	'swap.tips.card.title2': '進行一次兌換',
	'swap.tips.card.title3': '省錢',
	'swap.tips.card.content1': '由於整個過程都是自動化，兌換速度最快',
	'swap.tips.card.content2': '選擇正確的策略，進行有利的兌換',
	'swap.tips.card.content3': '兌換匯率最好，佣金最少',

	'swap.trade.title': '最近的交易',
}

export default swap
