const other = {
	'other.help.title': 'Câu hỏi thường gặp',
	'other.serve.title': 'Điều khoản dịch vụ',
	'other.serve.tips': 'Cập nhật lần cuối vào ngày 30 tháng 7 năm 2024',
	'other.privacy.title': 'Chính sách bảo mật',
	'other.privacy.tips': 'Cập nhật lần cuối vào ngày 14 tháng 1 năm 2023',

	'orders.no.data': 'Không tìm thấy OrderID',
	'orders.details.send': 'Gửi',
	'orders.details.receive': 'Nhận',
	'orders.details.orderid': 'OrderID',
	'orders.details.difftime': 'Thời gian còn lại',
	'orders.details.type': 'Loại đơn hàng',
	'orders.details.status1': 'Tỷ giá cố định',
	'orders.details.status2': 'Tỷ giá thả nổi',
	'orders.details.createTime': 'Thời gian tạo',
	'orders.details.address': 'Địa chỉ',
	'orders.details.with.amount': 'Với số tiền',
	'orders.details.b.send': 'Gửi',
	'orders.details.b.send1': 'Đến địa chỉ:',
	'orders.details.b.s': 'Tỷ giá hối đoái sẽ được cố định khi nhận được',
	'orders.details.b.s1': 'Xác nhận mạng. ',
	'orders.details.b.s2': 'Địa chỉ nhận',
	'orders.details.progress.1': 'Đang chờ gửi tiền',
	'orders.details.progress.2': 'Đang chờ xác nhận',
	'orders.details.progress.3': 'Thực hiện trao đổi',
	'orders.details.progress.4': 'Hoàn tất',

	'orders.list.1': 'Ngày/ID OROER',
	'orders.list.2': 'Gửi',
	'orders.list.3': 'Nhận',
	'orders.list.4': 'Địa chỉ nhận',
	'orders.title': 'Đơn hàng đã hoàn tất',
}

export default other
