import menu from './zh/menu'
import home from './zh/home'
import chat from './zh/chat'
import other from './zh/other'
import about from './zh/about'
import swap from './zh/swap'

const lang = {
	'app.switch.language.tips': '切換{{msg}}成功',
	'app.language': '語言',
	'app.loading': '載入中...',
	'connect.sign': '簽名...',
	'connect.btn': '連線',
	'connect.logout': '註銷',
	'connect.sign.error': '簽名驗證失敗',

	'layout.menu.stake': '存入',
	'layout.menu.withdraw': '提取',
	'layout.menu.team': '生態',
	'layout.menu.share': '分享',

	'app.fee.title1': '累積手續費',
	'app.fee.title2': '累積服務地址數',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
