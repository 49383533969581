import useSWR from 'swr'
import { DEFAULT_CHAINID } from '~/config/*'
import { multicall } from '@/lib/web3/multicall'
import getChainConfig from '@/lib/web3/getChainConfig'
import { VAULTPROXY } from '@/contract/abi'
import { formatEther } from 'viem'
import useWalletStore from '@/lib/store/useWalletStore'
import moment from 'moment'
import { serverQlTotalCount } from '@/pages/Count/graphql'
import { CurrentUserInfoInit, serverQlCurrentTotalCount } from '@/pages/Count/graphql/count'
import { nanoid } from 'nanoid'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

// 总数据需要的参数
export type ITotalCount = {
	stakeAmount: string
	stakeAmountTotal: string //质押总额
	actualBalance: string //实际余额
	profitTotalAmount: string //利润总额
	efficientAddressTotal: string //有效地址总数
	managerWithdrawnAmount: string
	withdrawnAmount: string
	rewardPaidAmount: string
	addressTotal: string
	addressList: string[]
}

export const TotalCountInit: ITotalCount = {
	stakeAmount: '0',
	withdrawnAmount: '0',
	rewardPaidAmount: '0',
	addressTotal: '0',
	addressList: [],
	stakeAmountTotal: '0',
	actualBalance: '0',
	profitTotalAmount: '0',
	efficientAddressTotal: '0',
	managerWithdrawnAmount: '0',
}

export default function useChatHooks() {
	const currentChainId = DEFAULT_CHAINID
	const { isConnected, address } = useWeb3ModalAccount()

	const isSign = useWalletStore(state => state.isSign)

	// 合约信息
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']
	const AUSDT_ADDRESS = contracts['AUSDT_ADDRESS']

	// fetcher check user is 权限
	const isCheckFetcher = async (): Promise<{ isCheck: boolean; isExeAddress: boolean }> => {
		return await multicall(currentChainId, {
			contracts: [
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'MANAGER_ROLE',
					args: [],
				},
				{
					address: VaultProxy_ADDRESS,
					abi: VAULTPROXY,
					functionName: 'EXECUTOR_ROLE',
					args: [],
				},
			],
		}).then(async res => {
			const MANAGER_ROLE = res[0].result
			const EXECUTOR_ROLE = res[1].result
			return await multicall(currentChainId, {
				contracts: [
					{
						address: VaultProxy_ADDRESS,
						abi: VAULTPROXY,
						functionName: 'hasRole',
						args: [MANAGER_ROLE, address],
					},
					{
						address: VaultProxy_ADDRESS,
						abi: VAULTPROXY,
						functionName: 'hasRole',
						args: [EXECUTOR_ROLE, address],
					},
				],
			}).then(res => {
				const hasRole = res[0].result as boolean,
					exeRole = res[1].result as boolean
				return {
					isCheck: hasRole || exeRole,
					isExeAddress: exeRole,
				}
			})
		})
	}

	const fetcher = async () => {
		const { isCheck, isExeAddress } = await isCheckFetcher()
		if (!isCheck)
			return {
				isCheck,
				count: TotalCountInit,
				list: [],
				dutyAddress: '',
				currentDays: CurrentUserInfoInit,
				aUsdtBalance: 0,
				isExeAddress,
			}
		const day = moment().diff(moment.unix(1734278400), 'days').toString()
		console.log('day', day)
		const multicallPromise = await multicall(currentChainId, {
			contracts: [
				{
					address: AUSDT_ADDRESS,
					abi: [
						{
							constant: true,
							inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
							name: 'balanceOf',
							outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
							payable: false,
							stateMutability: 'view',
							type: 'function',
						},
					],
					functionName: 'balanceOf',
					args: [VaultProxy_ADDRESS],
				},
			],
		}).then(res => {
			console.log('res', res)
			return {
				aUsdtBalance: res[0].status === 'success' ? formatEther(res[0].result as bigint) : 0,
				isExeAddress,
			}
		})
		const promise = await Promise.all([serverQlTotalCount(), serverQlCurrentTotalCount(day)]).then(res => {
			const sys: any = res[0]
			const currentDays: any = res[1]
			return {
				count: {
					stakeAmount: formatEther(sys.stakeAmount),
					withdrawnAmount: formatEther(sys.withdrawnAmount),
					rewardPaidAmount: formatEther(sys.rewardPaidAmount),
					addressTotal: sys.addressTotal,
					addressList: sys.addressList,
					stakeAmountTotal: formatEther(sys.stakeAmountTotal),
					actualBalance: formatEther(sys.actualBalance),
					profitTotalAmount: formatEther(sys.profitTotalAmount),
					efficientAddressTotal: sys.efficientAddressTotal,
					managerWithdrawnAmount: formatEther(sys.managerWithdrawnAmount),
				},
				list: sys.addressList.map((item: any, keys: number) => {
					return {
						key: `${item}-${keys}-${nanoid(10)}`,
						title: item,
					}
				}),
				currentDays: {
					stakeAmount: formatEther(currentDays.stakeAmount),
					withdrawnAmount: formatEther(currentDays.withdrawnAmount),
					managerWithdrawnAmount: formatEther(currentDays.managerWithdrawnAmount),
					profitTotalAmount: formatEther(currentDays.profitTotalAmount),
				},
			}
		})
		return {
			...multicallPromise,
			...promise,
			isCheck,
		}
	}

	const { data, ...args } = useSWR(isConnected && !isSign && address ? `useChatHooks-${address}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	/**
	 * @param isCheck 是否有查询权限
	 * @param totalCount 总数据统计
	 * @param treeDataTotal 总用户列表
	 * @param currentDayCount 当日数据统计
	 * @param aUsdtBalance 合约地址 aUsdt 余额
	 * @param isExeAddress 不能提现
	 */
	return {
		data: {
			isCheck: data?.isCheck || false,
			totalCount: data?.count || TotalCountInit,
			treeDataTotal: (data?.list || []) as any[],
			currentDayCount: data?.currentDays || CurrentUserInfoInit,
			aUsdtBalance: data?.aUsdtBalance || 0,
			isExeAddress: data?.isExeAddress || false,
		},
		...args,
	}
}
