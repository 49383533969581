const about = {
	'about.title': '關於我們',
	'about.content':
		'加密貨幣為實現財務自由開闢了新的機會。用戶群越大，新興的分散式帳本技術提供的競爭優勢就越大。 Fast Coin 透過一個簡單易用的兌換平台，為您提供充分利用數位資產的工具。 ',
	'about.title2': '優勢',
	'about.title2.tips1': '兌換的最大便利性和選擇策略的能力將使您能夠進行有利可圖的兌換',
	'about.title2.tips2': '我們提供安全的兌換。您不冒任何風險，可以立即看到佣金的多少',
	'about.title2.tips3': '您可以在任何裝置上以最快速度進行自動兌換',
	'about.title3': '任務',
	'about.title3.tips1': `我們是有遠見的長期參與者，希望成為您在數位資產領域可靠且值得信賴的合作夥伴。我們的使命是透過實用和可擴展的解決方案簡化交換過程，使加密經濟為您服務。 `,
	'about.title4': '支援的貨幣',
}

export default about
