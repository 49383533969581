import { ReactComponent as FOOTER } from '@/assets/lgoo.svg'

export default function Footer() {
	return (
		<div className="flex flex-col items-center justify-center py-10">
			<FOOTER />
			<span className="mt-4 text-sm text-[#B5B7C3]">© 2018–2025 </span>
		</div>
	)
}
