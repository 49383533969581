const swap = {
	'swap.tips.no.wallet': 'กรุณาเชื่อมต่อกระเป๋าสตางค์ของคุณ',
	'swap.tips.no.number': 'กรุณาใส่จำนวน',
	'swap.tips.no.address': 'ใส่ที่อยู่ผู้รับของคุณ',
	'swap.tips.address.error': 'ข้อผิดพลาดที่อยู่',
	'swap.tips.error': 'ล้มเหลว',
	'swap.tips.max': 'สูงสุด: {{msg}}',
	'swap.tips.min': 'ต่ำสุด: {{msg}}',

	'swap.card.send': 'ส่ง',
	'swap.card.receive': 'รับ',
	'swap.card.tips': 'พิมพ์สกุลเงินหรือสัญลักษณ์',
	'swap.card.min': 'ต่ำสุด:',
	'swap.card.max': 'max:',

	'swap.btn.title': 'ปลายทาง',
	'swap.input.tips': 'ที่อยู่การชำระเงิน',
	'swap.title.order': 'ประเภทคำสั่งซื้อ',

	'swap.tab.1': 'อัตราคงที่ (1.0%)',
	'swap.tab.2': 'อัตราลอยตัว (0.5%)',

	'swap.btn.tipss': 'มีความแตกต่างอะไร?',
	'swap.btn.swap': 'แลกเปลี่ยนตอนนี้',

	'swap.tips.1': `การใช้ไซต์และสร้างการแลกเปลี่ยนแสดงว่าคุณยอมรับ Fast Coin`,
	'swap.tips.2': 'ข้อกำหนดในการให้บริการ',
	'swap.tips.3': ' และ ',
	'swap.tips.4': 'นโยบายความเป็นส่วนตัว',
	'swap.tips.5': '.',

	'swap.diff.title': 'ความแตกต่างระหว่างอัตราคงที่และอัตราลอยตัวคืออะไร?',

	'swap.paste.title': 'วาง',
	'swap.help.title': 'คำถามที่พบบ่อย',
	'swap.help.1':
		'สามวิธี: ทางอีเมล (หากคุณใส่ที่อยู่อีเมลของคุณ), บนเว็บไซต์ของเรา (หากเบราว์เซอร์ของคุณยอมรับคุกกี้) หรือโดยการดูธุรกรรมในบล็อคเชนโดยใช้ลิงก์จากคำสั่งซื้อของคุณ',
	'swap.help.2': `ไม่ต้องลงทะเบียนและไม่จำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของคุณ เราไม่ได้ยึดเงินของคุณ การแลกเปลี่ยนทั้งหมดจะเกิดขึ้นทันทีในโหมดอัตโนมัติเต็มรูปแบบ`,
	'swap.help.3':
		'ความซื่อสัตย์เป็นสิ่งสำคัญที่สุดสำหรับเรา ดังนั้นเราจึงมุ่งมั่นที่จะมีความโปร่งใสอย่างเต็มที่และทำให้ค่าธรรมเนียมทั้งหมดชัดเจน:',
	'swap.help.4': '•1% หากคุณเลือกอัตราคงที่',
	'swap.help.5': '•0.5% หากคุณเลือกอัตราลอยตัว',
	'swap.help.6': 'ไปที่หน้าคำถามที่พบบ่อย',
	'swap.help.title1': 'ฉันจะติดตามคำสั่งซื้อของฉันได้อย่างไร? ',
	'swap.help.title2': 'ทำไมฉันถึงเชื่อใจคุณได้? ',
	'swap.help.title3': 'คุณมีค่าธรรมเนียมที่ซ่อนอยู่หรือไม่? ',

	'swap.tips.title': 'เชื่อถือได้ตั้งแต่ปี 2018',
	'swap.tips.card.title1': 'ประหยัดเวลา',
	'swap.tips.card.title2': 'ทำธุรกรรม แลกเปลี่ยน',
	'swap.tips.card.title3': 'ประหยัดเงิน',
	'swap.tips.card.content1': 'ความเร็วในการแลกเปลี่ยนสูงสุดเนื่องจากระบบอัตโนมัติเต็มรูปแบบ',
	'swap.tips.card.content2': 'เลือกกลยุทธ์ที่เหมาะสมและทำการซื้อขายที่เอื้ออำนวย',
	'swap.tips.card.content3': 'อัตราแลกเปลี่ยนที่ดีที่สุดและค่าคอมมิชชันขั้นต่ำ',

	'swap.trade.title': 'ธุรกรรมล่าสุด',
}

export default swap
