const about = {
	'about.title': 'Về chúng tôi',
	'about.content':
		'Tiền điện tử mở ra những cơ hội mới để đạt được tự do tài chính. Cơ sở người dùng càng lớn, lợi thế cạnh tranh mà các công nghệ sổ cái phân tán mới nổi mang lại càng lớn. FixedFloat cung cấp cho bạn các công cụ để tận dụng tối đa tài sản kỹ thuật số của mình thông qua một nền tảng trao đổi dễ dàng và dễ tiếp cận.',
	'about.title2': 'Ưu điểm',
	'about.title2.tips1':
		'Việc thực hiện trao đổi dễ dàng tối đa và khả năng lựa chọn chiến lược sẽ cho phép bạn thực hiện một cuộc trao đổi có lợi nhuận',
	'about.title2.tips2':
		'Chúng tôi cung cấp một cuộc trao đổi an toàn. Bạn không phải mạo hiểm bất cứ điều gì và bạn có thể thấy ngay quy mô của hoa hồng',
	'about.title2.tips3': 'Bạn có thể thực hiện trao đổi tự động với tốc độ tối đa trên mọi thiết bị',
	'about.title3': 'Sứ mệnh',
	'about.title3.tips1':
		'Chúng tôi là những người chơi lâu năm có tầm nhìn và muốn trở thành đối tác đáng tin cậy của bạn trong thế giới tài sản kỹ thuật số. Sứ mệnh của chúng tôi là đơn giản hóa quy trình trao đổi thông qua các giải pháp thực tế và có thể mở rộng quy mô giúp nền kinh tế tiền điện tử hoạt động vì bạn.',
	'about.title4': 'Các loại tiền được hỗ trợ',
}

export default about
