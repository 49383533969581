const swap = {
	'swap.tips.no.wallet': '먼저 지갑을 연결해주세요',
	'swap.tips.no.number': '수량을 입력하세요',
	'swap.tips.no.address': '수신 주소를 입력하세요',
	'swap.tips.address.error': '주소 오류',
	'swap.tips.error': '교환 실패',
	'swap.tips.max': '최대 금액: {{msg}}',
	'swap.tips.min': '최소 금액: {{msg}}',

	'swap.card.send': '보내기',
	'swap.card.receive': '수신',
	'swap.card.tips': '화폐나 티켓을 입력하세요',
	'swap.card.min': '최소:',
	'swap.card.max': '최대:',

	'swap.btn.title': '목적지',
	'swap.input.tips': '지불 주소',
	'swap.title.order': '주문 유형',

	'swap.tab.1': '고정 환율(1.0%)',
	'swap.tab.2': '변동환율(0.5%)',

	'swap.btn.tipss': '차이점이 무엇인가요? ',
	'swap.btn.swap': '스왑 시작',

	'swap.tips.1': '이 사이트를 사용하고 교환을 생성하면 Fast Coin Mixer에 동의하게 됩니다.',
	'swap.tips.2': '서비스 약관',
	'swap.tips.3': '그리고',
	'swap.tips.4': '개인정보 보호정책',
	'swap.tips.5': '. ',

	'swap.diff.title': '고정환율과 변동환율의 차이점은 무엇인가요? ',

	'swap.paste.title': '붙여넣기',
	'swap.help.title': 'FAQ',
	'swap.help.1':
		'세 가지 방법: 이메일(이메일 주소를 남긴 경우), 당사 웹사이트(브라우저가 쿠키를 허용하는 경우) 또는 주문 링크를 통해 블록체인의 거래를 확인하는 방법입니다.',
	'swap.help.2': `등록할 필요도 없고 개인 정보를 공유할 필요도 없습니다. 우리는 귀하의 자금을 보유하지 않으며 모든 교환은 완전 자동 모드에서 즉시 이루어집니다.`,
	'swap.help.3': '정직이 우리의 최우선 과제이므로 우리는 완전한 투명성을 약속하고 모든 수수료를 명확하게 합니다:',
	'swap.help.4': '•고정 금리를 선택한 경우 1%',
	'swap.help.5': '•변동금리를 선택한 경우 0.5%',
	'swap.help.6': 'FAQ 페이지로 이동',
	'swap.help.title1': '주문을 어떻게 추적할 수 있나요? ',
	'swap.help.title2': '왜 당신을 신뢰할 수 있나요? ',
	'swap.help.title3': '숨겨진 비용이 있나요? ',

	'swap.tips.title': '2018년부터 신뢰를 받고 있습니다',
	'swap.tips.card.title1': '시간을 절약하세요',
	'swap.tips.card.title2': '교환 수행',
	'swap.tips.card.title3': '돈을 절약하세요',
	'swap.tips.card.content1': '전체 과정이 자동화되어 있어 상환 속도가 가장 빠릅니다.',
	'swap.tips.card.content2': '올바른 전략을 선택하고 유익한 교환을 하세요',
	'swap.tips.card.content3': '최고의 환율과 최저 수수료',

	'swap.trade.title': '최근 거래',
}

export default swap
