const about = {
	'about.title': '当社について',
	'about.content':
		'仮想通貨は経済的な自由を達成するための新たなチャンスをもたらしてくれます。ユーザーベースが大きければ大きいほど、新たな分散型台帳テクノロジーが提供する競争優位性が高まります。FixedFloatは、簡単にアクセスできる取引プラットフォームを通じて、皆様のデジタルアセットを最大限活用するためのツールを提供します。',
	'about.title2': 'アドバンテージ',
	'about.title2.tips1': 'これ以上ない取引の簡単さと戦略を選べる能力によって、利益の出る取引をすることが可能になります',
	'about.title2.tips2': '当社は安全な取引を提供します。何かをリスクにさらすことは一切なく、すぐに手数料を見ることができます',
	'about.title2.tips3': 'どんなデバイスからでも最高クラスのスピードで自動的に取引をすることができます',
	'about.title3': 'ミッション',
	'about.title3.tips1':
		'当社はビジョンと長年の実績を持った会社で、デジタルアセットの世界で皆様の信頼できるパートナーになることを望んでいます。当社のミッションは、仮想通貨エコノミーを皆様のために機能させられる、実践的かつ拡張性のあるソリューションを通じて、取引のプロセスを簡単なものにすることです。',
	'about.title4': 'サポートされている通貨',
}

export default about
