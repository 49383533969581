import { ReactComponent as Card1 } from '@/assets/home/card1.svg'
import { ReactComponent as Card2 } from '@/assets/home/card2.svg'
import { ReactComponent as Card3 } from '@/assets/home/card3.svg'

import BG1 from '@/assets/home/card-bg1.png'
import BG2 from '@/assets/home/card-bg2.png'
import BG3 from '@/assets/home/card-bg3.png'
import React from 'react'
import { useTranslation } from 'react-i18next'

const cards: {
	icon: React.ReactNode
	title: string
	bg: string
	desc: string
}[] = [
	{
		icon: <Card1 />,
		title: 'swap.tips.card.title1',
		bg: BG1,
		desc: 'swap.tips.card.content1',
	},
	{
		icon: <Card2 />,
		title: 'swap.tips.card.title2',
		bg: BG2,
		desc: 'swap.tips.card.content2',
	},
	{
		icon: <Card3 className="-mt-2.5" />,
		title: 'swap.tips.card.title3',
		bg: BG3,
		desc: 'swap.tips.card.content3',
	},
]

export default function HomeTips() {
	const { t } = useTranslation()
	return (
		<div className="mt-[3.75rem] flex w-full flex-col px-2">
			<div className="tail-text-menu ml-1 text-2xl font-bold">{t('swap.tips.title')}</div>
			<div className="flex flex-col pt-5">
				{cards.map((item, index) => (
					<div
						style={{
							background: `url(${item.bg}) no-repeat  center center`,
						}}
						className="relative min-h-[25.625rem] w-full"
						key={index}
					>
						{item.icon}
						<div className="w-full px-4 pb-10 pt-5">
							<div className="mb-2 text-2xl text-[#1BA27A]">{t(item.title)}</div>
							<p className="text-sm text-white">{t(item.desc)}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
