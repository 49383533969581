import { formatStrAddress2 } from '@/utils'
import { IOrderDetail } from '../page'
// import { Snippet } from '@nextui-org/react'

import { ReactComponent as Copy2Icon } from '@/assets/svg/copy_2.svg'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'

export default function OrderDetailsBottomInfo({ info }: { info: IOrderDetail }) {
	const { t } = useTranslation()
	return (
		<div className="w-full shrink-0 rounded-md bg-[#202648] p-4">
			<div className="flex flex-col items-start gap-4 border-b border-[#373758] py-4">
				<div className="flex items-center justify-center text-xs text-[#A6A6A6]">
					{t('orders.details.b.send')}
					<div className="flex items-center gap-0 font-bold text-[#1BA27A]">
						{Number(info.send_amount)}
						<span className="ml-1 flex items-start">
							{info.from_code} <span className="text-[0.5rem] leading-[0.5rem]">{info.ccies_from_code.network}</span>
						</span>
					</div>
					{t('orders.details.b.send1')}
				</div>
				<div className="flex items-center gap-2">
					<div className="text-sm text-white">{formatStrAddress2(10, 10, info.send_address)}</div>
					{/* <Snippet
						classNames={{
							base: 'p-0 gap-0 bg-transparent',
							copyButton: '!w-3 !h-3 !p-0 min-w-3',
						}}
						symbol=""
						copyIcon={<Copy2Icon />}
						codeString={info.send_address}
						color="default"
					></Snippet> */}
					<Copy2Icon
						className="h-3 w-3"
						onClick={() => {
							copy(info.send_address)
							toast.success(t('home.message.tip5'), { id: 'copy' })
						}}
					/>
				</div>
				<div className="text-[0.625rem] text-[#8D9FAD]">
					{t('orders.details.b.s')} <span className="text-white">{Number(info.send_amount)}</span>
					{t('orders.details.b.s1')}
				</div>
			</div>
			<div className="flex flex-col items-start gap-4 py-4">
				<div className="flex items-center justify-center text-xs text-[#A6A6A6]">
					{t('orders.details.b.s2')} {info.to_code}
				</div>
				<div className="flex items-center gap-2">
					<div className="bg-transparent text-sm text-white">{formatStrAddress2(10, 10, info.to_address)}</div>
					{/* <Snippet
						classNames={{
							base: 'p-0 gap-0 bg-transparent',
							copyButton: '!w-3 !h-3 !p-0 min-w-3',
						}}
						symbol=""
						copyIcon={<Copy2Icon />}
						codeString={info.to_address}
						color="default"
					></Snippet> */}
					<Copy2Icon
						className="h-3 w-3"
						onClick={() => {
							copy(info.to_address)
							toast.success(t('home.message.tip5'), { id: 'copy' })
						}}
					/>
				</div>
			</div>
		</div>
	)
}
