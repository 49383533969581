import menu from './vi/menu'
import home from './vi/home'
import chat from './vi/chat'
import other from './vi/other'
import about from './vi/about'
import swap from './vi/swap'

const lang = {
	'app.switch.language.tips': 'Chuyển {{msg}} thành công',
	'app.language': 'Ngôn ngữ',
	'app.loading': 'đang tải...',
	'app.address.error.tips': 'Lỗi địa chỉ',

	'connect.sign': 'Đăng nhập...',
	'connect.btn': 'Kết nối',
	'connect.logout': 'đăng xuất',
	'connect.sign.error': 'Xác minh chữ ký không thành công',

	'layout.menu.stake': 'Cổ phần',
	'layout.menu.withdraw': 'Rút',
	'layout.menu.team': 'Cộng đồng',
	'layout.menu.share': 'Chia sẻ',

	'app.fee.title1': 'Phí tích lũy',
	'app.fee.title2': 'Số lượng địa chỉ dịch vụ tích lũy',

	...menu,
	...home,
	...chat,
	...other,
	...about,
	...swap,
}

export default lang
