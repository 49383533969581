import { ReactComponent as USDTMINSVG } from '@/assets/svg/token/usdt-min.svg'
import { cnTw, numFormat } from '@/utils'
import { Button } from '@nextui-org/react'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { encodeFunctionData } from 'viem'

import { VAULTPROXY } from '@/contract/abi'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import getChainConfig from '@/lib/web3/getChainConfig'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useHomeHooks from '@/hooks/useHomeHooks'

export default function Regular() {
	const { t } = useTranslation()
	const { isConnected } = useWeb3ModalAccount()

	const { onSendTransaction } = useSendTransaction()
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']

	// 领取load
	const [isRewardLoading, setIsRewardLoading] = useState<boolean>(false)
	// 赎回load
	const [isWithdrawLoading, setIsWithdrawLoading] = useState<boolean>(false)

	const { mutate: HomeMutate } = useHomeHooks()
	const { orderRewardAmount, orderWithdrawAmount } = useHomeHooks().data

	// 领取
	const handleRewardClick = async () => {
		setIsRewardLoading(true)
		if (!isConnected) {
			setIsRewardLoading(false)
			return
		}
		try {
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'getOrderReward',
				args: [],
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.regular.reward.success'), { duration: 2000, id: 'RedemptionSuccess' })
			setIsRewardLoading(false)
			HomeMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { duration: 2000, id: 'RedemptionErr' })
			setIsRewardLoading(false)
		}
	}

	// 赎回
	const handleWithdrawClick = async () => {
		setIsWithdrawLoading(true)
		if (!isConnected) {
			setIsWithdrawLoading(false)
			return
		}
		try {
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'orderWithdraw',
				args: [],
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.regular.withdraw.success'), { duration: 2000, id: 'RedemptionSuccess' })
			setIsWithdrawLoading(false)
			HomeMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { duration: 2000, id: 'RedemptionErr' })
			setIsWithdrawLoading(false)
		}
	}

	return (
		<>
			<div className="my-4 h-[0.0625rem] w-full bg-[#454545]"></div>
			<span className="tail-text-menu pb-5 text-xl font-bold">{t('home.regular.title')}</span>
			<div className="flex w-full justify-around gap-3 pb-5">
				<div className="flex w-full flex-col-reverse items-center gap-1">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.regular.tips1')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(orderWithdrawAmount)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
				<div className="flex w-full flex-col-reverse items-center gap-1">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.regular.tips2')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(orderRewardAmount)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
			</div>
			{isConnected && (
				<>
					<div className={cnTw('flex w-full gap-2.5 pb-5')}>
						<Button
							onClick={() => {
								if (isWithdrawLoading) return
								if (Number(orderWithdrawAmount) > 0) handleWithdrawClick()
							}}
							isDisabled={!(Number(orderWithdrawAmount) > 0) || isWithdrawLoading}
							isLoading={isWithdrawLoading}
							className={`${Number(orderWithdrawAmount) > 0 ? 'tail-btn-active' : 'tail-btn-no-data'} !w-2/5 shrink-0`}
						>
							<span>{t('home.regular.btn1')}</span>
						</Button>
						<Button
							onClick={() => {
								if (isRewardLoading) return
								if (Number(orderRewardAmount) > 0) handleRewardClick()
							}}
							isDisabled={!(Number(orderRewardAmount) > 0) || isRewardLoading}
							isLoading={isRewardLoading}
							className={`${Number(orderRewardAmount) > 0 ? 'tail-btn-active' : 'tail-btn-no-data'} !w-full`}
						>
							<span>{t('home.regular.btn2')}</span>
						</Button>
					</div>
					<Link to="/myOrders">
						<span className="text-sm font-bold text-[#008FDF] underline">{t('home.check.order.title')}</span>
					</Link>
				</>
			)}
		</>
	)
}
