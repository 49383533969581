import useAllCoinHooks, { CoinType } from '@/hooks/useAllCoinHooks'
import { cnTw } from '@/utils'
import { Button, Input, Popover, PopoverContent, PopoverTrigger, Spinner, Tooltip } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { IType, valueInit } from './Swap'
import { SearchIcon } from './SearchIcon'
import { useTranslation } from 'react-i18next'

/**
 * @param isSend 是否发送
 * @param color 颜色
 * @param selectInfo 选择的币种信息
 * @param otherCoin 另一个币种
 * @param otherCode 另一个币种代码
 * @param onSelect 选择币种
 * @param value 数量
 * @param onValueChange 数量变化
 * @param type 类型
 * @param otherRate 另一个币种汇率
 * @param isPriceLoading 汇率加载中
 * @param isOpacity 是否不能兑换
 */
interface Props {
	isSend?: boolean
	color: string
	selectInfo: CoinType | undefined
	otherCoin: string | undefined
	otherCode: string
	onSelect: (coin: CoinType) => void
	value: string
	onValueChange: (value: string) => void
	type: IType
	otherRate?: number | undefined
	isPriceLoading: boolean
	isOpacity?: boolean
	isOpacityText?: string
	max?: number
	min?: number
}

export default function HomeSendReceiveCard({
	isSend = false,
	color,
	onSelect,
	selectInfo,
	otherCoin,
	otherRate,
	isOpacity = false,
	min = 0,
	max = 0,
	...props
}: Props) {
	const { t } = useTranslation()
	const { list } = useAllCoinHooks().data
	const args = useAllCoinHooks()

	const [open, setOpen] = useState(false)

	const [searchValue, setSearchValue] = useState('')

	useEffect(() => {
		if (!open) setSearchValue('')
	}, [open])

	useEffect(() => {
		console.log('otherCoin && otherRate', otherCoin, otherRate)
	}, [selectInfo, otherCoin, otherRate])

	return (
		<div className="tail-send-receive-card flex flex-col gap-2">
			<div className="px-3 text-xs" style={{ color }}>
				{isSend ? t('swap.card.send') : t('swap.card.receive')}
			</div>
			<div
				className={cnTw(
					'flex h-11 items-center rounded-md border',
					isSend ? (selectInfo?.send === '1' ? '' : 'opacity-50') : selectInfo?.recv === '1' ? '' : 'opacity-50',
					isOpacity ? '!opacity-50' : '',
				)}
				style={{ borderColor: isOpacity ? 'red' : color }}
			>
				<Tooltip
					classNames={{
						base: 'before:bg-[#ab0706]',
						content: 'bg-[#AB0706] shadow-none text-xs rounded-md px-1',
					}}
					content={
						<div className="flex items-center gap-1">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9 1.125C4.65469 1.125 1.125 4.65469 1.125 9C1.125 13.3453 4.65469 16.875 9 16.875C13.3453 16.875 16.875 13.3453 16.875 9C16.875 4.65469 13.3453 1.125 9 1.125ZM9.5625 13.5H8.4375V12.375H9.5625V13.5ZM8.4375 11.25V4.5H9.5625V11.25H8.4375Z"
									fill="white"
								/>
							</svg>
							{props.isOpacityText}
						</div>
					}
					placement="bottom-start"
					showArrow
					isOpen={isOpacity}
				>
					<input
						type="text"
						style={{
							color: color,
						}}
						disabled={!isSend ? true : false}
						value={props.value}
						onChange={e => {
							if (e.target.value === '' || e.target.value === '0') {
								props.onValueChange(e.target.value === '' ? '' : valueInit)
								return
							}
							;/^([0-9][0-9]*)+(\.[0-9]{0,6})?$/.test(e.target.value) && props.onValueChange(e.target.value)
						}}
						maxLength={20}
						className="h-full w-full rounded-l-md bg-[#0A0D11] pl-3"
					/>
				</Tooltip>
				<Popover
					classNames={{
						content: 'rounded-md p-2',
					}}
					isOpen={open}
					onClose={() => setOpen(false)}
				>
					<PopoverTrigger className="!scale-100 !opacity-100">
						<Button
							isLoading={args.isLoading}
							onClick={() => setOpen(!open)}
							startContent={
								!args.isLoading &&
								selectInfo?.logo && <img src={selectInfo?.logo || ''} alt="" className="flex h-4 w-4 shrink-0" />
							}
							endContent={
								<div className="shrink-0">
									<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M4.81089 4.8764C4.41175 5.42946 3.58825 5.42946 3.18912 4.87641L0.813898 1.58521C0.336599 0.923842 0.809174 -2.3761e-07 1.62478 -3.08912e-07L6.37522 -7.24209e-07C7.19083 -7.95511e-07 7.6634 0.923841 7.1861 1.5852L4.81089 4.8764Z"
											style={{
												fill: color,
											}}
										/>
									</svg>
								</div>
							}
							className="shrink-0 rounded-none rounded-r-md bg-[#0A0D11]"
						>
							<div className="flex flex-col items-center">
								<span className="text-sm" style={{ color }}>
									{selectInfo?.code}
								</span>
								<span
									className="rounded px-1 text-[0.625rem] leading-3 text-[#18181b]"
									style={{
										background: color,
									}}
								>
									{selectInfo?.network}
								</span>
							</div>
						</Button>
					</PopoverTrigger>
					<PopoverContent
						className="h-[18.75rem] min-w-[17.5rem] items-start justify-start gap-2 overflow-hidden overflow-y-scroll"
						aria-label="Static Actions"
					>
						<div className="fixed left-0 top-0 z-50 min-h-10 w-full bg-content1 p-2 shadow-sm">
							<Input
								classNames={{
									inputWrapper: 'rounded-md px-2',
								}}
								value={searchValue}
								onValueChange={setSearchValue}
								size="sm"
								placeholder={t('swap.card.tips')}
								startContent={<SearchIcon className="pointer-events-none mb-0.5 flex-shrink-0 text-[#F7931A]" />}
							/>
						</div>
						{list
							.filter(item => {
								if (searchValue === '') return true
								return item.coin.toLowerCase().startsWith(searchValue.toLowerCase())
							})
							.map(item => (
								<div
									key={item.code}
									className={cnTw(
										'flex h-10 w-full shrink-0 cursor-pointer items-center rounded-md px-2 hover:bg-default',
										isSend
											? item.send === '1'
												? ''
												: 'opacity-50 hover:bg-default/50'
											: item.recv === '1'
												? ''
												: 'opacity-50 hover:bg-default/50',
										selectInfo?.coin === item.coin ? 'bg-default' : '',
									)}
									onClick={() => {
										onSelect(item)
										// if (isSend) {
										// 	if (item.send === '0') props.onValueChange(valueInit)
										// } else {
										// 	if (item.recv === '0') props.onValueChange(valueInit)
										// }
										setOpen(false)
									}}
								>
									<div className="flex w-full items-center justify-between">
										<div className="flex w-full items-center gap-2">
											<img src={item.logo} className="h-5 w-5" alt="" />
											<span
												className="text-sm text-white"
												style={{
													color: item.color,
												}}
											>
												{item.name}
											</span>
										</div>
										<div className="ml-2 flex flex-col items-center">
											<span
												className="shrink-0 text-xs text-[#a6a6a6]"
												style={{
													color: item.color,
												}}
											>
												{item.coin}
											</span>
											<span
												className="rounded px-0.5 text-[0.625rem] leading-3 text-[#18181b]"
												style={{
													background: item.color,
												}}
											>
												{item.network}
											</span>
										</div>
									</div>
								</div>
							))}
					</PopoverContent>
				</Popover>
			</div>
			<div className="min-h-4 w-full">
				{props.isPriceLoading ? (
					<Spinner size="sm" color="white" />
				) : (
					<>
						{otherCoin && otherRate && (
							<div className="px-3 text-[0.625rem] text-[#A6A6A6]">
								1{selectInfo?.coin} = {otherRate}
								{otherCoin}
							</div>
						)}
						{isSend && max !== 0 && min !== 0 && (
							<div className="ml-2 mt-1 flex items-center gap-2">
								<div className="rounded-md bg-[rgba(10,13,17,.6)] p-1 text-xs" style={{ color }}>
									<span className="mr-1 text-[0.625rem] !text-[#A6A6A6]">{t('swap.card.min')}</span>
									{min}
									{selectInfo?.coin}
								</div>
								<div className="rounded-md bg-[rgba(10,13,17,.6)] p-1 text-xs" style={{ color }}>
									<span className="mr-1 text-[0.625rem] !text-[#A6A6A6]">{t('swap.card.max')}</span>
									{max}
									{selectInfo?.coin}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}
