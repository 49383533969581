import useShareHooks from '@/hooks/useShareHooks'
import { Button, Input } from '@nextui-org/react'
import { QRCodeSVG } from 'qrcode.react'
import { useMemo } from 'react'
import copy from 'copy-to-clipboard'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { formatStrAddress } from '@/utils'

export default function SharePage() {
	const { t } = useTranslation()
	const { isConnected, address } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()

	const { isUserStaked } = useShareHooks().data

	const isLinkShare = useMemo(() => {
		if (isConnected) return `${window.location.origin}/#/stake?invite=${address}`
		return `${window.location.origin}/#/stake`
	}, [isConnected, address])

	return (
		<>
			<div className="flex w-full items-center justify-center pb-4">
				<span className="tail-text-menu text-2xl font-bold text-white">{t('home.invite.title')}</span>
			</div>
			{!isConnected ? (
				<Button onClick={() => open()} className="tail-btn-active my-5 w-full">
					<span>{t('connect.btn')}</span>
				</Button>
			) : (
				<>
					{isUserStaked ? (
						<>
							<div className="mb-8 flex w-full items-center justify-center">
								<div className="bg-[#171232] p-2">
									<QRCodeSVG value={isLinkShare} width="116px" bgColor="#171232" fgColor="#fff" height="116px" />
								</div>
							</div>
							<Input
								// placeholder={isLinkShare}
								placeholder={formatStrAddress(10, 10, address || '')}
								//  value={isLinkShare} defaultValue={isLinkShare}
								classNames={{
									inputWrapper: 'tail-input-wrapper h-10',
									input: 'text-sm text-center text-white line-clamp-1 pointer-events-none	',
									base: 'opacity-100',
								}}
								readOnly
								// endContent={
								// 	<span
								// 		onClick={() => {
								// 			copy(isLinkShare)
								// 			toast.success(t('home.message.tip5'))
								// 		}}
								// 		className="z-10 shrink-0 cursor-pointer text-sm font-bold text-[#F7931A]"
								// 	>
								// 		{t('home.invite.copy')}
								// 	</span>
								// }
							/>
							<div className="flex w-full items-center justify-center">
								<Button
									onClick={() => {
										copy(isLinkShare)
										toast.success(t('home.message.tip5'))
									}}
									className="tail-btn-active mt-5 w-1/2"
								>
									<span>{t('home.invite.copy')}</span>
								</Button>
							</div>
						</>
					) : (
						<div className="mt-10 text-center text-base text-white/80">{t('home.invite.tip')}</div>
					)}
				</>
			)}
		</>
	)
}
