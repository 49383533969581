const about = {
	'about.title': 'О нас',
	'about.content':
		'Криптовалюты открывают новые возможности для финансовой свободы. Чем больше пользователей, тем выше конкурентные преимущества технологии блокчейн. Fast Coin — это простой и удобный сервис, который позволяет эффективно управлять цифровыми активами и совершать конфиденциальные обмены.',
	'about.title2': 'Наши преимущества',
	'about.title2.tips1': 'Простота обмена и возможность выбора стратегии позволяют получить максимальную выгоду.',
	'about.title2.tips2': 'Безопасный обмен ч прозрачной комиссией.',
	'about.title2.tips3': 'Обмен происходит мгновенно и доступен с любого устройства.',
	'about.title3': 'Наша миссия',
	'about.title3.tips1':
		'Мы — долгосрочный игрок в индустрии цифровых активов. Наша цель — сделать криптоэкономику доступной, удобной и безопасной, предоставляя пользователям простые и масштабируемые решения для обмена.',
	'about.title4': 'Поддерживаемые валюты',
}

export default about
