import { serverTeamList } from '@/api/order'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'

export interface ITeamList {
	address: string
	stake: number
	teamStake: number
	count: number
}

export default function useTeamList() {
	const { isConnected, address } = useWeb3ModalAccount()

	const { data, ...args } = useSWR(
		isConnected ? ['useTeamList', address] : null,
		async ([, add]) => {
			const dataParams: any = await serverTeamList({ page: 1, limit: 999, address: add || '' })
			if (dataParams.code === 0) {
				return {
					list: dataParams.data as ITeamList[],
				}
			}
			return {
				list: [] as ITeamList[],
			}
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return {
		data,
		...args,
	}
}
