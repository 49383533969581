/* eslint-disable */
import React, { useEffect } from 'react'
import { Spinner } from '@nextui-org/react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import TopBarModule from '@/components/TopBar'
import useWalletStore from '@/lib/store/useWalletStore'

import { ReactComponent as RETURNSVG } from '@/assets/svg/return.svg'
import ConnectModale from '@/components/Connect'
import { isAddress } from 'viem'
import useHomeHooks from '@/hooks/useHomeHooks'
import useChatHooks from '@/hooks/useChatHooks'
import useRootHooks from '@/hooks/useRootHooks'
import useShareHooks from '@/hooks/useShareHooks'
import { useTranslation } from 'react-i18next'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

import BottomBarModule from '@/components/BottomBar'
import { cnTw } from '@/utils'
import useSign from '@/hooks/useSign'
import useTeamList from '@/hooks/useTeamList'

const LinkInit: {
	uri: string
	label: string
}[] = [
	{ uri: '/stake', label: 'layout.menu.stake' },
	{ uri: '/withdraw', label: 'layout.menu.withdraw' },
	{ uri: '/team', label: 'layout.menu.team' },
	{ uri: '/share', label: 'layout.menu.share' },
]

export default function LayoutPage() {
	const { t } = useTranslation()

	const location = useLocation()
	const navigate = useNavigate()
	const { isConnected, address } = useWeb3ModalAccount()

	useSign()

	const isSign = useWalletStore(state => state.isSign)

	const { mutate: HomeMutate } = useHomeHooks()
	const { mutate: RootMutate } = useRootHooks()

	const { mutate: ChatMutate } = useChatHooks()
	const { mutate: ShareMutate } = useShareHooks()

	const { mutate: TeamMutate } = useTeamList()

	useEffect(() => {
		if (isConnected && isAddress(address ?? '')) {
			HomeMutate()
			RootMutate()
			ShareMutate()
			TeamMutate()
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	}, [address, isConnected, location.pathname])

	useEffect(() => {
		if (isConnected && isAddress(address ?? '') && location.pathname === '/x63sk9a01') ChatMutate()
	}, [isConnected, address, location.pathname])

	const isTopBarSwitch =
		location.pathname.startsWith('/stake') ||
		location.pathname.startsWith('/team') ||
		location.pathname.startsWith('/share') ||
		location.pathname.startsWith('/withdraw')

	const isNoBottomBar =
		location.pathname.startsWith('/orders') ||
		location.pathname.startsWith('/about') ||
		location.pathname.startsWith('/help') ||
		location.pathname.startsWith('/serve') ||
		location.pathname.startsWith('/privacy')

	const isPx =
		location.pathname.startsWith('/orders') ||
		location.pathname.startsWith('/home') ||
		location.pathname.startsWith('/about') ||
		location.pathname.startsWith('/help') ||
		location.pathname.startsWith('/serve') ||
		location.pathname.startsWith('/privacy') ||
		location.pathname.startsWith('/myOrders')

	if (location.pathname === '/x63sk9a01')
		return (
			<div className="tail-layout-count relative flex flex-col !bg-[var(--bg-card)]">
				<ConnectModale isNoPage={true} />
				{isSign && (
					<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-2xl">
						<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
					</div>
				)}
				<Outlet />
			</div>
		)

	return (
		<div
			className="tail-layout relative flex flex-col"
			style={{
				background: isNoBottomBar ? '#0D0D17' : '',
			}}
		>
			<TopBarModule />
			<div className="h-14"></div>
			{isSign && (
				<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-b-2xl">
					<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
				</div>
			)}
			<div className="tail-bg-layout relative z-20 flex h-full flex-col sm:rounded-b-2xl">
				{/* {isTopBarSwitch && (
					<div className="mb-1 flex h-12 shrink-0 items-end justify-between px-7">
						{LinkInit.map((item, key) => (
							<Link
								to={item.uri}
								key={key}
								onClick={() => {
									if (item.uri === '/team') {
										TeamMutate()
									}
								}}
								className={`flex items-center justify-center pb-2 ${item.uri === location.pathname ? 'tail-link-active' : ''}`}
							>
								<span
									className={`px-2 ${item.uri === location.pathname ? 'text-base font-bold text-white' : 'text-sm font-normal text-[#9099BC]'}`}
								>
									{t(item.label)}
								</span>
							</Link>
						))}
					</div>
				)} */}
				{location.pathname.startsWith('/stake') ? (
					<div className="px-3 py-4">
						<Outlet />
					</div>
				) : (
					<div
						className={cnTw(
							'h-full w-full px-[1.875rem] pt-[1.625rem]',
							isPx && 'px-2 pt-[0.625rem]',
							location.pathname.startsWith('/about') && '!px-0 pt-[0.625rem]',
							location.pathname.startsWith('/team') && '!px-0 pb-10 pt-[1.625rem]',
						)}
					>
						<Outlet />
					</div>
				)}
			</div>
			{!isNoBottomBar && <BottomBarModule />}
		</div>
	)
}
