const menu = {
	'menu.home': 'หน้าแรก',
	'menu.contract': 'สัญญา',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'สัญญาออปชั่น',
	'menu.option.ai': 'AI High Frequency Arbitrage',
	'menu.option.qr': 'Quantitative Robot',
	'menu.option.ai.mall': 'AI Smart Mall',

	'menu.my.orders': 'คำสั่งซื้อของฉัน',
	'menu.stake': 'Stake',
	'menu.about': 'เกี่ยวกับ',
	'menu.about.faq': 'คำถามที่พบบ่อย',
	'menu.serve': 'ข้อกำหนดในการให้บริการ',
	'menu.privacy': 'นโยบายความเป็นส่วนตัว',
}

export default menu
