import { forwardRef, useImperativeHandle } from 'react'

import { Button, Modal, ModalBody, ModalContent, ModalFooter, useDisclosure } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'

function Difference(props: any, ref: any) {
	const { t } = useTranslation()
	const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()
	useImperativeHandle(ref, () => ({
		onOpen: () => {
			onOpen()
		},
		onClose: () => {
			onClose()
		},
	}))

	return (
		<Modal
			classNames={{
				base: 'bg-[#21284B] max-h-[100vh]',
			}}
			scrollBehavior="inside"
			size="full"
			isOpen={isOpen}
			onOpenChange={onOpenChange}
		>
			<ModalContent>
				{onClose => (
					<>
						<ModalBody>
							<div className="flex w-full items-center justify-center">
								<div className="tail-text-menu mb-1 mt-10 w-full max-w-[16.875rem] text-center text-2xl font-bold">
									{t('swap.diff.title')}
								</div>
							</div>
							<div className="tail-diff-base pb-5">
								<div className="title">Fixed rate</div>
								<p>Opting for a fixed rate, you get the price you see at the point of initiating a transaction.</p>
								<ul>
									<li>Pay just 1% + network fee.</li>
									<li>Rates freeze for 10 minutes.</li>
									<li>
										If the market rate changes by more than 1.2% before the appearance of your transaction on the blockchain
										network, you will be asked to make a refund or continue exchanging at the market rate.
									</li>
								</ul>
								<div className="tips">
									Attention! Your transaction must be received within 10 minutes and the amount must exactly match the amount of
									the order. Otherwise, you will be prompted to make a refund or continue the exchange at the market rate.
								</div>
								<div className="title">Float rate</div>
								<p>
									The exchange rate is finally set when your transaction receives the necessary number of blockchain network
									confirmations. If the market goes up, you will get more cryptocurrency, if down - less. All fair.
								</p>
								<li>Pay just 0.5% + network fee.</li>
								<li>
									The exchange will be made at the market rate, which is finally set within 10 minutes after your transaction
									receives the required number of confirmations in the blockchain network.
								</li>
								<div className="tips">
									<span className="font-bold">Example: </span> you are exchanging 1 BTC for 28.37362388 ETH. With a fixed rate,
									you will receive exactly 28.37362388 ETH. With a float exchange rate, the amount of ETH can change both up and
									down. It depends on changes in the market rate.
								</div>
							</div>
						</ModalBody>
						<ModalFooter className="items-center justify-center pb-10">
							<Button onClick={onClose} className="h-10 min-w-[7.625rem] rounded-md bg-[#008FDF]">
								<span>OK</span>
							</Button>
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	)
}

export default forwardRef(Difference)
