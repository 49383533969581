const menu = {
	'menu.home': 'Главная',
	'menu.contract': 'Опционные контракты',
	'menu.chatgpt': 'AI-бот',
	'menu.option.contract': 'Опционные сделки',
	'menu.option.ai': 'AI-арбитраж',
	'menu.option.qr': 'Квантовый трейдинг',
	'menu.option.ai.mall': 'AI-маркет',

	'menu.my.orders': 'Мои сделки',
	'menu.stake': 'Стейкинг',
	'menu.about': 'О нас',
	'menu.about.faq': 'FAQ',
	'menu.serve': 'Условия использования',
	'menu.privacy': 'Политика конфиденциальности',
}

export default menu
