import { useEffect } from 'react'
import Footer from '@/components/Footer'
import { useTranslation } from 'react-i18next'

export default function Privacy() {
	const { t } = useTranslation()
	const scrollToHash = () => {
		const contactUs = document.getElementById(
			(window.location.hash || '').substring(8, (window.location.hash || '').length).replace('#', ''),
		)
		if (contactUs) {
			window.requestAnimationFrame(() => {
				// (contactUs as any).scrollIntoViewIfNeeded()
				window.scrollTo({
					top: contactUs.offsetTop - 20,
					behavior: 'smooth',
				})
			})
		}
	}

	useEffect(() => {
		scrollToHash()
	}, [])
	return (
		<div className="h-full overflow-hidden overflow-y-scroll pt-4 md:pt-[3.875rem]">
			<div className="tail-text-menu px-4 text-[2rem] font-bold leading-9">{t('other.privacy.title')}</div>
			<p className="px-2 pt-10">{t('other.privacy.tips')}</p>
			<div className="tail-no-default flex flex-col gap-3 px-2 pb-10 pt-6 text-sm">
				<h3 id="terms_section_1">
					<span className="marker">Section 1</span> <label>Definitions</label>
				</h3>
				<p>In this Privacy Policy (the "Privacy Policy"):</p>
				<p>
					"<b>Cookies</b>" means data files that are placed on your device or computer and often include an anonymous unique
					identifier. This data can be used for authentication, identification of a user session, user's preferences or anything
					else that can be achieved through storing data on your computer. For more information about cookies, and how to disable
					cookies, visit{' '}
					<a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
						http://www.allaboutcookies.org
					</a>
					.
				</p>
				<p>
					"<b>Fast Coin</b>" means a set of related web pages and services located under a single domain named fixedfloat.com.
				</p>
				<p>
					"<b>Google</b>" means Google LLC.
				</p>
				<p>
					"<b>Log Files</b>" means track actions occurring on the Fast Coin and data including your IP address, browser type,
					Internet service provider, referring/exit pages, and date/time stamps.
				</p>
				<p>
					"<b>Personal Data</b>" means (a) data that can be used to identify or made identifiable an individual and (b) Cookies,
					Log Files, Tages and Pixels.
				</p>
				<p>
					"<b>Tags</b>" and "<b>Pixels</b>" means electronic files used to record information about how you browse the Fast Coin.
				</p>
				<h3>
					<span className="marker">Section 2</span> <label>General</label>
				</h3>
				<p>
					This Privacy Policy relates to the Personal Data that we collect when you are using our services or visiting Fast Coin.
				</p>
				<p>
					Personal Data is processed pursuant to the provisions of Regulation (EU) 2016/679 of the European Parliament and of the
					Council dated 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on
					the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) and the Personal
					Data Protection Act dated 10 May 2018.
				</p>
				<p>We would like to assure you that we are committed to the following principles of Personal Data protection:</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> any Personal Data you provide us with will be secured and will be collected only for
					the purposes set forth in this Privacy Policy;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> we will not rent or sell your Personal Data to any third party;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> we are committed to certain principles of Personal Data protection in relation to
					General Data Protection Regulation effective from 25 May 2018;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> we will provide you with the means to contact us regarding any questions or requests
					relating to this Privacy Policy or Personal Data.
				</p>
				<p>
					By using our services or visiting Fast Coin you confirm your agreement with this Privacy Policy. In view of that if you
					do not agree with this Privacy Policy please kindly do not visit Fast Coin and do not use our services.
				</p>
				<h3>
					<span className="marker">Section 3</span> <label>Personal Data We Collect</label>
				</h3>
				<p>If you use our services you may provide us with your e-mail address in order to receive relevant notifications.</p>
				<p>
					When you visit Fast Coin we automatically collect certain information about your device (i.e. Cookies, Tags and Pixels
					and Log Files).
				</p>
				<h3>
					<span className="marker">Section 4</span> <label>Processing of Personal Data</label>
				</h3>
				<p>
					The Personal Data is processed on the basis of (a) your consent or (b) provisions of law (including any relevant laws
					and regulations on counteracting money-laundering and terrorism financing) that authorise to process Personal Data.
				</p>
				<p>
					The processing of your collected Personal Data is based on (a) your consent as you provide us with your e-mail address
					or (b) by visiting Fast Coin and using our services. You can withdraw your consent at any time by sending a relevant
					notification to our support service (see section Contacts details and rights below).
				</p>
				<p>
					In order for us to provide our services, we will need to undertake collection, recording, organisation, storage,
					adaptation/alteration, retrieval, consultation, use, disclosure, erasure or destruction of your Personal Data, either
					among our affiliates or other authorised third parties. Fast Coin does not collect any sensitive Personal Data about you
					(i.e. gender, racial origin, financial position).
				</p>
				<p>
					Fast Coin is concerned with protecting your privacy and has commercially reasonable technical and organizational
					measures to protect your Personal Data against unlawful or unauthorized access, use, abuse, loss and disclosure and
					store your Personal Data securely. We will also take all reasonable precautionary steps to ensure that our staff have
					received adequate training relating to Personal Data protection. Notwithstanding the fact that we use all reasonable
					efforts to protect your Personal Data, we cannot guarantee the absolute security of your Personal Data provided or
					collected through Fast Coin.
				</p>
				<p>We may use your Personal Data for the following purposes:</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> informing you of the status of the exchange;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> providing you with supporting documents;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> marketing purposes;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> improving Fast Coin and our services.
				</p>
				<p>
					We may retain Personal Data even after the exchange has been completed if retention is reasonably necessary to comply
					with our obligations under applicable laws, rules and regulations or to meet regulatory requirements, resolve disputes
					or enforce this Privacy Policy or our obligations. We will retain your Personal Data for a reasonable period or as long
					as required by applicable laws, rules and regulations.
				</p>
				<p>
					We do not knowingly and/or intentionally request to collect or collect Personal Data from any under-age individual. If a
					user submitting Personal Data is suspected of being under-age, we will require to cancel the exchange and will not allow
					continuing using our services. We will also take steps to delete the Personal Data as soon as possible.
				</p>
				<h3>
					<span className="marker">Section 5</span> <label>Cookies</label>
				</h3>
				<p>
					When you visit Fast Coin our system automatically collects information about your visit, such as your browser type, your
					IP address, and the referring website. Such information is typically collected using Cookies, Log Files, Tages and
					Pixels and similar tools.
				</p>
				<p>
					We use{' '}
					<a href="https://analytics.google.com/" target="_blank" rel="noopener noreferrer">
						Google Analytics
					</a>
					, a web analysis service of Google. Please review their{' '}
					<a href="https://www.google.com/analytics/terms/us.html">Terms of Service</a> and{' '}
					<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">
						Privacy Policy
					</a>
					.
				</p>
				<p>
					Fast Coin uses the Remarketing Lists features of Google Analytics for Display Advertisers. Fast Coin and Google use
					first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie)
					together to inform, optimize, and serve ads based on your past visits to Fast Coin.com. This means that vendors
					including Google will display Fast Coin.com promotional material on other sites you visit across the Internet.
				</p>
				<p>
					We use cookies from third-party partners, such as Google and Facebook, for marketing purposes. Fast Coin.com uses Google
					Analytics to process data about your behavior, age, gender, interests, to show you targeted ads and other content that
					has been customized for you.
				</p>
				<p>
					You can also opt-out of Google Analytics here:{' '}
					<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
				</p>
				<p>
					We also use{' '}
					<a href="https://adwords.google.com/" target="_blank" rel="noopener noreferrer">
						Google AdWords
					</a>{' '}
					Conversion Tracking cookie to measure and optimize the performance and user experience related to our ads in Google
					Search or selected Google Display Network sites. Please review the privacy policy{' '}
					<a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">
						here
					</a>
					.
				</p>
				<p>
					You can also opt-out of Google AdWords here:{' '}
					<a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">
						https://adssettings.google.com/authenticated
					</a>
					.
				</p>
				<h3>
					<span className="marker">Section 6</span> <label>Reservation of Rights</label>
				</h3>
				<p>
					We reserve the right to amend this Privacy Policy at any time without prior notice to you. We will process the Personal
					Data in accordance with the terms of the Privacy Policy that you have consented to at the time of your consent. Upon an
					update of this Privacy Policy, we will inform you with a banner notice on the Fast Coin. If you continue to use Fast
					Coin, it will constitute and will be considered as your consent to the updated Privacy Policy. Check the date at the
					beginning of this Privacy Policy to determine when it was last amended.
				</p>
				<h3>
					<span className="marker">Section 7</span> <label>Contact details and Rights</label>
				</h3>
				<p>Upon receipt of your request and due review of its merits:</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> we will provide you with information as to whether and what Personal Data we store in
					relation to you;
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> should your Personal Data be incorrect you may have it rectified; and
				</p>
				<p className="with-counter with-marker">
					<span className="marker">●</span> you may also revoke your consent to use your Personal Data in the future, in whole or
					in parts, or request deletion of your Personal Data.
				</p>
				<p>
					If you have any questions or queries about us, our Privacy Policy or your Personal Data please contact us using our
					support service at Support page or at <b>info@fixedfloat.com</b>.
				</p>
			</div>
			<Footer />
		</div>
	)
}
