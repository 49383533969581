import request from '@/utils/requestApi'

export const serverOrderAllCoin = async () =>
	request({
		url: '/order/all_coin',
		method: 'post',
	})

// 查询价格汇率
export const serverOrderPrice = async (data: { type: 'fixed' | 'float'; from: string; to: string; amount: number }) =>
	request({
		url: '/order/price',
		method: 'post',
		data,
	})

// 创建订单
export const serverOrderCreate = async (data: {
	type: 'fixed' | 'float'
	from: string
	to: string
	amount: number
	to_address: string
}) =>
	request({
		url: '/order/create',
		method: 'post',
		data,
	})

// 获取订单二维码
export const serverOrderQrcode = async (data: { id: number | string }) =>
	request({
		url: '/order/get_qr',
		method: 'post',
		data,
	})

// 最近订单
export const serverOrderRecent = async () =>
	request({
		url: '/order_list',
		method: 'post',
	})

// 订单列表
export const serverOrderList = async (data: { page: number; limit: number }) =>
	request({
		url: '/user/order/list',
		method: 'post',
		data,
	})

// 订单详情
export const serverOrderDetail = async (data: { id: number | string }) =>
	request({
		url: '/user/order/info',
		method: 'post',
		data,
	})

// 直推记录
export const serverTeamList = async (data: { page: number; limit: number; address: string }) =>
	request({
		url: '/user/team/list',
		method: 'post',
		data,
	})

// 获取手续费
export const serverOrderFeeCount = async () =>
	request({
		url: '/fee',
		method: 'post',
	})
