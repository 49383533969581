import moment from 'moment'
import { IOrderDetail } from '../page'
import { useState } from 'react'
import { useInterval } from 'ahooks'
import { useTranslation } from 'react-i18next'

// 个位数子 补齐0
export const padZero = (number: number): string => number.toString().padStart(2, '0')

export default function OrderDetailsInfo({ info }: { info: IOrderDetail }) {
	const { t } = useTranslation()
	// 时间差
	const [diffTime, setDiffTime] = useState<string>('00:00')
	const [diffTimeInterval, setDiffTimeInterval] = useState<number | undefined>(1000)

	useInterval(() => {
		const time = onCalculateTime()
		setDiffTime(time)
	}, diffTimeInterval)

	// 计算当前时间差
	const onCalculateTime = () => {
		if (!info?.ff_expiration) {
			setDiffTimeInterval(undefined)
			return '00:00'
		}
		const now = moment()
		const end = moment.unix(Number(info.ff_expiration))
		const duration = moment.duration(end.diff(now))
		const minutes = duration.minutes()
		const seconds = duration.seconds()
		if (minutes <= 0 && seconds <= 0) {
			setDiffTimeInterval(undefined)
			return '00:00'
		}
		return `${padZero(minutes)}:${padZero(seconds)}`
	}

	return (
		<div className="w-[8.75rem] shrink-0 rounded-md bg-[#202648] p-2">
			<div className="flex flex-col items-center gap-1 border-b border-[#373758] py-2">
				<span className="text-[0.625rem] text-[#A6A6A6]">{t('orders.details.orderid')}</span>
				<div className="text-sm text-[#1BA27A]">{info.order_sn}</div>
			</div>
			<div className="flex flex-col items-center gap-1 border-b border-[#373758] py-2">
				<span className="text-[0.625rem] text-[#A6A6A6]">{t('orders.details.difftime')}</span>
				<div className="text-sm text-[#1BA27A]">{diffTime}</div>
			</div>
			<div className="flex flex-col items-center gap-1 border-b border-[#373758] py-2">
				<span className="text-[0.625rem] text-[#A6A6A6]">{t('orders.details.type')}</span>
				<div className="text-xs text-white">
					{info.type === 'fixed' ? t('orders.details.status1') : t('orders.details.status2')}
				</div>
			</div>
			<div className="flex flex-col items-center gap-1 py-2">
				<span className="text-[0.625rem] text-[#A6A6A6]">{t('orders.details.createTime')}</span>
				<div className="text-xs text-white">{moment(info.created_at).format('YYYY/MM/DD HH:mm:ss')}</div>
			</div>
		</div>
	)
}
