export default function IconClose() {
	return (
		<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.820435" y="0.820557" width="39" height="39" rx="19.5" fill="#008FDF" stroke="#008FDF" />
			<g clip-path="url(#clip0_2481_531)">
				<path
					d="M26.2486 25.9027L20.961 20.6151L26.2486 15.3274C26.3125 15.2649 26.3632 15.1902 26.3978 15.1078C26.4325 15.0254 26.4504 14.9369 26.4505 14.8475C26.4505 14.7581 26.4328 14.6696 26.3983 14.5872C26.3638 14.5047 26.3131 14.4299 26.2494 14.3673C26.1868 14.3034 26.112 14.2526 26.0295 14.2179C25.947 14.1833 25.8584 14.1654 25.7689 14.1654C25.6794 14.1654 25.5909 14.1833 25.5083 14.2179C25.4258 14.2526 25.3511 14.3034 25.2885 14.3673L20.0008 19.6549L14.7123 14.3665C14.6497 14.3026 14.5749 14.2518 14.4924 14.2171C14.4099 14.1825 14.3214 14.1646 14.2319 14.1646C14.1424 14.1646 14.0538 14.1825 13.9713 14.2171C13.8888 14.2518 13.814 14.3026 13.7514 14.3665C13.4825 14.6353 13.4825 15.0586 13.7514 15.3274L19.039 20.6151L13.7514 25.9027C13.4825 26.1716 13.4825 26.5948 13.7514 26.8637C13.814 26.9276 13.8888 26.9784 13.9713 27.013C14.0538 27.0477 14.1424 27.0656 14.2319 27.0656C14.3214 27.0656 14.4099 27.0477 14.4924 27.013C14.5749 26.9784 14.6497 26.9276 14.7123 26.8637L20 21.576L25.2877 26.8637C25.3503 26.9276 25.4251 26.9784 25.5076 27.013C25.5901 27.0477 25.6786 27.0656 25.7681 27.0656C25.8576 27.0656 25.9462 27.0477 26.0287 27.013C26.1112 26.9784 26.186 26.9276 26.2486 26.8637C26.3125 26.8011 26.3633 26.7263 26.398 26.6438C26.4326 26.5613 26.4505 26.4727 26.4505 26.3832C26.4505 26.2937 26.4326 26.2051 26.398 26.1226C26.3633 26.0401 26.3125 25.9654 26.2486 25.9027Z"
					fill="#0C0D16"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2481_531">
					<rect width="18" height="18" fill="white" transform="translate(20 8.00012) rotate(45)" />
				</clipPath>
			</defs>
		</svg>
	)
}
