import Footer from '@/components/Footer'
import { cnTw } from '@/utils'
import { Button } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import HelpAbout from './components/about'
import HelpExchange from './components/exchange'
import HelpOrders from './components/orders'
import { useTranslation } from 'react-i18next'

const typeArr = [
	{ key: 'about', value: 'About Fast Coin' },
	{ key: 'exchange', value: 'About exchange' },
	{ key: 'orders', value: 'About orders' },
]

export default function Help() {
	const { t } = useTranslation()
	const [type, setType] = useState<string>('about')

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="h-full overflow-hidden overflow-y-scroll pt-4 md:pt-[3.875rem]">
			<div className="tail-text-menu px-4 text-[2rem] font-bold leading-9">{t('other.help.title')}</div>
			<div className="flex flex-wrap items-center gap-4 py-5">
				{typeArr.map((item, index) => (
					<Button
						onClick={() => setType(item.key)}
						key={index}
						className={cnTw('h-10 px-5', item.key === type && 'border-[#008FDF]')}
						radius="sm"
						variant={item.key === type ? 'bordered' : 'light'}
					>
						<span className={cnTw('text-xs text-white', item.key === type && 'text-[#008FDF]')}>{item.value}</span>
					</Button>
				))}
			</div>
			{type === 'about' && <HelpAbout />}
			{type === 'exchange' && <HelpExchange />}
			{type === 'orders' && <HelpOrders />}
			<Footer />
		</div>
	)
}
